import { PropsWithChildren } from "react";

interface Props {
  label: string
  "data-test-id"?: string
}

const InfoRow: React.FC<PropsWithChildren<Props>> = ({ label, children, "data-test-id": dataTestId }) => {
  return (
    <>
      <dt>{label}</dt>
      <dd data-test-id={dataTestId}>{children}</dd>
    </>
  );
};

export default InfoRow;
