import { ButtonGroup, Container, FormGroup, Icon, TextFormInput, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { RadioButton, RadioButtonGroup } from "@ruter-ds/rds-components";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import successAlert from "../../../common/toastr";
import {
  FreeTicketSettings,
  getDefaultFreeTicketSettings,
  mapFreeTicketSettings,
  mapFreeTicketSettingsUpdateContract,
} from "../../../types/freeTicketAgreement/freeTicketSettings";
import { CancelButton, SubmitButton } from "../../common/buttons";
import DropdownInput from "../../common/form-hooks/DropdownInput";
import PageHeaderComponent from "../../common/layout/PageHeader";
import PageSubheaderComponent from "../../common/layout/PageSubheader";
import H1Skeleton from "../../common/skeleton/H1Skeleton";
import InputSkeleton from "../../common/skeleton/InputSkeleton";
import "./FreeTicketSettingsPage.scss";
import { freeTicketSettingsLanguageResource } from "./lang-resource";

const FreeTicketSettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(true);
  const formMethods = useForm<FreeTicketSettings>({
    defaultValues: getDefaultFreeTicketSettings(),
  });
  const authContext = useAuthContextAuthenticated();
  const language = useLanguageResource(freeTicketSettingsLanguageResource);
  const companyId = authContext.userData.selectedCompany.id;

  const { reset, control } = formMethods;

  const value = useWatch({ name: "automaticallyEndChildrenTickets", control });

  useEffect(() => {
    const getSettings = async () => {
       
      const response = await apiClient.get(`/freeticket-api/company/${companyId}/settings`, mapFreeTicketSettings);

      if (response.type === "success") {
        reset(response.result);
        setFetching(false);
      } else if (response.type === "HttpError" && response.responseStatus === 404) {
        setFetching(false);
      } else {
        setSubmitting(() => {
          throw response.error;
        });
      }
    };
    getSettings();
  }, [companyId, reset]);

  const onSubmit = async (values: FreeTicketSettings) => {
    setSubmitting(true);
    const model = mapFreeTicketSettingsUpdateContract(values);

    const response = await apiClient.put(`/freeticket-api/company/${companyId}/settings`, model);

    if ((response).type === "success") {
      setSubmitting(false);
      successAlert(language.settingsSaved);
    } else {
      setSubmitting(() => {
        throw response.error;
      });
    }
  };

  if (fetching) {
    return (
      <Container width="s" className="freeticket-settings-page" data-test-id="freeticket-settings-page skeleton">
        <H1Skeleton />
        <InputSkeleton />
        <H1Skeleton />
        <div className="auto-end-children-tickets-skeleton" />
      </Container>
    );
  }

  const renderAutomatickCancellationOfChildTickets = () => (
    <>
      <FormGroup>
        <div style={{ maxWidth: "400px" }}>
          <Controller
            name="automaticallyEndChildrenTickets"
            control={control}
            render={({ field }) => (
              <RadioButtonGroup
                radioName={field.name}
                isFluid
                selectedValue={field.value}
                data-test-id="auto-end-children-ticket"
                onChange={(event: ChangeEvent<HTMLInputElement>) => field.onChange(event)}
              >
                <RadioButton iconLeft={ <Icon variant="ChildIcon" size="medium" /> } buttonText={language.yes} value="yes" />
                <RadioButton iconLeft={ <Icon variant="CrossCircleIcon" size="medium" /> } buttonText={language.no} value="no" />
              </RadioButtonGroup>
            )}
          />
        </div>
      </FormGroup>
      {value === "yes" && (
        <div className="question-container">
          <div className="label">{language.freeTicketExpiryType}</div>
          <DropdownInput required name="expiryType" validationLabel="test">
            <option value="AT_END_OF_MONTH">{language.month}</option>
            <option value="AT_END_OF_YEAR">{language.year}</option>
          </DropdownInput>
          <div className="label">{language.freeTicketMaxChildrenAge}</div>
          <DropdownInput required name="age" validationLabel="test">
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </DropdownInput>
        </div>
      )}
    </>
  );

  return (
    <Container width="s" className="freeticket-settings-page" data-test-id="freeticket-settings-page">
      <PageHeaderComponent text={language.pageTitle} loading={false} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageSubheaderComponent text={language.invoicing} loading={false} />
          <TextFormInput name="invoiceReference" label={language.invoiceReference} />
          <PageSubheaderComponent text={language.freeTicketsAdult} loading={false} />
          {renderAutomatickCancellationOfChildTickets()}
          <ButtonGroup>
            <SubmitButton text={language.confirmTitle} submitting={submitting} />
            <CancelButton onClick={() => navigate("/fribilletter")} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default FreeTicketSettingsPage;
