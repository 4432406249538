import { formatter } from "@ruter-as/web-components-and-tools";

export const formatText = (value: string | null) => {
  return value || "–"
}


export const formatDate = (date: Date | null) => {
  if (!date) return "–";

  return formatter.date.toShortDateString(date);
};

