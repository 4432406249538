import { mapZones } from "src/common/api/commonTypes/Zone";
import { mapAvailableSubscriptionArray } from "src/common/api/companyAgreementApi/AvailableSubscription";
import { mapEmployeeInvoices } from "src/common/api/companyAgreementApi/EmployeeInvoice";
import { mapCompanyAgreementOrder, mapCompanyAgreementOrderSearchResult } from "src/common/api/companyAgreementApi/order";
import { mapPayrollChangeArray } from "src/common/api/companyAgreementApi/PayrollChange";
import { mapSubscription, mapSubscriptionSearchResult } from "src/common/api/companyAgreementApi/Subscription";
import { AgreementSettings } from "src/types/AuthCompany";
import ChangeZonePostContract from "src/types/backendContracts/companyAgreementApi/ChangeZonePostContract";
import { UpdateEmployeeDetails } from "src/types/backendContracts/companyAgreementApi/UpdateEmployeeDetails";
import { mapTicketRequestSearchResult, TicketRequestJson } from "src/types/companyAgreement/ticketRequest";
import { mapProductTemplateArray } from "src/types/ProductTemplate";
import CompanyTicketPostContract from "./createTicketPostContract";
import { mapPayrollArray } from "./Payroll";
import { mapPrices } from "./prices";
import { mapSubscriberSearchResult } from "./subscriber";
import { SubscriptionPostContract } from "./subscriptionPostContract";

const addPaginationParameters = (page: number, pageSize: number) => {
  return `maxEntries=${pageSize}&offset=${(page - 1) * 10}`;
};

const addQueryParameters = (query: string) => {
  return `searchFor=${query}`;
};

const companyAgreementApi = {
  ticket: {
    getByTicketId: (ticketId: string) => ({
      method: "get" as const,
      url: `/api/ticket/${ticketId}`,
      mapper: mapCompanyAgreementOrder,
    }),
    getEmployeeTicketsBySubscriberId: (subscriberId: string) => ({
      method: "get" as const,
      url: `/api/subscriber/${subscriberId}/tickets`,
      mapper: mapCompanyAgreementOrderSearchResult,
    }),

    updateContactDetailsForAllTickets: (ticketId: string, body: UpdateEmployeeDetails) => ({
      method: "post" as const,
      url: `/api/ticket/update-contact-details-for-all-entries-with-same-phone-number-as-this-order-id/${ticketId}`,
      body,
    }),
    changeZone: (ticketId: string, body: ChangeZonePostContract) => ({
      method: "post" as const,
      url: `/api/ticket/change-zone/${ticketId}`,
      body,
    }),
    cancelActiveTicket: (ticketId: string, expiryDate: Date) => ({
      method: "post" as const,
      url: `/api/ticket/change-expiry/by-id/${ticketId}/${encodeURIComponent(expiryDate.toISOString())}`,
    }),
    cancelPendingTicket: (ticketId: string) => ({
      method: "post" as const,
      url: `/api/ticket/cancel-pending/by-id/${ticketId}`,
    }),
    sendPickupCode: (ticketId: string) => ({
      method: "post" as const,
      url: `/api/ticket/resend-pickup-code/${ticketId}`,
    }),
    resendTicket: (ticketId: string) => ({
      method: "post" as const,
      url: `/api/ticket/resend/${ticketId}`,
    }),
    create: (body: CompanyTicketPostContract) => ({
      method: "post" as const,
      url: `/api/ticket/create`,
      body,
    }),
    getEmployeeTicketsBySubscriptionId: (id: string) => ({
      method: "get" as const,
      url: `/api/ticket/all-entries-given-to-person-with-same-phone-number-as-in-this-subscription-id/${id}`,
      mapper: mapCompanyAgreementOrderSearchResult,
    }),
  },
  invoice: {
    getBySubscriberId: (subscriberId: string) => ({
      method: "get" as const,
      url: `/api/subscriber/${subscriberId}/invoices`,
      mapper: mapEmployeeInvoices,
    }),
    getByAgreementAndDate: (agreementNumber: string, date: Date) => ({
      method: "get" as const,
      url: `/api/invoice/orders/${agreementNumber}/${encodeURIComponent(date.toISOString())}`,
      mapper: mapPayrollArray,
    }),
    getChangesByAgreementNumberAndDate: (agreementNumber: string, oldDate: Date, newDate: Date) => ({
      method: "get" as const,
      url: `/api/invoice/changed-subscriptions?agreement=${agreementNumber}&oldDate=${encodeURIComponent(oldDate.toISOString())}&newDate=${encodeURIComponent(newDate.toISOString())}`,
      mapper: mapPayrollChangeArray,
    }),
  },
  zones: {
    getAll: () => ({
      method: "get" as const,
      url: "/api/zone/list",
      mapper: mapZones,
    }),
  },
  product: {
    list: {
      get: (productTemplateId: string) => {
        return {
          method: "get" as const,
          url: `/api/product/list/${productTemplateId}`,
          mapper: mapPrices,
        };
      },
    },
    getV2Products: () => ({
      method: "get" as const,
      url: "/api/product/list-v2",
      mapper: mapProductTemplateArray,
    }),
  },
  ticketRequest: {
    getByCompanyId: (companyId: string) => {
      return {
        method: "get" as const,
        url: `/api/ticket-request/by-company-id/${companyId}`,
        mapper: mapTicketRequestSearchResult,
      };
    },
    reject: (ticketRequestId: string) => ({
      method: "delete" as const,
      url: `/api/ticket-request/${ticketRequestId}`,
    }),
    approve: (ticketRequest: TicketRequestJson) => ({
      method: "post" as const,
      url: `/api/ticket-request/approve`,
      body: ticketRequest,
    }),
  },
  settings: {
    post: (contract: AgreementSettings) => ({
      method: "post" as const,
      url: "/api/company-agreement/settings",
      body: contract,
    }),
  },
  subscription: {
    getById: (id: string) => ({
      method: "get" as const,
      url: `/api/subscription/${id}`,
      mapper: mapSubscription,
    }),
    available:{
      geyByCompanyId: (companyId: string) => ({
        method: "get" as const,
        url: `/api/subscription/available/by-company-id/${companyId}`,
        mapper: mapAvailableSubscriptionArray,
      }),
    },
    create: (body: SubscriptionPostContract) => ({
      method: "post" as const,
      body,
      url: "/api/subscription/create",
    }),
    cancelPendingSubscription: (id: string) => ({
      method: "delete" as const,
      url: `/api/subscription/${id}`,
    }),
    cancelActiveSubscription: (id: string, expiryDate: Date) => ({
      method: "put" as const,
      url: `/api/subscription/${id}/expiry/${encodeURIComponent(expiryDate.toISOString())}`,
    }),
    getEmployeeSubscriptionsBySubscriberId: (subscriberId: string) => ({
      method: "get" as const,
      url: `/api/subscriber/${subscriberId}/subscriptions`,
      mapper: mapSubscriptionSearchResult,
    }),
  },
  subscriber: {
    getEmployeeBySubscriberId: (companyId: string, query: string, page: number, pageSize: number) => {
      return {
        method: "get" as const,
        url: `/api/subscriber/by-company-id/${companyId}?${addPaginationParameters(page, pageSize)}&${addQueryParameters(query)}`,
        mapper: mapSubscriberSearchResult,
      };
    },
  }
};

export default companyAgreementApi;
