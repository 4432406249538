import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import ticketStatusLanguageResource, { TicketStatusLanguageResource } from "./lang-resource";

export type Status = "active" | "pending" | "expired" | "cancelled" | "failed" | "unknown" | "anonymised";

interface Props {
  status: Status;
}

interface Parameters {
  status: Status;
  language: TicketStatusLanguageResource;
}

const TicketStatusComponent = ({ status, language }: Parameters): string => {
  if (language.statuses[status]) {
    return language.statuses[status];
  }

  return language.statuses.unknown;
};

const TicketStatus: React.FC<Props> = ({ status }) => {
  const language = useLanguageResource(ticketStatusLanguageResource);

  return <>{TicketStatusComponent({ status, language })}</>;
};
export default TicketStatus;
