import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface TicketStatusLanguageResource {
  statuses: {
    active: string;
    pending: string;
    expired: string;
    cancelled: string;
    failed: string;
    unknown: string;
    anonymised: string;
  };
}

const ticketStatusLanguageResource: LanguageResource<TicketStatusLanguageResource> = {
  nb: {
    statuses: {
      active: "Gyldig",
      pending: "Ventende",
      expired: "Utløpt",
      cancelled: "Kansellert",
      failed: "Feilet",
      unknown: "Ukjent",
      anonymised: "Anonymisert",
    },
  },
  en: {
    statuses: {
      active: "Valid",
      pending: "Pending",
      expired: "Expired",
      cancelled: "Cancelled",
      failed: "Failed",
      unknown: "Unknown",
      anonymised: "Anonymised",
    },
  },
};

export default ticketStatusLanguageResource;
