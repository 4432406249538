import { MediaType, SearchResult, ticketCounterApi, TicketCounterOrder } from "@ruter-as/billettluke-frontend";
import { apiClient, Container, formatter, ProgressRadial, SearchInput, Table, TablePagination, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import GotoColumn from "../../common/Table/GotoColumn/GotoColumn";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import orderHistoryLanguageResource from "./lang-resource";
import "./OrderHistory.scss";

let fetchOrdersTimout: NodeJS.Timeout;

const OrderHistory: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;
  const componentIsMounted = useRef(false);
  if (!companyId) {
    throw new Error("company should be set");
  }

  const pageLanguage = useLanguageResource(orderHistoryLanguageResource);
  const [orders, setOrders] = useState<undefined | SearchResult<TicketCounterOrder>>(undefined);
  const navigate = useNavigate();
  const fullLoading = Boolean(!orders);
  const { throttledQuery, query, setQuery, pagination } = usePaginationAndQuery({ sendRawQueryText: true });
  const { page, pageSize } = pagination;

  const fetch = useCallback(async () => {
    if (fetchOrdersTimout) {
      clearTimeout(fetchOrdersTimout);
    }

    const response = await apiClient.request(ticketCounterApi.order.getByCompanyId(companyId, throttledQuery, page, pageSize));

    if (response.type === "success") {
      setOrders(response.result);

      if (response.result.matches.some((x) => x.requestedTicketCount !== x.ticketCount) && componentIsMounted.current) {
        fetchOrdersTimout = setTimeout(() => fetch(), 1000);
      }
    } else {
      setOrders(() => { throw response.error; });
    }


  }, [page, pageSize, companyId, throttledQuery]);


  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      clearTimeout(fetchOrdersTimout);
      componentIsMounted.current = false;
    };
  }, []);

  const getMediaTypeText = (mediaType: MediaType) => {
    if (mediaType === "MOBILE_TICKET") {
      return pageLanguage.mobileTicket;
    } else if (mediaType === "TRAVEL_CARD_ULTRALIGHT") {
      return pageLanguage.impulsCard;
    } else if (mediaType === "TRAVEL_CARD") {
      return pageLanguage.travelCard;
    }
    return "";
  };

  const renderTicketCount = (order: TicketCounterOrder) => {
    if (order.mediaType === "TRAVEL_CARD_ULTRALIGHT") {
      return order.passengerCount;
    }
    if (order.requestedTicketCount === order.ticketCount) {
      return order.ticketCount;
    }
    return (
      <div className="ticket-count">
        <span data-test-id="ticket-count-label" style={{ marginRight: "0.5rem" }}>
          {pageLanguage.ticketCountStatus(order.ticketCount.toString(), order.requestedTicketCount.toString())}
        </span>
        <ProgressRadial type="Detirmate" min={0} max={order.requestedTicketCount} value={order.ticketCount} />
      </div>
    );
  };

  const renderRow = (order: TicketCounterOrder) => {
    return (
      <tr key={order.id} data-order-id={order.id} onClick={() => navigate(`/billettluka/ordrehistorikk/${order.id}`)}>
        <td>{formatter.date.toShortDateString(order.purchaseDate)}</td>
        <td>{order.orderedBy}</td>
        <td>{getMediaTypeText(order.mediaType)}</td>
        <td>{renderTicketCount(order)}</td>
        <PriceColumn price={order.totalPrice} showDecimals/>
        <td>{order.invoiceReference}</td>
        <td>{order.personalReference}</td>
        <GotoColumn />
      </tr>
    );
  };

  return (
    <Container width="m" className="components-ticketcounter-order-history" data-test-id="order-history-page">
      <h1>{pageLanguage.title}</h1>
      <div className="search-and-new-ticket">
        <SearchInput
          placeholder={pageLanguage.searchPlaceHolder}
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value);
          }}
        />
      </div>

      <div>
        <Table
          breakpoint="600px"
          loading={fullLoading}
        >
          <thead>
            <tr>
              <th scope="col">{pageLanguage.orderDate}</th>
              <th scope="col">{pageLanguage.orderedBy}</th>
              <th scope="col">{pageLanguage.mediaType}</th>
              <th scope="col">{pageLanguage.ticketCount}</th>
              <th scope="col" style={{ textAlign: "right" }}>
                {pageLanguage.ticketPrice}
              </th>
              <th scope="col">{pageLanguage.invoiceReference}</th>
              <th scope="col">{pageLanguage.personalReference}</th>
              <th className="goto-column" />
            </tr>
          </thead>
          <tbody>{orders?.matches.map(renderRow)}</tbody>
        </Table>
        <TablePagination pagination={pagination} totalRecords={orders?.totalEntries} />
      </div>
    </Container>
  );
};

export default OrderHistory;
