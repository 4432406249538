import { Button, ButtonGroup, FormGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useState } from "react";
import { Subscription } from "src/common/api/companyAgreementApi/Subscription";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { subscriptionCardLanguageResource } from "./lang-resource";

interface Props {
  subscription: Subscription
  handleClose: () => void
  onSubmit: () => void
}

const ResendSubscriptionConfirmationModal: React.FC<Props> = ({ subscription, handleClose, onSubmit }) => {
  const lang = useLanguageResource(subscriptionCardLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const submit = async () => {
    setLoading(true);
    onSubmit();
  };

  return (
    <Modal isOpen={true} handleClose={() => { handleClose(); }} title={lang.resendSubscription}>
      <FormGroup>
        <p>{lang.confirmResendSubscription}</p>
      </FormGroup>
      {submitError && <FormGroup><Message skin="danger" data-test-id="change-zone-submit-error-message" title={formLang.submitError} /></FormGroup>}
      <ButtonGroup>
        <Button variant="primary" type="button" onClick={submit} text={formLang.yes} loading={loading} data-test-id="confirm-button" />
        <Button variant="cancel" type="button" text={formLang.cancel} onClick={handleClose} data-test-id="cancel-button" />
      </ButtonGroup>
    </Modal>
  );
};

export default ResendSubscriptionConfirmationModal;
