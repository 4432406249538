import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

export interface HousingUnitLanguageResource {
  title: OneParamLanguageResourceTemplate
  subscriptions: string
  expired: string
  invoiceMonth: string
  orderNumber: string
  zones: string
  amount: string
  addSubscription: string
  companyTicket: string
}

export const housingUnitLanguageResource: LanguageResource<HousingUnitLanguageResource> = {
  nb: {
    title: languageResourceBuilder.oneParamTemplate("Billetter tilknyttet boenhet: $p1$"),
    subscriptions: "Abonnementer",
    zones: "Soner",
    amount: "Beløp",
    orderNumber: "Billettnummer",
    expired: "Utløpte",
    invoiceMonth: "Fakturamnd.",
    addSubscription: "Legg til",
    companyTicket: "Bedriftsbillett",
  },
  en: {
    title: languageResourceBuilder.oneParamTemplate("Tickets associated with housing unit: $p1$"),
    subscriptions: "Subscriptions",
    zones: "Zones",
    amount: "Amount",
    invoiceMonth: "Invoice month",
    orderNumber: "Ticket number",
    expired: "Expired",
    addSubscription: "Add new",
    companyTicket: "Company ticket",
  },
};
