import { LocaleText } from "src/types/localeText";
import { SearchResult } from "src/types/SearchResult";
import { parseSubscriptionType, SubscriptionType } from "src/types/subscriptionType";

export interface SubscriptionJson {
  id: string,
  startAt: string,
  expireAt: string | null,
  agreementId: string,
  companyId: string,
  phone: string,
  phoneCountryCode: string,
  employeeId: string,
  lastName: string,
  firstName: string,
  type: string,
  activeSince: string | null,
  pricePerMonth: number,
  priceVatPerMoth: number,
  state: string
}

export interface Subscription {
  id: string,
  startAt: Date,
  expireAt: Date | null,
  agreementId: string,
  companyId: string,
  phone: string,
  phoneCountryCode: string,
  employeeId: string,
  lastName: string,
  firstName: string,
  type: Exclude<SubscriptionType, "TICKET">,
  pricePerMonth: number,
  priceVatPerMoth: number,
  status: SubscriptionState
}

const subscriptionStateArray = ["pending", "expired", "active", "cancelled", "anonymised", "failed"] as const;

export type SubscriptionState = typeof subscriptionStateArray[number];

const parseSubscriptionState = (value: string): SubscriptionState => {
  const parsed = subscriptionStateArray.find(x => x === value.toLocaleLowerCase());

  if (!parsed) {
    throw new Error(`Could not find subscription state: ${value}`);
  }

  return parsed;
};

const parseNonTicketSubscriptionType = (value: string): Exclude<SubscriptionType, "TICKET"> => {
  const parsed = parseSubscriptionType(value);
  if(parsed === "TICKET") {
    throw new Error("Subscription should not have type TICKET")
  }
  return parsed
}


export const mapSubscription = (json: SubscriptionJson): Subscription => ({
  id: json.id,
  agreementId: json.agreementId,
  companyId: json.companyId,
  employeeId: json.employeeId,
  expireAt: json.expireAt ? new Date(json.expireAt) : null,
  firstName: json.firstName,
  lastName: json.lastName,
  phone: json.phone,
  phoneCountryCode: json.phoneCountryCode,
  pricePerMonth: json.pricePerMonth,
  priceVatPerMoth: json.priceVatPerMoth,
  startAt: new Date(json.startAt),
  type: parseNonTicketSubscriptionType(json.type),
  status: parseSubscriptionState(json.state),
});

export const getHumanReadableSubscriptionType = (value: SubscriptionType): LocaleText => {
  switch (value) {
    case "OTTO":
      return {
        en: "Car sharing",
        nb: "Bildeling",
      };
    case "JOULE":
      return {
        nb: "Elsykkel",
        en: "Electrical bike",
      };
    default:
      throw new Error(`${value} is not handled`);
  }
};

export const mapSubscriptionArray = (json: SubscriptionJson[]): Subscription[] => json.map(mapSubscription);

export const mapSubscriptionSearchResult = (json: SearchResult<SubscriptionJson>): SearchResult<Subscription> => ({
  totalEntries: json.totalEntries,
  matches: mapSubscriptionArray(json.matches)
})

export const getValidSubscriptionJson = (json: Partial<SubscriptionJson> = {}): SubscriptionJson => ({
  activeSince: "2019-06-30T21:59:59Z",
  expireAt: null,
  startAt: "2019-06-30T21:59:59Z",
  agreementId: "Agreement number",
  companyId: "Company_id",
  employeeId: "Employee_id",
  firstName: "Firstname",
  id: "id",
  lastName: "Lastname",
  phone: "93002881",
  phoneCountryCode: "+47",
  pricePerMonth: 100,
  priceVatPerMoth: 25,
  type: "JOULE",
  state: "EXPIRED",
  ...json,
});
