import { apiClient } from "@ruter-as/web-components-and-tools";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { ExistingOpenTicketError, QuaranteeenError, QuaranteeenErrorJson } from "src/common/api/companyAgreementApi/createServiceFormData";
import CompanyTicketPostContract from "src/common/api/companyAgreementApi/createTicketPostContract";
import { mapCompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";

export const submitCreateTicket = async (backendContract: CompanyTicketPostContract): Promise<{ type: 'success' } | QuaranteeenError | ExistingOpenTicketError | { type: 'unhandledError', error: Error }> => {
  const response = await apiClient.request(companyAgreementApi.ticket.create(backendContract));
  
  if (response.type === "success") {
    return { type: 'success' };
  } else if (response.type === "HttpError" && response.responseStatus === 409) {
    const responseBodyJson = response.responseBody as QuaranteeenErrorJson;

    if (responseBodyJson.expectedQuaranteenEndDate && responseBodyJson.lastOrder?.expirationDate) {
      return {
        type: "QUARANTEEN_ERROR",
        expectedQuaranteenEndDate: new Date(responseBodyJson.expectedQuaranteenEndDate),
        lastOrder: mapCompanyAgreementOrder(responseBodyJson.lastOrder),
      };
    } else {
      return { type: "EXISTING_OPEN_TICKET_ERROR" };
    }
  } else {
    return { type: 'unhandledError', error: response.error }
  }
};