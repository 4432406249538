export const subscriptionTypeArray = ["JOULE", "OTTO", "TICKET", "UNKOWN"] as const;

export type SubscriptionType = typeof subscriptionTypeArray[number];

export const parseSubscriptionType = (value: string) => {
  if (subscriptionTypeArray.includes(value as SubscriptionType)) {
    return value as SubscriptionType;
  }
  return "UNKOWN"
};
