import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useState } from "react";
import { OnboardingNextStep, parseOnboardingNextStep } from "src/types/OnboardingNextStep";
import ProvideSigneeInfo from "./ProvideSigneeInfo";
import VerifyPhone from "./VerifyPhone";
import { onboardingLanguageResources } from "./lang-resource";

const OnboardingCallback: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const onboardingId = queryParams.get("onboardingId");
  const nextStep = parseOnboardingNextStep(queryParams.get("nextStep"));
  const language = useLanguageResource(onboardingLanguageResources);
  const [signeeProvided, setSigneeProvided] = useState(false);

  if (!onboardingId || !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g.test(onboardingId)) {
    throw new Error(`${onboardingId} is not a valid onboardingId`);
  }

  if (nextStep === OnboardingNextStep.CONTACT_SUPPORT_ERROR_OCCURRED) {
    throw new Error(`Nextstep had value=${nextStep}`);
  }

  if (nextStep === OnboardingNextStep.CONTACT_SUPPORT_COMPANY_EXISTS) {
    return (
      <Container width="s" data-test-id="company-exists-error-message">
        <h1>{language.companyExistsHeader}</h1>
        <div>{language.companyExistsText}</div>
      </Container>
    );
  }
  if (nextStep === OnboardingNextStep.CONTRACT_SIGNED || signeeProvided) {
    return <VerifyPhone onboardingId={onboardingId} nextStep={nextStep} />;
  }
  if (nextStep === OnboardingNextStep.PROVIDE_SIGNEE_INFO) {
    return <ProvideSigneeInfo onboardingId={onboardingId} onSigneeProvided={() => setSigneeProvided(true)} />;
  }

  throw Error("Should not reach this far");
};

export default OnboardingCallback;