import { apiClient, ButtonGroup, CheckBoxForminput, CheckboxList, Container, DropdownFormInput, FieldValidationError, formatter, FormGroup, PhoneFormInput, TextFormInput, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidHousingAssociationService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import { AvailableSubscription, mapAvailableSubscriptionToOption } from "src/common/api/companyAgreementApi/AvailableSubscription";
import { mapCreateTicketPostContract } from "src/common/api/companyAgreementApi/createTicketPostContract";
import CreateHousingTicketFormData, { CreateHousingTicketErrorType, QuaranteeenError, QuaranteeenErrorJson } from "src/common/api/companyAgreementApi/housingAssociation/createHousingTicketFormDate";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { mapHousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { mapSubscriptionPostContract } from "src/common/api/companyAgreementApi/subscriptionPostContract";
import { getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { getProductPrice } from "src/common/getProductPrice";
import { getSubscriptionPrice } from "src/common/getSubscriptionPrice";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import ProductPrice from "src/components/common/form/ProductPrice";
import { getLocaleText } from "src/types/localeText";
import { ProductTemplate } from "src/types/ProductTemplate";
import { subscriptionTypeArray } from "src/types/subscriptionType";
import { housingAsssociationTicketFormLanguageResource } from "./lang-resource";


const HousingAssociationForm: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const housingAssociationService = useValidHousingAssociationService();
  const lang = useLanguageResource(housingAsssociationTicketFormLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const navigate = useNavigate();
  const { selectedLanguage } = useLanguageContext();
  const [submitting, setSubmitting] = useState(false);
  const [zones, setZones] = useState<Zone[]>();
  const [backendError, setBackendError] = useState<CreateHousingTicketErrorType>();
  const ticketOwnerFieldName = getTicketOwnerFieldName(housingAssociationService);
  const [productTemplate, setProductTemplate] = useState<ProductTemplate>();
  const [availableSubscriptions, setAvailableSubscriptions] = useState<AvailableSubscription[]>([]);

  const dates = getExpiryDates(6, formLang.today);

  const formMethods = useForm<CreateHousingTicketFormData>({
    defaultValues: {
      agreementNumber: housingAssociationService.agreementNumber,
      firstName: "",
      lastName: "",
      phone: "",
      startDate: dates[0].value,
      zoneFrom: "1",
      zoneTo: "1",
      profile: "ADULT",
      TICKET: true
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const [zoneResponse, productTemplateResponse, availableSubscriptionResponse] = await Promise.all([
        apiClient.request(housingAssociationApi.zones.getAll()),
        apiClient.request(housingAssociationApi.product.getV2Products()),
        apiClient.request(housingAssociationApi.subscription.available.geyByCompanyId(authContext.userData.selectedCompany.id)),
      ]);

      if (zoneResponse.type !== "success") {
        setZones(() => {
          throw zoneResponse.error;
        });
        return;
      }

      if (productTemplateResponse.type !== "success") {
        setProductTemplate(() => {
          throw productTemplateResponse.error;
        });
        return;
      }

      if (availableSubscriptionResponse.type !== "success") {
        setAvailableSubscriptions(() => {
          throw availableSubscriptionResponse.error;
        });
        return;
      }

      setZones(zoneResponse.result);
      setProductTemplate(productTemplateResponse.result.find(x => x.tags.includes("bigcustomer:subscriptionTicket")));
      setAvailableSubscriptions(availableSubscriptionResponse.result);
    };

    fetchData();
    return () => { setZones(undefined); };
  }, [authContext]);

  const selectedSubscriptions = subscriptionTypeArray.filter(subType => subType !== "TICKET").filter(subType => formMethods.watch(subType));
  const subscriptionIsSelected = selectedSubscriptions.length > 0;

  const submitCreateTicket = async (data: CreateHousingTicketFormData) => {
    const backendContract = mapCreateTicketPostContract(data, false, false);

    const response = await apiClient.request(housingAssociationApi.ticket.create(backendContract));

    if (response.type === "HttpError" && response.responseStatus === 409) {
      const responseBodyJson = response.responseBody as QuaranteeenErrorJson;

      if (responseBodyJson.expectedQuaranteenEndDate && responseBodyJson.lastOrder?.expirationDate) {
        const responseBody: QuaranteeenError = {
          type: "QUARANTEEN_ERROR",
          expectedQuaranteenEndDate: new Date(responseBodyJson.expectedQuaranteenEndDate),
          lastOrder: mapHousingAssociationOrder(responseBodyJson.lastOrder),
        };

        setBackendError(responseBody);
      } else {
        setBackendError({ type: "EXISTING_OPEN_TICKET_ERROR" });
      }
      setSubmitting(false);
    } else if (response.error) {
      setSubmitting(() => {
        throw response.error;
      });
    }
    return response;
  };

  const submitCreateSubscription = async (data: CreateHousingTicketFormData) => {
    const postContracts = mapSubscriptionPostContract(data);
    const responses = await Promise.all(postContracts.map(contract => apiClient.request(housingAssociationApi.subscription.create(contract))));

    const failedResponse = responses.find(response => response.type !== "success");

    if (failedResponse) {
      setSubmitting(() => {
        throw failedResponse.error;
      });
    }
  };

  const onSubmit = async (data: CreateHousingTicketFormData) => {
    setSubmitting(true);
    const ticketCreateResponse = await submitCreateTicket(data);
    if (subscriptionIsSelected && ticketCreateResponse.type === "success") {
      await submitCreateSubscription(data);
    }
    if (ticketCreateResponse.type === "success") {
      navigate("/borettslag");
    }
  };

  const dateOptions = dates.map((o) => ({
    value: o.value,
    text: o.text,
  }));

  if (!zones) {
    return null;
  }

  const renderErrorMessage = (error: CreateHousingTicketErrorType) => {
    if (error.type === "QUARANTEEN_ERROR") {
      const formattedLastOrderExpirationDate = error.lastOrder.expirationDate ? formatter.date.toShortDateString(error.lastOrder.expirationDate) : "–";
      const formattedExpectedQuaranteenEndDate = formatter.date.toShortDateString(error.expectedQuaranteenEndDate);
      return <FieldValidationError text={lang.gapToSmall(formattedLastOrderExpirationDate, formattedExpectedQuaranteenEndDate)} />;
    } else {
      return <FieldValidationError text={lang.errorExistingOpenTicket} />;
    }
  };

  const renderTerms = () => {
    const { terms } = authContext.features.urls;
    if (!terms) return null;

    const { url } = terms;
    if (terms.type === "External") {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ marginTop: "1.5rem", display: "block" }}>
          {lang.readPolicies}
        </a>
      );
    } else {
      return (
        <Link to={url} style={{ marginTop: "1.5rem", display: "block" }}>
          {lang.readPolicies}
        </Link>
      );
    }
  };

  const profile = formMethods.watch("profile");

  const zoneFrom = formMethods.watch("zoneFrom");
  const zoneTo = formMethods.watch("zoneTo");


  const getPrice = () => {
    let price = productTemplate && getProductPrice(productTemplate.products, zoneFrom, zoneTo, zones, profile) || 0;

    if (subscriptionIsSelected) {
      price = price + getSubscriptionPrice(availableSubscriptions, selectedSubscriptions);
    }

    return price;
  };

  const serviceOptions = availableSubscriptions.map(sub => mapAvailableSubscriptionToOption(sub, selectedLanguage, sub.type === "TICKET"));

  const showSubscriptions = availableSubscriptions.length > 1;
  const hasSingleAvailableSubscription = availableSubscriptions.length === 1
  const formTitle = lang.order + " " + (hasSingleAvailableSubscription ? getLocaleText(availableSubscriptions[0].name, selectedLanguage) : lang.subscription).toLowerCase();

  return (
    <Container width="xs" data-test-id="housing-association-ticket-form" >
      <h1>{formTitle}</h1>
      {hasSingleAvailableSubscription && <p data-test-id="subscription-description">{getLocaleText(availableSubscriptions[0].description, selectedLanguage)}</p>}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
          {showSubscriptions &&
            <FormGroup>
              <CheckboxList options={serviceOptions} label={lang.chooseAddOn} />
            </FormGroup>
          }
          <PhoneFormInput name="phone" required label={formLang.phone} mobileOnly />
          <TextFormInput name="firstName" required minLength={2} maxLength={50} label={formLang.firstName} />
          <TextFormInput name="lastName" required minLength={2} maxLength={50} label={formLang.lastName} />
          <TextFormInput name="employeeNumber" required maxLength={20} label={ticketOwnerFieldName} />
          {authContext.features.getZoneInput(zones)}
          <DropdownFormInput name="startDate" required label={formLang.startDate}>
            {dateOptions.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
          </DropdownFormInput>
          <ProductPrice price={getPrice()} />
          <CheckBoxForminput name="acceptPrivacy" label={lang.acceptPrivay} required />
          {renderTerms()}
          {backendError && renderErrorMessage(backendError)}
          <ButtonGroup>
            <SubmitButton submitting={submitting} text={lang.order} />
            <CancelButton onClick={() => { navigate("/borettslag"); }} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default HousingAssociationForm;