import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface SubscriptionCardLanguageResource {
  confirmCancelSubscription: string
  cancelSubscriptionTitle: string
  from: string
  resendSubscription: string
  confirmResendSubscription: string
  id: string
  status: string
  validFrom: string
  validTo: string
  pricePerMonth: string
}

export const subscriptionCardLanguageResource: LanguageResource<SubscriptionCardLanguageResource> = {
  nb: {
    cancelSubscriptionTitle: "Kanseller abonnement",
    confirmCancelSubscription: "Er du sikker på at du vil kansellere abonnementet?",
    resendSubscription: "Send rabattkode på nytt",
    confirmResendSubscription: "Vil du sende ut rabattkoden til den ansatte?",
    from: "Gjelder fra",
    id: "Id",
    validFrom: "Gyldig fra",
    validTo: "Gyldig til",
    pricePerMonth: "Pris/mnd",
    status: "Status",
  },
  en: {
    cancelSubscriptionTitle: "Cancel the subscription",
    confirmCancelSubscription: "Are you sure that you want to cancel the subscription?",
    confirmResendSubscription: "Resend discount code",
    from: "From",
    resendSubscription: "Resend subscription",
    id: "Id",
    pricePerMonth: "Price/month",
    status: "Status",
    validFrom: "Valid from",
    validTo: "Valid to",
  },
};
