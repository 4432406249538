import React from "react";
import formatter from "../../../../common/formatter";
import "./PriceColumn.scss";

interface Props {
  price: number;
  showDecimals?: boolean;
  "data-label"?: string;
}

const PriceColumn: React.FC<Props> = (props) => {
  const { price, showDecimals } = props;
  const dataLabel = props["data-label"];  
  return (
    <td data-label={dataLabel} className="price-column">
      {formatter.number.currency(price, Boolean(showDecimals))}
    </td>
  );
};

export default PriceColumn;
