import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";
import "./Footer.scss";
import footerLanguageResource from "./lang-resource";

const Footer: React.FC = (): JSX.Element => {
  const lang = useLanguageResource(footerLanguageResource);
  const authContext = useAuthContext();
  const contactUsLink = authContext.features.urls.contactUs;
  
  const renderContactLink = (): JSX.Element => {
    if (contactUsLink.type === "Internal") {
      return (
        <Link data-test-id="contact-us-link" to={contactUsLink.url}>
          {lang.contactUs}
        </Link>
      );
    }
    return (
      <a
        data-test-id="contact-us-link"
        href={contactUsLink.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {lang.contactUs}
      </a>
    );
  };

  const renderExternalLink = (text: string, link: string, dataTestId?: string) => (
    <a
      data-test-id={dataTestId}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  const renderTerms = (text: string) => {
    const { terms } = authContext.features.urls;
    if (!terms) return null;
    const { url } = terms;
    if (terms.type === "External") {
      return renderExternalLink(text, url);
    } else {
      return <Link to={url}>{text}</Link>;
    }
  };

  const renderFooter = (): JSX.Element => {
    const conditionsOfTravelUrl = authContext.features.urls.travelTerms;
    const privacyUrl = authContext.features.urls.privacy;
    const cookiesUrl = authContext.features.urls.cookies;

    return (
      <footer className="components-app-footer">
        <Container width="s" className="footer-container">
          <div className="footer-content">
            <div className="section">
              <h2>{lang.customerService}</h2>
              {renderContactLink()}
            </div>
            <div className="section">
              <h2>{lang.policiesAndPrivacy}</h2>
              {renderExternalLink(lang.privacy, privacyUrl)}
              {renderExternalLink(lang.conditionsOfTransportation, conditionsOfTravelUrl)}
              {renderExternalLink(lang.cookies, cookiesUrl)}
              {renderTerms(lang.policies)}
            </div>
          </div>
        </Container>
      </footer>
    );
  };


  return renderFooter();

};

export default Footer;
