import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

interface EmployeesLanguageResource {
  title: string;
  searchPlaceholder: OneParamLanguageResourceTemplate;
  newSubscription: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  status: string;
}

const employeesLanguageResource: LanguageResource<EmployeesLanguageResource> = {
  nb: {
    title: "Ansatte",
    searchPlaceholder: languageResourceBuilder.oneParamTemplate("Filtrer på navn, telefon- eller $p1$"),
    newSubscription: "Nytt abonnement",
    firstName: "Fornavn",
    lastName: "Etternavn",
    phoneNumber: "Telefonnr.",
    status: "Status",
  },
  en: {
    title: "Employees",
    searchPlaceholder: languageResourceBuilder.oneParamTemplate("Search by name, phone or $p1$"),
    newSubscription: "New subscription",
    firstName: "First name",
    lastName: "Last name",
    phoneNumber: "Phone no.",
    status: "Status",
  },
};

export default employeesLanguageResource;