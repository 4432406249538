import { TicketCounterTicket, TravelMoney } from "@ruter-as/billettluke-frontend";
import React from "react";
import { TICKET_PENDING } from "src/constants";

interface Props {
  delivery: TicketCounterTicket | TravelMoney
  children: React.ReactNode
  "data-label"?: string,
}

const ActionButtonsColumn: React.FC<Props> = (props) => {
  const dataLabel = props["data-label"];  
  const { delivery } = props;

  if (delivery.status !== TICKET_PENDING) {
    return <td data-label={dataLabel}></td>;
  }

  return (
    <td className="actions" data-label={dataLabel}>
      {props.children}
    </td>
  );
};
export default ActionButtonsColumn;