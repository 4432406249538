import { ReactNode } from "react";

export interface InfoCardButtonType {
  onClick: () => void
  text?: string
  icon?: ReactNode
  disabled?: boolean
  "data-test-id"?: string
}

const InfoCardButton: React.FC<InfoCardButtonType> = ({ onClick, text, "data-test-id": dataTestId, disabled, icon }) => {
  return (
    <button
      data-test-id={dataTestId}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <span>{text}</span>
    </button>
  );
};

export default InfoCardButton;