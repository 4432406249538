import { apiClient, Button, ButtonGroup, Message, Modal, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useValidCompanyTicketService } from "src/AuthContext";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import { UpdateEmployeeDetails } from "src/types/backendContracts/companyAgreementApi/UpdateEmployeeDetails";
import employeeTicketsLanguageResource from "./lang-resource";


interface Props {
  ticket: CompanyAgreementOrder;
  onClose: () => void;
  onSuccess: () => void;
}

interface ChangeInfoForm {
  firstName: string;
  lastName: string;
  employeeNumber: string;
}

const ChangeInfoModal: React.FC<Props> = ({ ticket, onClose, onSuccess }) => {
  const companyTicketService = useValidCompanyTicketService();
  const lang = useLanguageResource(employeeTicketsLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const [submitting, setSubmitting] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);

  const formMethods = useForm<ChangeInfoForm>({
    defaultValues: {
      firstName: ticket.firstName,
      lastName: ticket.lastName,
      employeeNumber: ticket.employeeId,
    },
  });

  const submit = async (data: ChangeInfoForm) => {
    setSubmitting(true);
    setSubmitFailure(false);
    const postContract: UpdateEmployeeDetails = {
      firstName: data.firstName,
      lastName: data.lastName,
      employeeId: data.employeeNumber,
    };

    const response = await apiClient.request(companyAgreementApi.ticket.updateContactDetailsForAllTickets(ticket.id, postContract));
    if (response.type === "success") {
      onSuccess();
    } else {
      setSubmitFailure(true);
      sentry.captureException(response.error);
    }
    setSubmitting(false);
  };

  return (
    <Modal
      isOpen={true}
      title={lang.changeInfo}
      handleClose={onClose}
      data-test-id="ticket-card-change-info-modal"
    >
      <div className="change-info-form">

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(submit)}>
            <TextFormInput name="firstName" label={formLang.firstName} required minLength={2} maxLength={50} />
            <TextFormInput name="lastName" label={formLang.lastName} required minLength={2} maxLength={50} />
            <TextFormInput name="employeeNumber" label={ticketOwnerFieldName.toLowerCase()} required minLength={1} maxLength={20} />

            {submitFailure && (
              <Message skin="danger" data-test-id="submit-failure" style={{ marginTop: "2rem" }}>
                <p>{lang.submitFailure}</p>
              </Message>
            )}
            <ButtonGroup>
              <Button variant="primary" type="submit" text={formLang.save} loading={submitting} />
              <Button variant="cancel" type="button" text={formLang.cancel} data-test-id="cancel-button" onClick={onClose} />
            </ButtonGroup>
          </form>
        </FormProvider>
      </div>
    </Modal>


  );
};

export default ChangeInfoModal;
