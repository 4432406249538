import { FieldValidationError, formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { CreateCompanyTicketErrorType } from "src/common/api/companyAgreementApi/createServiceFormData";
import { createCompanyTicketErrorLanguageResource } from "./lang-resource";

interface Props {
  error: CreateCompanyTicketErrorType
}

const CreateCompanyTicketError: React.FC<Props> = ({error}) => {
  const lang = useLanguageResource(createCompanyTicketErrorLanguageResource);

  if (error.type === "QUARANTEEN_ERROR") {
    const formattedLastOrderExpirationDate = error.lastOrder.expirationDate ? formatter.date.toShortDateString(error.lastOrder.expirationDate) : "–";
    const formattedExpectedQuaranteenEndDate = formatter.date.toShortDateString(error.expectedQuaranteenEndDate);
    return <FieldValidationError text={lang.gapToSmall(formattedLastOrderExpirationDate, formattedExpectedQuaranteenEndDate)} />;
  } else {
    return <FieldValidationError text={lang.errorExistingOpenTicket} />;
  }
};

export default CreateCompanyTicketError;
