import { SubstituteDictionary } from "@ruter-as/web-components-and-tools";
import { Zone } from "src/common/api/commonTypes/Zone";
import { AuthCompany } from "../AuthCompany";
import { Contact } from "../Contact";
import { UserRoleType } from "../user/UserRoleType";
import { calculateCompanyAdmin, CompanyAdmin } from "./CompanyAdmin";
import { EnvName } from "./EnvName";
import { calculateCompanyTicketService, CompanyTicketService } from "./services/CompanyTicketService";
import { calculateFreeTicketService } from "./services/FreeTicketService";
import { calculateHousingAssociatonService } from "./services/HousingAssociationService";
import { calculateSchoolTicketService } from "./services/SchoolTicketService";
import { Services } from "./services/Services";
import { calculateTicketCounterService } from "./services/TicketCounterService";
import { parseTenant, Tenant } from "./Tenant";
import { calculateTheme, Theme } from "./Theme";
import { calculateUrls, Urls } from "./Urls";
import { calculateUserAdmin, UserAdmin } from "./UserAdmin";
import { DefaultZone, getDefaultZones, getZoneInput } from "./ZoneInput";

const getTenant = (): Tenant => parseTenant(window.tenant);


export interface NonAuthenticatedFeatures {
  showFreeTicketArgeementInfo: boolean
  showCompanyAgreementInfo: boolean
  showTicketCounterInfo: boolean
  showTools: 'no' | 'only-real-time'
  theme: Theme
  urls: Urls
  languageSubstitutionDictionary: SubstituteDictionary[]
  onboarding: Onboarding
}

export interface AuthenticatedFeatures {
  services: Services
  userAdmin: UserAdmin
  invoice: InvoiceAccess
  companyAdmin: CompanyAdmin
  travelCardAdmin: TravelCardAdmin
  showTools: 'no' | 'only-real-time' | 'only-widget' | 'widget-and-real-time'
  theme: Theme
  urls: Urls
  languageSubstitutionDictionary: SubstituteDictionary[]
  onboarding: Onboarding
  defaultZones: DefaultZone
  getZoneInput: (zones: Zone[]) => JSX.Element
}

export interface HasInvoiceAccess {
  hasAccess: true
  allowRefund: boolean
  hasInvoiceBaseOnly: boolean
}

export type InvoiceAccess = HasInvoiceAccess | NoAccess;

const calculateInvoiceAccess = (hasAService: boolean, contact: Contact, tenant: Tenant): InvoiceAccess => {

  if (tenant === Tenant.Brakar) {
    return { hasAccess: false };
  }

  if (!hasAService) {
    return { hasAccess: false };
  }

  return {
    hasAccess: true,
    allowRefund: contact.roles.some((x) => x === UserRoleType.GlobalCompanyAdmin),
    hasInvoiceBaseOnly: tenant === Tenant.Akt,
  };
};


interface TravelCardAdminAccess {
  hasAccess: true;
}

interface NoAccess {
  hasAccess: false
}

export type TravelCardAdmin = TravelCardAdminAccess | NoAccess;

const calculateTravelCardAdmin = (contact: Contact): TravelCardAdmin => {
  const tenant = getTenant();

  if (!contact.roles.some(role => role === UserRoleType.GlobalTravelCardAdmin)) {
    return { hasAccess: false };
  }

  return {
    hasAccess: tenant === Tenant.Ruter,
  };
};

const brakarSubstitutionDictionaryList: SubstituteDictionary[] = [
  { language: "nb", replaceWith: "ansattbillett", search: "fribillett" },
  { language: "en", replaceWith: "employee-ticket", search: "free-ticket" },
  { language: "en", replaceWith: "employee ticket", search: "free ticket" },
];

const getSubstituteDictionaryList = (tenant: Tenant): SubstituteDictionary[] => {

  switch (tenant) {
    case Tenant.Brakar:
      return brakarSubstitutionDictionaryList;
    default:
      return [];
  }
};


interface HasOnboarding {
  hasAccess: true
  showOnboarding: boolean
}

type Onboarding = HasOnboarding | NoAccess;

const calculateOnboaring = (authenticated: boolean, tenant: Tenant): Onboarding => {
  if (tenant !== Tenant.Ruter) {
    return { hasAccess: false };
  }

  if (authenticated) {
    return { hasAccess: true, showOnboarding: false };
  }

  return { hasAccess: true, showOnboarding: true };
};

export const calculateAuthenticatedFeatures = (selectedCompany: AuthCompany, contact: Contact, env: EnvName): AuthenticatedFeatures => {
  const tenant = getTenant();

  const freeTicket = calculateFreeTicketService(selectedCompany, contact, tenant);
  const companyTicket: CompanyTicketService = (tenant === Tenant.Ruter || tenant === Tenant.Akt) ? calculateCompanyTicketService(selectedCompany, contact, tenant, env) : { hasService: false };
  const ticketCounter = calculateTicketCounterService(selectedCompany, contact, tenant, env);
  const schoolTicket = calculateSchoolTicketService(selectedCompany, contact, env);
  const housingAssociation = calculateHousingAssociatonService(selectedCompany, contact, tenant, env);

  const hasAService = freeTicket.hasService ||
    companyTicket.hasService ||
    ticketCounter.hasService ||
    schoolTicket.hasService ||
    housingAssociation.hasService;

  let showTools: 'no' | 'only-widget' | 'widget-and-real-time' | 'only-real-time' = 'no'

  if (tenant === Tenant.Akt && companyTicket.hasService) {
    showTools = 'only-widget'
  } else if (tenant === Tenant.Ruter && (companyTicket.hasService || housingAssociation.hasService)) {
    showTools = 'widget-and-real-time'
  } else if (tenant === Tenant.Ruter) {
    showTools = 'only-real-time'
  }

  return {
    services: {
      freeTicket,
      companyTicket,
      ticketCounter,
      schoolTicket,
      housingAssociation,
    },
    userAdmin: calculateUserAdmin(selectedCompany, contact),
    invoice: calculateInvoiceAccess(hasAService, contact, tenant),
    companyAdmin: calculateCompanyAdmin(contact, tenant, env),
    travelCardAdmin: calculateTravelCardAdmin(contact),
    showTools,
    theme: calculateTheme(tenant),
    urls: calculateUrls(tenant, true),
    languageSubstitutionDictionary: getSubstituteDictionaryList(tenant),
    onboarding: calculateOnboaring(true, tenant),
    getZoneInput: (zones: Zone[]) => getZoneInput(zones, tenant),
    defaultZones: getDefaultZones(tenant),
  };
};

export const calculateNonAuthenticatedFeatures = (): NonAuthenticatedFeatures => {
  const tenant = getTenant();
  switch (tenant) {
    case Tenant.Akt:
      return {
        showCompanyAgreementInfo: false,
        showFreeTicketArgeementInfo: false,
        showTicketCounterInfo: false,
        showTools: 'no',
        theme: calculateTheme(tenant),
        urls: calculateUrls(tenant, false),
        languageSubstitutionDictionary: getSubstituteDictionaryList(tenant),
        onboarding: calculateOnboaring(false, tenant),
      };
    case Tenant.Brakar:
      return {
        showFreeTicketArgeementInfo: true,
        showCompanyAgreementInfo: false,
        showTicketCounterInfo: false,
        showTools: 'no',
        theme: calculateTheme(tenant),
        urls: calculateUrls(tenant, false),
        languageSubstitutionDictionary: getSubstituteDictionaryList(tenant),
        onboarding: calculateOnboaring(false, tenant),
      };
    case Tenant.Ruter:
      return {
        showFreeTicketArgeementInfo: false,
        showCompanyAgreementInfo: true,
        showTicketCounterInfo: true,
        showTools: 'only-real-time',
        theme: calculateTheme(tenant),
        urls: calculateUrls(tenant, false),
        languageSubstitutionDictionary: getSubstituteDictionaryList(tenant),
        onboarding: calculateOnboaring(false, tenant),
      };
  }
};


