import { apiClient, Container, formatter, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import { AvailableSubscription } from "src/common/api/companyAgreementApi/AvailableSubscription";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { Subscription } from "src/common/api/companyAgreementApi/Subscription";
import { setDifference } from "src/common/helpers/setDifference";
import successAlert from "src/common/toastr";
import { ButtonLink } from "src/components/common/buttons";
import SubscriptionCard from "src/components/common/cards/subscriptionCard/SubscriptionCard";
import TicketCard from "src/components/common/cards/ticketCard/TicketCard";
import { TICKET_ACTIVE, TICKET_CANCELLED, TICKET_EXPIRED, TICKET_PENDING } from "src/constants";
import { SearchResult } from "src/types/SearchResult";
import { EmployeeInvoice, InvoiceEmployeeJson } from "../../../common/api/companyAgreementApi/EmployeeInvoice";
import getTicketOwnerFieldName from "../../../common/ticketOwnerFieldName";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import ChangeInfoModal from "./ChangeInfoModal";
import "./EmployeeDetailsPage.scss";
import employeeTicketsLanguageResource from "./lang-resource";
import NewSubscriptionModal from "./NewSubscriptionModal";
import SkeletonTicket from "./SkeletonTicket";

interface PageData {
  orders: SearchResult<CompanyAgreementOrder>;
  employeeInvoices: EmployeeInvoice[];
  zones: Zone[];
  subscriptions: Subscription[];
  availableSubscriptions: AvailableSubscription[];
}

type ModalType = "CHANGE_INFO" | "NEW_ORDER"

const getModal = (
  type: ModalType,
  ticket: CompanyAgreementOrder | undefined,
  subscription: Subscription | undefined,
  availableSubscriptions: AvailableSubscription[],
  zones: Zone[],
  onSuccess: () => void,
  onClose: () => void
) => {
  switch (type) {
    case "CHANGE_INFO": {
      if (!ticket) {
        throw new Error("Cant change info on undefined ticket")
      }
      return <ChangeInfoModal ticket={ticket} onSuccess={onSuccess} onClose={onClose} />
    }
    case "NEW_ORDER":
      return <NewSubscriptionModal onClose={onClose} onSuccess={onSuccess} ticket={ticket} subscription={subscription} zones={zones} availableSubscriptions={availableSubscriptions} />
  }
}

const getMonth = (month: number, year: number) => formatter.date.toMonthAndYear(new Date(year, month - 1, 1));


const renderPayrollRow = (data: EmployeeInvoice) => (
  <tr key={data.id}>
    <td>{getMonth(data.month, data.year)}</td>
    <td>{data.invoiceLine.description}</td>
    <PriceColumn price={data.invoiceLine.price} />
  </tr>
);

const sortRows = (a: InvoiceEmployeeJson, b: InvoiceEmployeeJson) => {
  const sortA = new Date(a.year, a.month, 1).toISOString();
  const sortB = new Date(b.year, b.month, 1).toISOString();

  if (sortA > sortB) {
    return -1;
  }
  if (sortA < sortB) {
    return 1;
  }

  return 0;
};

const EmployeeDetailsPage: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const language = useLanguageResource(employeeTicketsLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const { id } = useParams();
  const [pageData, setPageData] = useState<PageData | undefined>();
  const [modalType, setModalType] = useState<ModalType>();
  const [loading, setLoading] = useState(true);

  if (id === undefined) {
    throw Error("id is required");
  }

  const fetch = useCallback(async () => {
    setLoading(true);
    const [ordersResponse, invoicesResponse, zonesResponse, subscriptions, availableSubscriptionsResponse] = await Promise.all([
      apiClient.request(companyAgreementApi.ticket.getEmployeeTicketsBySubscriberId(id)),
      apiClient.request(companyAgreementApi.invoice.getBySubscriberId(id)),
      apiClient.request(companyAgreementApi.zones.getAll()),
      apiClient.request(companyAgreementApi.subscription.getEmployeeSubscriptionsBySubscriberId(id)),
      apiClient.request(companyAgreementApi.subscription.available.geyByCompanyId(authContext.userData.selectedCompany.id)),
    ]);

    if (ordersResponse.type !== "success") { setPageData(() => { throw ordersResponse.error; }); return; }
    if (invoicesResponse.type !== "success") { setPageData(() => { throw invoicesResponse.error; }); return; }
    if (zonesResponse.type !== "success") { setPageData(() => { throw zonesResponse.error; }); return; }
    if (subscriptions.type !== "success") { setPageData(() => { throw subscriptions.error; }); return; }
    if (availableSubscriptionsResponse.type !== "success") { setPageData(() => { throw availableSubscriptionsResponse.error; }); return; }


    setPageData({
      employeeInvoices: invoicesResponse.result.sort(sortRows).slice(0, 5),
      orders: ordersResponse.result,
      zones: zonesResponse.result,
      subscriptions: subscriptions.result.matches,
      availableSubscriptions: availableSubscriptionsResponse.result,
    });
    setLoading(false);

  }, [id, authContext]);

  useEffect(() => {
    fetch();
  }, [fetch]);


  const renderSkeleton = () => (
    <Container width="m" className="components-employeetickets">
      <div className="skeleton-h1" />
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-h2">
        <div className="content" />
      </div>
      <div className="tickets">
        {pageData?.orders.matches.map((ticket) => (
          <SkeletonTicket key={ticket.id} />
        ))}
      </div>
    </Container>
  );

  if (loading || pageData === undefined) {
    return renderSkeleton();
  }

  const activeTickets = pageData.orders.matches.filter((ticket) => ticket.status === TICKET_ACTIVE || ticket.status === TICKET_PENDING);
  const inactiveTickets = pageData.orders.matches.filter((ticket) => ticket.status === TICKET_CANCELLED || ticket.status === TICKET_EXPIRED);

  const activeOrPendingSubscription = pageData.subscriptions.filter(sub => sub.status === "active" || sub.status === "pending");
  const inactiveSubscription = setDifference(pageData.subscriptions, activeOrPendingSubscription);

  let filteredAvailableSubscriptions = [...pageData.availableSubscriptions]

  if (activeTickets.length > 0) {
    filteredAvailableSubscriptions = filteredAvailableSubscriptions.filter(sub => sub.type !== "TICKET");
  }
  if (activeOrPendingSubscription.length > 0) {
    activeOrPendingSubscription.forEach(subscription => {
      filteredAvailableSubscriptions = filteredAvailableSubscriptions.filter(sub => sub.type !== subscription.type);
    })
  }

  const allowCreateNewSubscription = filteredAvailableSubscriptions.length > 0 && companyTicketService.allowOrderTicket

  const ticketOrSub = pageData.orders.matches[0] || pageData.subscriptions[0]

  if (!ticketOrSub) throw new Error("Both pageData orders and subscriptions are empty")

  return (
    <Container width="l" className="components-employeetickets" data-test-id="components-employeetickets">
      <h1>{`${ticketOrSub.firstName} ${ticketOrSub.lastName}`}</h1>

      <div className="top">
        <div className="left">
          <div className="employee-info">
            <span className="label">{ticketOwnerFieldName}</span>
            <span className="value" data-test-id="employee-id-value">{ticketOrSub.employeeId}</span>
          </div>
          <div className="employee-info">
            <span className="label">{language.mobileNumber}</span>
            <span className="value" data-test-id="employee-phone-value">{ticketOrSub.phone}</span>
          </div>
          <div className="employee-info">
            <span className="label">{language.companyName}</span>
            <span className="value" data-test-id="employee-company-name-value">{authContext.userData.selectedCompany.name}</span>
          </div>
          {pageData.orders.matches.length > 0 && <ButtonLink text={language.changeInfo} onClick={() => setModalType("CHANGE_INFO")} dataTestId="change-employee-info-button" />}
        </div>
      </div>

      <h2>
        {language.subscriptions}
        {allowCreateNewSubscription && <>
          <span> – </span>
          <ButtonLink text={language.modalTitle} onClick={() => setModalType("NEW_ORDER")} dataTestId="new-service-button" />
        </>}
      </h2>
      <div className="tickets" data-test-id="active-tickets-container">
        {activeTickets.map((ticket) => (
          <TicketCard key={ticket.id} zones={pageData.zones} ticket={ticket} onUpdateRequest={() => { fetch(); }} title={language.businessTicket} />
        ))}
        <div className="tickets" data-test-id="employee-ticket-subscriptions">
          {activeOrPendingSubscription.length > 0 && (
            <>
              <div className="tickets" data-test-id="employee-ticket-subscriptions">
                {activeOrPendingSubscription.map(subscription =>
                  <SubscriptionCard subscription={subscription} onUpdateRequest={() => { fetch(); }} key={subscription.id} disableResendButton={companyTicketService.disableRefundSubscriptionButton} />,
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <h2>{language.expired}</h2>
      <div className="tickets" data-test-id="deactive-tickets-container">
        {inactiveTickets.map((ticket) => (
          <TicketCard key={ticket.id} zones={pageData.zones} ticket={ticket} onUpdateRequest={() => { fetch(); }} title={language.businessTicket} />
        ))}
        {inactiveSubscription.length > 0 && (
          <>
            <div className="tickets" data-test-id="employee-ticket-subscriptions">
              {inactiveSubscription.map(subscription =>
                <SubscriptionCard subscription={subscription} key={subscription.id} onUpdateRequest={() => { fetch(); }} disableResendButton={companyTicketService.disableRefundSubscriptionButton} />,
              )}
            </div>
          </>
        )}
      </div>

      <h2>{language.payroll}</h2>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "130px" }}>{language.invoiceMonth}</th>
            <th>{language.description}</th>
            <th style={{ textAlign: "right", width: "140px" }}>{language.amount}</th>
          </tr>
        </thead>
        <tbody>{pageData.employeeInvoices.map(renderPayrollRow)}</tbody>
      </Table>
      {modalType && getModal(
        modalType,
        pageData.orders.matches[0],
        pageData.subscriptions[0],
        filteredAvailableSubscriptions,
        pageData.zones,
        () => { fetch(); successAlert(language.orderSuccess); setModalType(undefined); },
        () => { setModalType(undefined) }
      )}
    </Container>
  );
};

export default EmployeeDetailsPage;
