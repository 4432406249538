import { parser } from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js/min";
import { CreateSubscriptionFormData } from "./createServiceFormData";
import CreateHousingTicketFormData from "./housingAssociation/createHousingTicketFormDate";

interface CompanyTicketPostContract {
  startDate: string
  zoneFrom: string | null
  zoneTo: string | null
  phone: string
  phoneCountryCode: string
  employeeId: string
  firstName: string
  lastName: string
  agreementName: string
  addOns: string[]
  profile?: string
}

export const mapCreateTicketPostContract = (
  formData: CreateSubscriptionFormData | CreateHousingTicketFormData,
  singleZoneHasAdditionalZone: boolean,
  hasMultipleProfiles: boolean,
): CompanyTicketPostContract => {
  const phone = parsePhoneNumberFromString(formData.phone);

  if (!phone || !phone.isValid()) {
    throw new Error("invalid phone number should be impossible as it is validated by the form");
  }

  let zoneFromName = formData.zoneFrom;
  let zoneToName = formData.zoneTo;

  if (singleZoneHasAdditionalZone) {
    if (zoneToName === zoneFromName) {
      if (zoneFromName === "Kristiansand" || zoneFromName === "Vennesla") {
        zoneFromName = "Kristiansand";
        zoneToName = "Vennesla";
      }
    }
  }

  const backendContract: CompanyTicketPostContract = {
    agreementName: formData.agreementNumber,
    employeeId: formData.employeeNumber,
    firstName: formData.firstName,
    lastName: formData.lastName,
    phone: phone.nationalNumber.toString(),
    phoneCountryCode: `+${phone.countryCallingCode}`,
    startDate: parser.date.fromShortDateString(formData.startDate).toISOString(),
    zoneFrom: zoneFromName,
    zoneTo: zoneToName,
    addOns: [],
  };

  if (hasMultipleProfiles) {
    backendContract.profile = formData.profile;
  }

  return backendContract;
};

export default CompanyTicketPostContract;