import { Container, Datalist, DatalistOnChangeEvent, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useState } from "react";
import { AuthCompany } from "../../../types/AuthCompany";
import "./SelectCompany.scss";
import { selectCompanyLanguageResource } from "./lang-resource";

interface Props {
  companies: AuthCompany[];
  selectCompany: (companyId: string) => void
}

const SelectCompany: React.FC<Props> = ({ companies, selectCompany }) => {
  const language = useLanguageResource(selectCompanyLanguageResource);
  const [value, setValue] = useState("");

  const options = companies.map(company => ({ value: company.id, text: company.name }));

  const onChange = (e: DatalistOnChangeEvent) => {
    setValue(e.value);

    if (e.found) {
      const company = companies.find(x => x.id === e.optionValue);
      if (company) {
        selectCompany(company.id);
      }
    }
  };

  return (
    <Container width="xs" className="components-app-selectcomany-selectcompany">
      <h1>{language.title}</h1>
      <Datalist
        name="selectedCompany"
        id="selectedCompany"
        listId="company-list"
        value={value}
        onChange={onChange}
        options={options}
        showIcons={true}
        maxHeight={400}
        className="datalist"
        isFixed
        autoFocus
      />
    </Container>
  );
};

export default SelectCompany;
