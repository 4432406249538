import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TicketCardLanguageResource {
  orderId: string
  validFrom: string
  validTo: string
  pickedUp: string
  pickupCode: string
  appId: string
  pricePerMonth: string
  invoiceReference: string
  resendTicket: string
  sendPickupCode: string
  cancelActiveTicket: string
  cancelPendingTicket: string
  status: string
  changeZone: string
  zones: string
  profile: string
}

export const ticketCardLanguageResource: LanguageResource<TicketCardLanguageResource> = {
  nb: {
    zones: "Soner",
    orderId: "Billettnummer",
    validFrom: "Gyldig fra",
    validTo: "Gyldig til",
    pickedUp: "Hentet",
    pickupCode: "Hentekode",
    appId: "App id",
    pricePerMonth: "Pris/mnd",
    invoiceReference: "Faktura ref.",
    resendTicket: "Send billett på nytt",
    sendPickupCode: "Send hentekode",
    cancelActiveTicket: "Avslutt billett",
    cancelPendingTicket: "Kansellere billett",
    status: "Status",
    changeZone: "Endre sone",
    profile: "Profil"
  },
  en: {
    zones: "Zones",
    orderId: "Ticket number",
    validFrom: "Valid from",
    validTo: "Valid to",
    pickedUp: "Fetched",
    pickupCode: "Pickup code",
    appId: "App id",
    pricePerMonth: "Price/month",
    invoiceReference: "Invoice ref.",
    cancelActiveTicket: "Terminate ticket",
    cancelPendingTicket: "Cancel ticket",
    resendTicket: "Resend ticket",
    sendPickupCode: "Sed pickup code",
    status: "Status",
    changeZone: "Change zone",
    profile: "Profile"
  },
};
