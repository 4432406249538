 
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpError from "./HttpError";
import InvalidAuthServiceError from "./InvalidAuthServiceError";
import InvalidRequestBody from "./InvalidRequestBody";
import InvalidResponseError from "./InvalidResponseError";
import InvalidUrl from "./InvalidUrlError";

type Mapper<T> = (json: any) => T;

export enum Method {
  Get = "get",
  Put = "put",
  Post = "post",
  Delete = "delete",
}

interface AuthService {
  getAccessToken: () => Promise<string | undefined>;
  login: () => Promise<void>;
}

let authService: AuthService | null = null;

async function redirectUserToLogin() {
  if (authService) {
    await authService.login();
  }
}

const generateRequestData = async (method: Method, payload?: unknown): Promise<RequestInit> => {
  if (!authService) {
    throw new InvalidAuthServiceError();
  }

  const accessToken = await authService.getAccessToken();

  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  if (accessToken) {
    headers.append("Authorization", `Bearer ${accessToken}`);
  }

  const requestData: RequestInit = {
    headers,
    method,
  };
  if (payload) {
    requestData.body = JSON.stringify(payload);
  }
  return requestData;
};

const validateUrl = (url: string) => {
  if (url.trim() === "") {
    throw new InvalidUrl(url);
  }
};

const validateBody = (body: unknown) => {
  if (body === null || body === undefined) {
    return;
  }

  if (typeof body !== "object") {
    throw new InvalidRequestBody(body);
  }
};

async function extractBody<T>(method: Method, url: string, response: Response, requestBody?: unknown, mapper?: (json: unknown) => T) {
  let responseBody;
  const responseClone = response.clone();

  try {
    responseBody = await response.json();
  } catch (e) {
    responseBody = await responseClone.text();
    throw new InvalidResponseError(method, url, response.status, responseBody, requestBody);
  }

  if (mapper) {
    return mapper(responseBody);
  }
  return responseBody;
}

async function http<T>(method: Method, url: string, requestBody?: unknown, mapper?: (json: unknown) => T, successCodes: number[] = []) {
  validateUrl(url);
  validateBody(requestBody);

  const requestData = await generateRequestData(method, requestBody);
  const response = await fetch(url, requestData);

  if (response.status === 401) {
    redirectUserToLogin();
  }

  if ((response.status < 200 || response.status > 299) && !successCodes.some((x) => x === response.status)) {
    const responseAsText = await response.text();
    throw new HttpError(method, url, response.status, responseAsText, requestBody);
  }
  return extractBody(method, url, response, requestBody, mapper);
}

class ApiClient {
  setAuthenticationService(authenticationService: AuthService) {
    authService = authenticationService;
  }

  async get<T>(url: string, mapper?: Mapper<T>): Promise<T> {
    return http(Method.Get, url, null, mapper);
  }

  async delete<T>(url: string, mapper?: Mapper<T>): Promise<T> {
    return http(Method.Delete, url, null, mapper);
  }

  async post<T>(url: string, body: unknown, mapper?: Mapper<T>, successCodes?: number[]): Promise<T> {
    return http(Method.Post, url, body, mapper, successCodes);
  }

  async put<T>(url: string, body: unknown, mapper?: Mapper<T>): Promise<T> {
    return http(Method.Put, url, body, mapper);
  }

  // only in use for testing
  resetAuthenticationService() {
    authService = null;
  }
}

export default new ApiClient();
