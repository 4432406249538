import * as Sentry from "@sentry/react";
import React from "react";
import { useAuthContext } from "src/AuthContext";
import { AuthenticatedFeatures } from "src/types/features/Features";
import MissingUserRights from "./missingUserRights/MissingUserRights";

interface Props {
   
  children: React.ReactNode
  requiredFeature?: (features: AuthenticatedFeatures) => boolean
}

const SecureComponent: React.FC<Props> = ({ children, requiredFeature }) => {
  const authContext = useAuthContext();

  if (!authContext.authenticated) {
    authContext.login();
    return null;
  }

  if (requiredFeature && !requiredFeature(authContext.features)) {
    Sentry.captureException(new Error("User tried to access unavailable feature"), { extra: { additionalData: authContext.features } });
    return <MissingUserRights />;
  }

  return <>{children}</>;
};

export default SecureComponent;
