import { Button, Container, FormGroup, TextAreaFormInput, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { ContactFormMapper, ContactFormReceipt } from "src/types/ContactFormReciept";
import "./ContactUs.scss";
import contactUsLanguageResource from "./lang-resource";

interface FormData {
  message: string;
}

interface Contract {
  message: string;
  companyId: string;
}

const ContactUs: React.FC = () => {
  const language = useLanguageResource(contactUsLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany?.id;
  const [receipt, setReceipt] = useState<ContactFormReceipt>();

  const formMethods = useForm<FormData>({
    defaultValues: {
      message: "",
    },
  });

  if (!companyId) throw new Error("Missing company id");

  const onSubmit = async (data: FormData) => {
    const body: Contract = {
      message: data.message,
      companyId,
    };

    const response = await apiClient.post("/api/contact/send-form", body, ContactFormMapper);
    if (response.type === "success") {
      setReceipt(response.result);
    } else {
      setReceipt(() => {
        throw response.error;
      });
    }
  };

  return (
    <Container width="s" className="components-contactus" data-test-id="components-contactus">
      {receipt ? (
        <>
          <h1>{language.responseTitle}</h1>
          <div className="data-row">
            <div className="label">{language.companyId}</div>
            <div className="data" data-test-id="company-id-value">
              {receipt.companyId}
            </div>
          </div>
          <div className="data-row">
            <div className="label">{language.firstName}</div>
            <div className="data" data-test-id="first-name-value">
              {receipt.firstName || "–"}
            </div>
          </div>
          <div className="data-row">
            <div className="label">{language.lastName}</div>
            <div className="data" data-test-id="last-name-value">
              {receipt.lastName || "–"}
            </div>
          </div>
          <div className="data-row">
            <div className="label">{language.email}</div>
            <div className="data" data-test-id="email-value">
              {receipt.email || "–"}
            </div>
          </div>

          <div className="data-row">
            <div className="label">{language.message}</div>
            <div className="data" data-test-id="message-value">
              {receipt.description}
            </div>
          </div>
        </>
      ) : (
        <FormProvider {...formMethods}>
          <h1>{language.title}</h1>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <TextAreaFormInput name="message" required minLength={2} label={language.message} />
            <FormGroup>
              <Button variant="primary" type="submit" text={language.sendButton} />
            </FormGroup>
          </form>
        </FormProvider>
      )}
    </Container>
  );
};

export default ContactUs;
