import { BlockLink, BlockLinkList, Container } from "@ruter-ds/rds-components";
import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";

const TermsAndConditions: React.FC = () => {
  const authContext = useAuthContext();
  return (
    <Container width="s">
      <h1>Vilkår</h1>
      <p>Her finner du en samlet oversikt over våre vilkår og betingelser når du kjøper billett og reiser med oss.</p>
      <BlockLinkList style={{ marginTop: "2rem" }}>
        <BlockLink element={Link} to="/vilkar/ruterbedrift">
          Bedriftsbillett vilkår og personvern
        </BlockLink>
        <BlockLink href={authContext.features.urls.travelTerms} target="_blank" rel="noopener noreferrer">
          Reisevilkår
        </BlockLink>
        {authContext.features.urls.privacyDeclarationEBike && (
          <BlockLink element={Link} to={authContext.features.urls.privacyDeclarationEBike.url}>
            Personvernerklæring for bestilling av Elsykkelabonnement
          </BlockLink>
        )}
        {authContext.features.urls.TermsEBike && (
          <BlockLink element={Link} to={authContext.features.urls.TermsEBike.url}>
            Vilkår for elsykkelabonnement 
          </BlockLink>
        )}

      </BlockLinkList>
    </Container>
  );
};

export default TermsAndConditions;
