import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

export interface HousingUnitModalLanguageResource {
  cancelActiveTicket: string
  from: string
  cancelActiveTicketInfoTitle: string
  cancelActiveTicketInfoPrivacy: OneParamLanguageResourceTemplate;
  cancelPendingTicket: string
  cancelSubscription: string
  confirmPendingTicketCancel: string
  changeZone: string
  noChangeToZoneValidationError: string
  confirmResendPickupCode: string
  resendPickupCode: string
  resendTicket: string
  resendTicketFailed: string
  resendTicketSucceeded: string
  confirmResendTicket: string
  confirmCancelSubscription: string
}

export const housingUnitModalLanguageResource: LanguageResource<HousingUnitModalLanguageResource> = {
  nb: {
    cancelActiveTicket: "Avslutt billett",
    from: "Gjelder fra",
    cancelActiveTicketInfoTitle: "Vedrørende avslutning av bedriftsbillett",
    cancelActiveTicketInfoPrivacy: languageResourceBuilder.oneParamTemplate("Billetten avsluttes $p1$. Personopplysninger slettes 3 måneder senere."),
    cancelPendingTicket: "Kansellere billett",
    confirmPendingTicketCancel: "Er du sikker på at du ønsker å kansellere billetten?",
    changeZone: "Endre sone",
    noChangeToZoneValidationError: "Du må gjøre en endring på soner for å lagre.",
    confirmResendPickupCode: "Vil du sende ut ny SMS med hentekode til den ansatte?",
    resendPickupCode: "Send hentekode på nytt",
    resendTicket: "Send billett på nytt",
    resendTicketFailed: "Send billett på nytt feilet!",
    resendTicketSucceeded: "Billett sendt på nytt!",
    confirmResendTicket:
      "Vil du lage ny billett og sende ut SMS med hentekode til den ansatte? Den opprinnelige billetten vil bli avsluttet umiddelbart.",
    cancelSubscription: "Kanseller abonnement",
    confirmCancelSubscription: "Er du sikker på at du vil kansellere abonnementet?",
  }, en: {
    cancelActiveTicket: "Terminate ticket",
    from: "From",
    cancelActiveTicketInfoTitle: "Regarding cancellation of ticket.",
    cancelActiveTicketInfoPrivacy:
      languageResourceBuilder.oneParamTemplate("The ticket will be cancelled on $p1$. Stored information about the employee holding this ticket, will be deleted 3 months later."),
    cancelPendingTicket: "Cancel ticket",
    confirmPendingTicketCancel: "Are you sure you want to cancel the ticket?",
    changeZone: "Change zone",
    noChangeToZoneValidationError: "You need to change zones in order to save.",
    confirmResendPickupCode: "Are you sure you want to send an SMS with a new pickup code to the employee?",
    resendPickupCode: "Re-send pickup code",
    resendTicket: "Re-send ticket",
    resendTicketSucceeded: "Ticket re-sent",
    resendTicketFailed: "Re-send ticket failed!",
    confirmResendTicket:
      "Are you sure you want to create a new ticket and send an SMS with a new pickup code to the employee? The original ticket will be cancelled immediately.",
    cancelSubscription: "Cancel subscription",
    confirmCancelSubscription: "Are you sure you want to cancel the subscription?",
  },
};
