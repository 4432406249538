import * as Sentry from "@sentry/react";
import React from "react";
import "react-day-picker/dist/style.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "src/AuthContext";
import { AuthenticatedFeatures } from "src/types/features/Features";
import CompaniesPage from "../companyAdmin/companies/CompaniesPage";
import CompanyPage from "../companyAdmin/company/CompanyPage";
import CreateCompanyPage from "../companyAdmin/createCompany/CreateCompany";
import CreateSubscriptionForm from "../companyAgreement/createService/CreateSubscriptionForm";
import EmployeeDetailsPage from "../companyAgreement/employeeDetails/EmployeeDetailsPage";
import EmployeesPage from "../companyAgreement/employees/EmployeesPage";
import AgreementInfo from "../companyAgreement/info/AgreementInfo";
import InvoicePage from "../companyAgreement/invoice/Invoice";
import InvoiceDetails from "../companyAgreement/invoiceDetails/InvoiceDetails";
import PayrollPage from "../companyAgreement/payroll/PayrollPage";
import Settings from "../companyAgreement/settings/Settings";
import TicketRequest from "../companyAgreement/ticketRequest/TicketRequest";
import LinkPage from "../companyAgreement/widget/LinkPage";
import WidgetPage from "../companyAgreement/widget/WidgetPage";
import CompanyInfoPage from "../companyInfo/MyPage";
import ContactUs from "../contactUs/ContactUs";
import FreeTicketForm from "../freeTicketAgreement/freeTicket/FreeTicketForm";
import FreeTickets from "../freeTicketAgreement/freeTickets/FreeTickets";
import FreeTicketsFamily from "../freeTicketAgreement/freeTicketsFamily/FreeTicketsFamily";
import FreeTicketInfo from "../freeTicketAgreement/info/FreeTicketInfo";
import FreeTicketInvoiceBasePage from "../freeTicketAgreement/invoiceBase/FreeTicketInvoiceBasePage";
import FreeTicketInvoiceBaseDetailsPage from "../freeTicketAgreement/invoiceBaseDetails/FreeTicketInvoiceBaseDetailsPage";
import MassChangeDepartment from "../freeTicketAgreement/massChangeDepartment/MassChangeDepartment";
import MassEndDate from "../freeTicketAgreement/massEndDate/MassEndDate";
import MassOrderCreate from "../freeTicketAgreement/massOrder/MassOrderCreate";
import MassOrders from "../freeTicketAgreement/massOrders/MassOrders";
import FreeTicketSettingsPage from "../freeTicketAgreement/settings/FreeTicketSettingsPage";
import TaxBase from "../freeTicketAgreement/taxBase/TaxBasePage";
import TaxBaseChangesPage from "../freeTicketAgreement/taxBaseChanges/TaxBaseChangesPage";
import TravelCardTodoPage from "../freeTicketAgreement/travelCard/TravelCardTodo";
import Home from "../home/Home";
import HousingAssociationForm from "../housingAssociation/createService/HousingAssociationForm";
import HousingUnit from "../housingAssociation/housingUnit/HousingUnit";
import HousingUnitCosts from "../housingAssociation/housingUnitCosts/HousingUnitCosts";
import HousingAssociationResidents from "../housingAssociation/residents/HousingAssociationResidents";
import HousingAssociationSettings from "../housingAssociation/settings/HousingAssociationSettings";
import HousingAssociationTicketRequestPage from "../housingAssociation/ticketRequest/HousingAssociationTicketRequest";
import HousingAssociationLinkPage from "../housingAssociation/widget/HousingAssociationLink";
import HousingAssociationWidgetPage from "../housingAssociation/widget/HousingAssociationWidget";
import OnboardingCallback from "../onboarding/OnboardingCallback";
import SchoolTicketDetail from "../schoolTicket/schoolTicketDetail/SchoolTicketDetail";
import SchoolTicketOrder from "../schoolTicket/schoolTicketOrder/SchoolTicketOrder";
import SchoolTicketOrderHistory from "../schoolTicket/schoolTicketOrderHistory/SchoolTicketOrderHistory";
import SimpleOnboarding from "../simpleOnboarding/SimpleOnboarding";
import PrivacyDeclarationEBike from "../terms/PrivacyDeclarationEBike";
import RuterBedrift from "../terms/RuterBedrift";
import TermsAndConditions from "../terms/TermsAndConditions";
import TermsForEBike from "../terms/TermsForEBike";
import TicketCounterInfo from "../ticketCounter/info/TicketCounterInfo";
import TicketCounterOrder from "../ticketCounter/order/TicketCounterOrder";
import { OrderDetails } from "../ticketCounter/orderDetails/orderDetails";
import OrderHistory from "../ticketCounter/orderHistory/OrderHistory";
import TicketCounterSettings from "../ticketCounter/settings/TicketCounterSettings";
import TicketHistory from "../ticketCounter/ticketHistory/TicketHistory";
import TicketCounterWhitelist from "../ticketCounter/ticketWhitelist/TicketCounterWhitelist";
import Tools from "../tools/Tools";
import TravelCardDetails from "../travelCardAdmin/travelCardDetails/TravelCardDetails";
import TravelCardSearchPage from "../travelCardAdmin/travelCardSearchPage/TravelCardSearchPage";
import UserPage from "../userAdmin/user/UserPage";
import UsersPage from "../userAdmin/users/UsersPage";
import "./App.scss";
import CypressTestData from "./CypressTestData";
import Footer from "./footer/Footer";
import GenericError from "./genericError/GenericError";
import Header from "./header/Header";
import NotFoundComponent from "./notFound/NotFoundComponent";
import RootRedirect from "./RootRedirect";
import SecureComponent from "./SecureComponent";

const App: React.FC = () => {
  const authContext = useAuthContext();
  const location = useLocation();

  document.title = authContext.features.theme.title;

  const hasHousingAssociationService = authContext.authenticated && authContext.features.services.housingAssociation.hasService;

  /* School ticket service*/
  const requireSchoolService = (features: AuthenticatedFeatures) => features.services.schoolTicket.hasService;
  const reuqireSchoolServiceAndAllowOrder = (features: AuthenticatedFeatures) =>
    features.services.schoolTicket.hasService && features.services.schoolTicket.allowOrder;

  /* Invoice access */
  const requireInvoiceAccess = (features: AuthenticatedFeatures) => features.invoice.hasAccess;

  /* Company ticket service */
  const requireCompanyService = (features: AuthenticatedFeatures) => features.services.companyTicket.hasService;
  const requireCompanyServiceAndAllowOrderTicket = (features: AuthenticatedFeatures) =>
    features.services.companyTicket.hasService && features.services.companyTicket.allowOrderTicket;

  /* Ticket counter service*/
  const requireTicketCounterService = (features: AuthenticatedFeatures) => features.services.ticketCounter.hasService;
  const requireValidTicketCounterServiceAndAllowOrder = (features: AuthenticatedFeatures) =>
    features.services.ticketCounter.hasService && features.services.ticketCounter.allowedOrderTicket;

  const requireValidTicketCounterServiceAndAllowSettings = (features: AuthenticatedFeatures) =>
    features.services.ticketCounter.hasService && features.services.ticketCounter.allowedSettings;

  const requireValidTicketCounterServiceAndAllowPaymentInAppEligible = (features: AuthenticatedFeatures) =>
    features.services.ticketCounter.hasService && features.services.ticketCounter.allowedPaymentInAppEligible;

  /*Freeticket service */
  const requireFreeticketService = (features: AuthenticatedFeatures) => features.services.freeTicket.hasService;
  const requireFreeTicketServiceAndAllowOrderTicket = (features: AuthenticatedFeatures) =>
    features.services.freeTicket.hasService && features.services.freeTicket.allowOrderTicket;

  const requireFreeTicketServiceAndTravelCardAdmin = (features: AuthenticatedFeatures) =>
    features.services.freeTicket.hasService && features.services.freeTicket.hasTravelCardAdmin;

  /*Housing association service */
  const requireHousingAssociationService = (features: AuthenticatedFeatures) =>
    features.services.housingAssociation.hasService;

  const requireHousingAssociationServiceAndAllowOrderTicket = (features: AuthenticatedFeatures) =>
    features.services.housingAssociation.hasService && features.services.housingAssociation.allowOrderTicket;

  /* Travel card admin */
  const requireTravelCardAdmin = (features: AuthenticatedFeatures) => features.travelCardAdmin.hasAccess;
  /* User admin rights */
  const requireUserAdminRights = (features: AuthenticatedFeatures) => features.userAdmin.hasAdminRights;

  /* Company admin */
  const requireCompanyAdmin = (features: AuthenticatedFeatures) => features.companyAdmin.hasAccess;

  const requireHousingAssociationOrCompanyService = (features: AuthenticatedFeatures) => features.services.housingAssociation.hasService
    || features.services.companyTicket.hasService;

  return (
    <div id="ruter-bedrift-app">
      <ToastContainer className="toast-container" toastClassName="toast" />
      <Header />
      <main>
        <Sentry.ErrorBoundary key={location.key} fallback={() => <GenericError />}>
          <Routes>
            {/* public routes */}
            <Route path="/bedriftsavtale/info" element={<AgreementInfo />} />
            <Route path="/billettluka/info" element={<TicketCounterInfo />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/register" element={<Onboarding />} /> */}
            {authContext.features.onboarding.hasAccess && <Route path="/register" element={<SimpleOnboarding />} />}
            <Route path="/register/verify-phone" element={<OnboardingCallback />} />
            <Route path="/verktoy" element={<Tools />} />
            <Route path="/vilkar" element={<TermsAndConditions />} />
            <Route path="/vilkar/ruterbedrift" element={<RuterBedrift />} />
            <Route path="/vilkar/joule/persjonvernerklaering" element={<PrivacyDeclarationEBike />} />
            <Route path="/vilkar/joule/vilkar" element={<TermsForEBike />} />
            <Route path="/fribilletter/info" element={<FreeTicketInfo />} />


            {/* Requires SchoolTicketAgreement */}
            <Route path="/skole" element={
              <SecureComponent requiredFeature={requireSchoolService}>
                <SchoolTicketOrderHistory />
              </SecureComponent>
            } />
            <Route path="/skole/bestill" element={
              <SecureComponent requiredFeature={reuqireSchoolServiceAndAllowOrder}>
                <SchoolTicketOrder />
              </SecureComponent>
            } />
            <Route path="/skole/:id" element={
              <SecureComponent requiredFeature={requireSchoolService}>
                <SchoolTicketDetail />
              </SecureComponent>
            } />

            {/* Requires BusinessAgreement */}
            <Route path="/bedriftsavtale" element={
              <SecureComponent requiredFeature={requireCompanyService}>
                <EmployeesPage />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/faktura/:id" element={
              <SecureComponent requiredFeature={requireInvoiceAccess}>
                <InvoiceDetails />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/lonnstrekk" element={
              <SecureComponent requiredFeature={requireCompanyService}>
                <PayrollPage />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/innstillinger" element={
              <SecureComponent requiredFeature={requireCompanyService}>
                <Settings />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/godkjenning" element={
              <SecureComponent requiredFeature={requireCompanyService}>
                <TicketRequest />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/billett/opprett" element={
              <SecureComponent requiredFeature={requireCompanyServiceAndAllowOrderTicket}>
                <CreateSubscriptionForm />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/ansatt/:id" element={
              <SecureComponent requiredFeature={requireCompanyService}>
                <EmployeeDetailsPage />
              </SecureComponent>
            } />
            <Route path="/verktoy/widget" element={
              <SecureComponent requiredFeature={requireHousingAssociationOrCompanyService}>
                {hasHousingAssociationService ? <HousingAssociationWidgetPage /> : <WidgetPage />}
              </SecureComponent>
            } />
            <Route path="/verktoy/link" element={
              <SecureComponent requiredFeature={requireHousingAssociationOrCompanyService}>
                {hasHousingAssociationService ? <HousingAssociationLinkPage /> : <LinkPage />}
              </SecureComponent>
            } />


            {/* Requires TicketCounterAgreement */}
            <Route path="/billettluka" element={
              <SecureComponent requiredFeature={requireValidTicketCounterServiceAndAllowOrder}>
                <TicketCounterOrder />
              </SecureComponent>
            } />
            <Route path="/billettluka/ordrehistorikk" element={
              <SecureComponent requiredFeature={requireTicketCounterService}>
                <OrderHistory />
              </SecureComponent>
            } />
            <Route path="/billettluka/ordrehistorikk/:id" element={
              <SecureComponent requiredFeature={requireTicketCounterService}>
                <OrderDetails />
              </SecureComponent>
            } />
            <Route path="/billettluka/billetthistorikk/" element={
              <SecureComponent requiredFeature={requireTicketCounterService}>
                <TicketHistory />
              </SecureComponent>
            } />
            <Route path="/billettluka/innstillinger/" element={
              <SecureComponent requiredFeature={requireValidTicketCounterServiceAndAllowSettings}>
                <TicketCounterSettings />
              </SecureComponent>
            } />
            <Route path="/billettluka/bedriften-betaler" element={
              <SecureComponent requiredFeature={requireValidTicketCounterServiceAndAllowPaymentInAppEligible}>
                <TicketCounterWhitelist />
              </SecureComponent>
            } />
            {/* Requires BusinessAgreement or TicketCounterAgreement */}
            <Route path="/faktura" element={
              <SecureComponent requiredFeature={requireInvoiceAccess}>
                <InvoicePage />
              </SecureComponent>
            } />

            {/* Requires GlobalUserAdmin or UserAdmin */}
            <Route path="/brukere" element={
              <SecureComponent requiredFeature={requireUserAdminRights}>
                <UsersPage />
              </SecureComponent>
            } />
            <Route path="/brukere/ny" element={
              <SecureComponent requiredFeature={requireUserAdminRights}>
                <UserPage />
              </SecureComponent>
            } />
            <Route path="/brukere/:id" element={
              <SecureComponent requiredFeature={requireUserAdminRights}>
                <UserPage />
              </SecureComponent>
            } />

            {/* Requires GlobalUserAdmin and Brakar */}
            <Route path="/fribilletter/reisekort" element={
              <SecureComponent requiredFeature={requireFreeTicketServiceAndTravelCardAdmin}>
                <TravelCardTodoPage />
              </SecureComponent>
            } />

            {/* Requires FreeTicketAgreement */}
            <Route path="/fribilletter/massebestillinger" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <MassOrders />
              </SecureComponent>
            } />
            <Route path="/fribilletter/massebestillinger/ny" element={
              <SecureComponent requiredFeature={requireFreeTicketServiceAndAllowOrderTicket}>
                <MassOrderCreate />
              </SecureComponent>
            } />
            <Route path="/fribilletter/massebestillinger/avslutt" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <MassEndDate />
              </SecureComponent>
            } />
            <Route path="/fribilletter/massebestillinger/byttavdeling" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <MassChangeDepartment />
              </SecureComponent>
            } />
            <Route path="/fribilletter" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <FreeTickets />
              </SecureComponent>
            } />
            <Route path="/fribilletter/ny" element={
              <SecureComponent requiredFeature={requireFreeTicketServiceAndAllowOrderTicket}>
                <FreeTicketForm />
              </SecureComponent>
            } />
            <Route path="/fribilletter/endringer" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <TaxBaseChangesPage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/skattegrunnlag" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <TaxBase />
              </SecureComponent>
            } />
            <Route path="/fribilletter/fakturagrunnlag" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <FreeTicketInvoiceBasePage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/fakturagrunnlag/:id" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <FreeTicketInvoiceBaseDetailsPage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/innstillinger" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <FreeTicketSettingsPage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/:id" element={
              <SecureComponent requiredFeature={requireFreeticketService}>
                <FreeTicketsFamily />
              </SecureComponent>
            } />

            {/* Required HousingAssociationAgreement */}
            <Route path="/borettslag" element={
              <SecureComponent requiredFeature={requireHousingAssociationService}>
                <HousingAssociationResidents />
              </SecureComponent>
            } />
            <Route path="/borettslag/opprett" element={
              <SecureComponent requiredFeature={requireHousingAssociationServiceAndAllowOrderTicket}>
                <HousingAssociationForm />
              </SecureComponent>
            } />
            <Route path="/borettslag/innstillinger" element={
              <SecureComponent requiredFeature={requireHousingAssociationService}>
                <HousingAssociationSettings />
              </SecureComponent>
            } />
            <Route path="/borettslag/kostnader-boenhet" element={
              <SecureComponent requiredFeature={requireHousingAssociationService}>
                <HousingUnitCosts />
              </SecureComponent>
            } />
            <Route path="/borettslag/boenhet/:id" element={
              <SecureComponent requiredFeature={requireHousingAssociationService}>
                <HousingUnit />
              </SecureComponent>
            } />
            <Route path="/borettslag/godkjenning" element={
              <SecureComponent requiredFeature={requireHousingAssociationService}>
                <HousingAssociationTicketRequestPage />
              </SecureComponent>
            } />

            {/* Required TravelCardAdmin and tenant = ruter */}
            <Route path="/reisekort" element={
              <SecureComponent requiredFeature={requireTravelCardAdmin}>
                <TravelCardSearchPage />
              </SecureComponent>
            } />
            <Route path="/reisekort/:companyId/:date" element={
              <SecureComponent requiredFeature={requireTravelCardAdmin}>
                <TravelCardDetails />
              </SecureComponent>
            } />

            {/* Requires CompanyAdmin */}
            <Route path="/bedrifter/ny" element={
              <SecureComponent requiredFeature={requireCompanyAdmin}>
                <CreateCompanyPage />
              </SecureComponent>
            } />
            <Route path="/bedrifter/:id" element={
              <SecureComponent requiredFeature={requireCompanyAdmin}>
                <CompanyPage />
              </SecureComponent>
            } />
            <Route path="/bedrifter" element={
              <SecureComponent requiredFeature={requireCompanyAdmin}>
                <CompaniesPage />
              </SecureComponent>
            } />

            {/* Requires that user is authenticated */}
            <Route path="/minside" element={
              <SecureComponent>
                <CompanyInfoPage />
              </SecureComponent>
            } />
            <Route path="/kontaktoss" element={
              <SecureComponent>
                <ContactUs />
              </SecureComponent>
            } />

            <Route path="/" element={<RootRedirect />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </Sentry.ErrorBoundary>
      </main>
      <Footer />
      <CypressTestData />
    </div>
  );
};

export default App;
