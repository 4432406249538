import { Icon, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { NavLink } from "react-router-dom";
import "./MobileMenu.scss";
import { headerLanguageResource } from "./languageResource";
import { MenuItem, MenuState, SubMenuItem } from "./menuState";

interface Props {
  menu: MenuState,
  hideMobileMenu: () => void;
  showMobileMenu: () => void;
  closeMenuItem: (id: string) => void;
  expandMenuItem: (id: string) => void;
}

const MobileMenu: React.FC<Props> = ({ menu, hideMobileMenu, showMobileMenu, closeMenuItem, expandMenuItem }) => {
  const language = useLanguageResource(headerLanguageResource);
  const { setSelectedLanguage, selectedLanguage } = useLanguageContext();

  const toggleOpen = () => {
    if (menu.showMobileMenu) {
      hideMobileMenu();
    } else {
      showMobileMenu();
    }
  };

  const toggleLanguage = () => {
    if (selectedLanguage === "en") {
      setSelectedLanguage("nb");
    } else {
      setSelectedLanguage("en");
    }
    hideMobileMenu();
  };

  const toggleItemGroup = (id: string) => {
    const open = menu.openItems.indexOf(id) !== -1;

    if (open) {
      closeMenuItem(id);
    } else {
      expandMenuItem(id);
    }
  };

  const renderSubMenuItem = (item: SubMenuItem) => {
    return (
      <NavLink key={item.id} to={item.path} onClick={() => { hideMobileMenu(); }}>
        {(language as any)[item.id]}
      </NavLink>
    );
  };

  const renderMenuItem = (item: MenuItem) => {
    const open = menu.openItems.indexOf(item.id) !== -1;

    return item.subMenu && item.subMenu.length > 0 ? (
      <div key={item.id} className={open ? "item-group open" : "item-group"}>
        <button type="button" className="toggle-expand" onClick={() => toggleItemGroup(item.id)}>
          {(language as any)[item.id]}
          {open && <Icon variant="ChevronUpIcon" size="small" />}
          {!open && <Icon variant="ChevronDownIcon" size="small" />}
        </button>
        <div className="sub-items">{item.subMenu.map((subMenuItem) => renderSubMenuItem(subMenuItem))}</div>
      </div>
    ) : (
      <NavLink key={item.id} to={item.path} onClick={() => { hideMobileMenu(); }}>
        {(language as any)[item.id]}
      </NavLink>
    );
  };

  const renderToggleButton = () => {
    const open = menu.showMobileMenu;

    if (open) {
      return (
        <button type="button" className="toggle-button open" onClick={toggleOpen}>
          <Icon size="small" variant="CrossIcon" className="icon" styles={{ color: "black", marginBottom: "8px", marginTop: "8px" }} />
          <div className="menu-text">{language.close}</div>
        </button>
      );
    }

    return (
      <button type="button" className="toggle-button" onClick={toggleOpen}>
        <Icon size="large" variant="HamburgerIcon" className="icon" />
        <div className="menu-text">{language.menu}</div>
      </button>
    );
  };

  const open = menu.showMobileMenu;

  return (
    <div className="components-layout-mobilmenu">
      {renderToggleButton()}
      {open && (
        <div className="menu-content">
          {menu.items.map((item) => renderMenuItem(item))}
          <button type="button" onClick={toggleLanguage}>
            {language.changeLanguage}
          </button>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;