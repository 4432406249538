import { formatter, Icon, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useState } from "react";
import { getHumanReadableSubscriptionType, Subscription } from "src/common/api/companyAgreementApi/Subscription";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import InfoCard, { InfoCardRowType } from "src/components/common/cards/infoCard/InfoCard";
import TicketStatus from "src/components/common/text/ticketStatus/TicketStatus";
import { getLocaleText } from "src/types/localeText";
import { formatDate, formatText } from "../formatter";
import { InfoCardButtonType } from "../infoCard/InfoCardButton";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { subscriptionCardLanguageResource } from "./lang-resource";
import ResendSubscriptionConfirmationModal from "./ResendSubscriptionConfirmationModal";

interface Props {
  subscription: Subscription
  onUpdateRequest: () => void
  disableResendButton: boolean
}


type ModalType = "CANCEL_SUBSCRIPTION" | "RESEND_DISCOUNT_CODE"

const getModal = (
  type: ModalType,
  subscription: Subscription,
  onUpdateRequest: () => void,
  onClose: () => void
) => {
  switch (type) {
    case "CANCEL_SUBSCRIPTION":
      return <CancelSubscriptionModal handleClose={onClose} onSubmit={() => { onUpdateRequest(); }} subscription={subscription} />
    case "RESEND_DISCOUNT_CODE":
      return <ResendSubscriptionConfirmationModal handleClose={onClose} onSubmit={() => { onUpdateRequest(); }} subscription={subscription} />
  }
}


const SubscriptionCard: React.FC<Props> = ({ subscription, onUpdateRequest, disableResendButton }) => {
  const lang = useLanguageResource(subscriptionCardLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const { selectedLanguage } = useLanguageContext();
  const [modalType, setModalType] = useState<ModalType>();

  const humanReadableType = getHumanReadableSubscriptionType(subscription.type);
  const subscriptionIsActiveOrPending = subscription.status === "active" || subscription.status === "pending";

  const infoRows: InfoCardRowType[] = [
    { label: lang.id, value: subscription.id, "data-test-id": "subscription-id" },
    { label: lang.status, value: <TicketStatus status={subscription.status} />, "data-test-id": "subscription-status" },
    { label: formLang.phone, value: formatText(`${subscription.phoneCountryCode} ${subscription.phone}`), "data-test-id": "subscription-phone"},
    { label: lang.validFrom, value: formatDate(subscription.startAt), "data-test-id": "subscription-valid-from" },
    { label: lang.validTo, value: formatDate(subscription.expireAt), "data-test-id": "subscription-valid-to" },
    { label: lang.pricePerMonth, value: formatter.number.currency(subscription.pricePerMonth, true), "data-test-id": "subscription-price" },
  ];

  const buttons: InfoCardButtonType[] = [
    {
      onClick: () => setModalType("RESEND_DISCOUNT_CODE"),
      text: lang.resendSubscription,
      icon: < Icon variant="CellPhoneArrowRightIcon" size="small" />,
      disabled: !subscriptionIsActiveOrPending || disableResendButton,
      "data-test-id": "subscription-resend-button",
    },
    {
      onClick: () => setModalType("CANCEL_SUBSCRIPTION"),
      text: lang.cancelSubscriptionTitle,
      icon: <Icon variant="CrossIcon" size="small" />,
      disabled: !subscriptionIsActiveOrPending || Boolean(subscription.expireAt),
      "data-test-id": "subscription-cancel-button",
    },
  ];

  return (
    <>
      <InfoCard
        id={subscription.id}
        label={getLocaleText(humanReadableType, selectedLanguage)}
        status={subscription.status}
        infoRows={infoRows}
        buttons={buttons}
        data-test-id="subscription-card" />
      {modalType && getModal(modalType, subscription, onUpdateRequest, () => {setModalType(undefined)})}
    </>
  );
};

export default SubscriptionCard;
