import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface FormFieldsLanguageResource {
  phone: string;
  mobile: string;
  firstName: string;
  lastName: string;
  invoiceReference: string;
  startDate: string;
  endDate: string;
  cancel: string;
  save: string;
  yes: string;
  selectPickText: string;
  email: string;
  emailPlaceHolder: string;
  submitError: string;
  validationErrors: string;
  formSaved: string;
  numberOfZones: string;
  oneZone: string;
  twoZones: string;
  allZones: string;
  forZone: string;
  toZone: string;
  companyName: string;
  birthDate: string;
  relation: string;
  employeeNumber: string;
  today: string;
  status: string
  no: string
  mobileTicket: string,
  travelCard: string
  chooseSubscription: string
  order: string
}

export const formFieldsLanguageResource: LanguageResource<FormFieldsLanguageResource> = {
  nb: {
    phone: "Telefonnummer",
    mobile: "Mobilnummer",
    firstName: "Fornavn",
    lastName: "Etternavn",
    invoiceReference: "Fakturaref (avtalenummer)",
    startDate: "Startdato",
    endDate: "Sluttdato",
    cancel: "Avbryt",
    save: "Lagre",
    yes: "Ja",
    selectPickText: "velg $label$",
    email: "E-postadresse",
    emailPlaceHolder: "eksempel: navn@firma.no",
    submitError: "Det skjedde en feil ved lagring av skjema",
    validationErrors: "Du må rette valideringsfeil i skjema",
    formSaved: "Data lagret!",
    numberOfZones: "Antall soner",
    oneZone: "Én sone",
    twoZones: "To soner",
    allZones: "Alle soner",
    forZone: "For sone",
    toZone: "Til sone (valgfritt)",
    companyName: "Firmanavn",
    birthDate: "Fødselsdato",
    relation: "Relasjon",
    employeeNumber: "Ansattnr",
    today: "I dag",
    status: "Status",
    no: "Nei",
    mobileTicket: "Mobilbillett",
    travelCard: "Reisekort",
    chooseSubscription: "Velg abonnement",
    order: "Bestill",
  },
  en: {
    phone: "Phone number",
    mobile: "Mobile number",
    firstName: "First name",
    lastName: "Last name",
    invoiceReference: "Invoice reference (agreement number)",
    startDate: "Start date",
    endDate: "End date",
    cancel: "Cancel",
    save: "Save",
    yes: "Yes",
    selectPickText: "Select $label$",
    email: "Email",
    emailPlaceHolder: "example: name@company.com",
    submitError: "an error occured while saving the form",
    validationErrors: "You need to fix validation errors before submitting",
    formSaved: "Data saved!",
    numberOfZones: "Number of zones",
    oneZone: "One zone",
    twoZones: "Two zones",
    allZones: "All zones",
    forZone: "For Zone",
    toZone: "To zone (optional)",
    companyName: "Company name",
    birthDate: "Birth date",
    relation: "Relation",
    employeeNumber: "Employee no.",
    today: "Today",
    status: "Status",
    no: "No",
    mobileTicket: "Mobile ticket",
    travelCard: "Travel card",
    chooseSubscription: "Choose subscription",
    order: "Order",
  },
};