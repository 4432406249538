import { LanguageResource, languageResourceBuilder, TwoParamsLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

interface CreateCompanyTicketErrorLanguageResource {
  gapToSmall: TwoParamsLanguageResourceTemplate;
  errorExistingOpenTicket: string;
}

export const createCompanyTicketErrorLanguageResource: LanguageResource<CreateCompanyTicketErrorLanguageResource> = {
  nb: {
    gapToSmall:
      languageResourceBuilder.twoParamTemplate("Bestilling av ny billett ble avvist, fordi det finnes en billett på dette telefonnummeret med sluttdato $p1$. Det er ikke mulig å ha overlappende billetter, første mulige startdato er derfor $p2$."),
    errorExistingOpenTicket: "Det eksisterer allerede en gyldig eller ventende billett på dette telefonnummeret.",
  },
  en: {
    gapToSmall: languageResourceBuilder.twoParamTemplate("Could not order a new ticket. A ticket that has expired on $p1$ already exists, according to our rules, the first available start date for a new ticket is $p2$"),
    errorExistingOpenTicket: "There already exists a pending or active ticket for this phonenumber.",
  },
};
