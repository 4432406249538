import { apiClient, Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContextAuthenticated, useValidHousingAssociationService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import { AvailableSubscription } from "src/common/api/companyAgreementApi/AvailableSubscription";
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { Subscription } from "src/common/api/companyAgreementApi/Subscription";
import SkeletonTicket from "src/components/companyAgreement/employeeDetails/SkeletonTicket";
import { SearchResult } from "src/types/SearchResult";
import "./HousingUnit.scss";
import { housingUnitLanguageResource } from "./lang-resource";
import CreateSubscriptionModal from "./modals/CreateSubscriptionModal";
import PersonWithTicketOrSubscription, { PersonWithTicketsOrSubscriptions, TicketsOrSubscriptions } from "./ticketOrSubscriptionsForPerson/TicketOrSubscriptionsForPerson";

interface PageData {
  orders: SearchResult<HousingAssociationOrder>;
  zones: Zone[];
  subscriptions: Subscription[];
  availableSubscriptions: AvailableSubscription[];
}


export type ModalType = {
  type: "NEW_ORDER"
  ticket: HousingAssociationOrder
  availableSubscriptions: AvailableSubscription[]
}



export const getName = (ticketOrSubscription: Subscription | HousingAssociationOrder) =>
  `${ticketOrSubscription.firstName} ${ticketOrSubscription.lastName}`

const sortTicketOrSubscriptionByStatus = (a: Subscription | HousingAssociationOrder, b: Subscription | HousingAssociationOrder) => {
  const getSortNumber = (subscriptionOrTicket: Subscription | HousingAssociationOrder) => {
    let sortNumber = subscriptionOrTicket.status === "active" ? 0 : 10
    if(subscriptionOrTicket.status === "pending") {
      sortNumber = 5
    } 
    if (subscriptionOrTicket.type !== "TICKET") {
      sortNumber += 1
    }
    return sortNumber
  }

  return getSortNumber(a) - getSortNumber(b)
}

export const groupTicketsAndSubscriptionsByName = (ticketsAndSubscriptions: TicketsOrSubscriptions): PersonWithTicketsOrSubscriptions[] => {
  const groupedTicketsAndSubscriptions: PersonWithTicketsOrSubscriptions[] = []
  ticketsAndSubscriptions.forEach(ticketOrSubscription => {
    const existingGroup = groupedTicketsAndSubscriptions.find(x => x.name === getName(ticketOrSubscription))
    if (existingGroup) {
      existingGroup.ticketsOrSubscriptions.push(ticketOrSubscription)
    } else {
      groupedTicketsAndSubscriptions.push({ name: getName(ticketOrSubscription), ticketsOrSubscriptions: [ticketOrSubscription] })
    }
  })

  groupedTicketsAndSubscriptions.forEach(x => {
    x.ticketsOrSubscriptions.sort(sortTicketOrSubscriptionByStatus)
  })

  groupedTicketsAndSubscriptions.sort((a, b) => b.name > a.name ? -1 : 1)

  return groupedTicketsAndSubscriptions
}

const HousingUnit: React.FC = () => {
  const language = useLanguageResource(housingUnitLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const housingAssociationService = useValidHousingAssociationService();
  const { id } = useParams();
  const [pageData, setPageData] = useState<PageData | undefined>();
  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState<ModalType>();

  const fetch = useCallback(async () => {
    setLoading(true);
    const [ordersResponse, zonesResponse, subscriptionsResponse, availableSubscriptionsResponse] = await Promise.all([
      apiClient.request(housingAssociationApi.subscriber.getTicketsBySubcsriberId(id || "")),
      apiClient.request(housingAssociationApi.zones.getAll()),
      apiClient.request(housingAssociationApi.subscriber.getSubscriptionsBySubscriberId(id || "")),
      apiClient.request(housingAssociationApi.subscription.available.geyByCompanyId(authContext.userData.selectedCompany.id)),
    ]);

    if (ordersResponse.type !== "success") { setPageData(() => { throw ordersResponse.error; }); return; }
    if (zonesResponse.type !== "success") { setPageData(() => { throw zonesResponse.error; }); return; }
    if (subscriptionsResponse.type !== "success") { setPageData(() => { throw subscriptionsResponse.error; }); return; }
    if (availableSubscriptionsResponse.type !== "success") { setPageData(() => { throw availableSubscriptionsResponse.error }); return; }

    setPageData({
      orders: ordersResponse.result,
      zones: zonesResponse.result,
      subscriptions: subscriptionsResponse.result.matches,
      availableSubscriptions: availableSubscriptionsResponse.result
    });
    setLoading(false);

  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);


  const renderSkeleton = () => (
    <Container width="m" className="components-housing-unit">
      <div className="skeleton-h1" />
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-employee-info">
        <div className="content" />
      </div>
      <div className="skeleton-h2">
        <div className="content" />
      </div>
      <div className="tickets">
        {pageData?.orders.matches.map((ticket) => (
          <SkeletonTicket key={ticket.id} />
        ))}
      </div>
    </Container>
  );


  if (loading || pageData === undefined) {
    return renderSkeleton();
  }

  const groupedTicketsAndSubscriptions = groupTicketsAndSubscriptionsByName(structuredClone([...pageData.orders.matches, ...pageData.subscriptions]))

  return (
    <Container width="l" className="components-housing-unit" data-test-id="components-housing-unit">
      <h1>{language.title(pageData.orders.matches[0].employeeId)}</h1>
      <div className="tickets">
        {groupedTicketsAndSubscriptions.map(person => <PersonWithTicketOrSubscription
          person={person}
          availableSubscriptions={pageData.availableSubscriptions}
          allowOrderTicket={housingAssociationService.allowOrderTicket}
          setModalType={setModalType}
          onUpdateRequest={fetch}
          zones={pageData.zones}
          key={person.name}
        />)}
      </div>
      {modalType?.type == "NEW_ORDER" &&
        <CreateSubscriptionModal
          availableSubscriptions={modalType.availableSubscriptions}
          onClose={() => { setModalType(undefined) }}
          onSuccess={() => { fetch(); setModalType(undefined) }}
          ticket={modalType.ticket}
          zoneInput={authContext.features.getZoneInput(pageData.zones)}
          terms={authContext.features.urls.terms}
        />}

    </Container>
  );
};

export default HousingUnit;