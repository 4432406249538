import { apiClient, ButtonGroup, CheckBoxForminput, CheckboxFormInputProps, CheckboxList, DropdownFormInput, FormGroup, Modal, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { AvailableSubscription } from "src/common/api/companyAgreementApi/AvailableSubscription"
import { mapCreateTicketPostContract } from "src/common/api/companyAgreementApi/createTicketPostContract"
import CreateHousingTicketFormData from "src/common/api/companyAgreementApi/housingAssociation/createHousingTicketFormDate"
import housingAssociationApi from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationApi"
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder"
import { mapSubscriptionPostContract } from "src/common/api/companyAgreementApi/subscriptionPostContract"
import { getExpiryDates } from "src/common/expiryDate"
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource"
import { CancelButton, SubmitButton } from "src/components/common/buttons"
import PrivacyTerms from "src/components/companyAgreement/common/privacyTerms/PrivacyTerms"
import { UrlType } from "src/types/features/Urls"
import { getLocaleText } from "src/types/localeText"
import { SubscriptionType } from "src/types/subscriptionType"
import { getName } from "../HousingUnit"
import "./CreateSubscriptionModal.scss"
import housingUnitCreateSubLanguageResource from "./lang-resource"

interface Props {
  ticket: HousingAssociationOrder
  availableSubscriptions: AvailableSubscription[]
  zoneInput: JSX.Element
  terms?: UrlType
  onSuccess: () => void
  onClose: () => void
}

interface FormData extends Record<SubscriptionType, boolean> {
  acceptPrivacy: boolean
  startDate: string
  zoneFrom: string
  zoneTo: string
}

const mapSubscriptionOptions = (
  availableSubscription: AvailableSubscription,
  disabled: boolean,
  selectedLanguage: "en" | "nb"
): CheckboxFormInputProps => ({
  name: availableSubscription.type,
  label: getLocaleText(availableSubscription.name, selectedLanguage),
  description: getLocaleText(availableSubscription.description, selectedLanguage),
  disabled
})


const CreateSubscriptionModal: React.FC<Props> = ({ availableSubscriptions, onClose, onSuccess, ticket, zoneInput, terms }) => {
  const lang = useLanguageResource(housingUnitCreateSubLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const { selectedLanguage } = useLanguageContext();
  const [submitting, setSubmitting] = useState(false);

  const dates = getExpiryDates(6, formLang.today);

  const ticketIsAvailable = availableSubscriptions.some(sub => sub.type === "TICKET")

  const formMethods = useForm<FormData>({
    defaultValues: {
      startDate: dates[0].value,
      TICKET: ticketIsAvailable
    }
  })

  useEffect(() => {
    if (availableSubscriptions.length === 1) {
      const { type } = availableSubscriptions[0];
      formMethods.setValue(type, true);
    }
  }, [])

  const submitCreateSubscription = async (data: CreateHousingTicketFormData): Promise<Array<true | Error>> => {
    const postContracts = mapSubscriptionPostContract(data);
    const responses = await Promise.all(postContracts.map(contract => apiClient.request(housingAssociationApi.subscription.create(contract))));

    return responses.map(response => response.type === "success" || response.error);
  };

  const onSubmit = async (formData: FormData) => {
    setSubmitting(true);
    const combinedTicketAndFormData: CreateHousingTicketFormData = {
      ...formData,
      agreementNumber: ticket.agreementNumber,
      employeeNumber: ticket.employeeId,
      firstName: ticket.firstName,
      lastName: ticket.lastName,
      phone: `${ticket.phoneCountryCode} ${ticket.phone}`,
      profile: ticket.profileId,
    }

    if (formData.TICKET) {
      const backendTicketContract = mapCreateTicketPostContract(combinedTicketAndFormData, false, false)

      const response = await apiClient.request(housingAssociationApi.ticket.create(backendTicketContract));

      if (response.type !== "success") {
        setSubmitting(() => { throw response.error })
        return;
      }
    }

    if (formData.OTTO || formData.JOULE) {
      const subscriptionResponses = await submitCreateSubscription(combinedTicketAndFormData)

      const error = subscriptionResponses.find(x => x !== true)

      if (error) {
        setSubmitting(() => { throw error })
        return
      }
    }

    onSuccess()
  }

  const renderDatePicker = () => {
    if (!ticketIsAvailable) {
      return null;
    }

    return (
      <FormGroup>
        <DropdownFormInput name="startDate" required label={lang.startDate}>
          {dateOptions.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
        </DropdownFormInput>
      </FormGroup>
    )
  }

  const subOptions = availableSubscriptions.map((sub) => mapSubscriptionOptions(sub, sub.type === "TICKET", selectedLanguage))

  const dateOptions = dates.map((o) => ({
    value: o.value,
    text: o.text,
  }));


  const selectedSubscriptions = availableSubscriptions.map(sub => sub.type).filter(subType => formMethods.watch(subType));
  const subscriptionSelected = selectedSubscriptions.length > 0

  const hasOnlyOneAvailableSubscription = availableSubscriptions.length === 1
  const title = lang.order + " " + (hasOnlyOneAvailableSubscription ? getLocaleText(availableSubscriptions[0].name, selectedLanguage) : lang.subscription).toLowerCase()

  return (
    <Modal isOpen handleClose={onClose} title={`${title} – ${getName(ticket)}`} data-test-id="housing-association-create-subscription-modal" className="housing-unit-create-subscription-modal">
      {hasOnlyOneAvailableSubscription && <p data-test-id="subscription-description">{getLocaleText(availableSubscriptions[0].description, selectedLanguage)}</p>}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{marginTop: "2rem"}}>
          {!hasOnlyOneAvailableSubscription && <FormGroup>
            <CheckboxList label={lang.chooseAddon} options={subOptions} />
          </FormGroup>}
          {renderDatePicker()}
          {ticketIsAvailable && <>
            <FormGroup>
              {zoneInput}
            </FormGroup>

            <CheckBoxForminput name="acceptPrivacy" label={lang.acceptPrivacy} required />
            <PrivacyTerms terms={terms} />
          </>
          }
          <ButtonGroup>
            <SubmitButton submitting={submitting} text={formLang.order} disabled={!subscriptionSelected} />
            <CancelButton onClick={() => { onClose() }} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default CreateSubscriptionModal
