import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface EmployeeTicketsLanguageResource {
  mobileNumber: string;
  companyName: string;
  changeInfo: string;
  modalTitle: string;
  businessTicket: string;
  zones: string
  payroll: string;
  amount: string;
  invoiceMonth: string;
  orderNumber: string;
  expired: string;
  submitFailure: string;
  noChangeToZoneValidationError: string;
  acceptPrivacy: string
  subscriptions: string
  description: string 
  startDate: string
  orderSuccess: string
  subscription: string
}

const employeeTicketsLanguageResource: LanguageResource<EmployeeTicketsLanguageResource> = {
  nb: {
    mobileNumber: "Mobilnummer",
    companyName: "Bedrift",
    changeInfo: "Endre info",
    modalTitle: "Legg til",
    businessTicket: "Bedriftsbillett",
    zones: "Soner",
    payroll: "Siste fem lønnstrekk",
    amount: "Beløp",
    invoiceMonth: "Fakturamnd.",
    orderNumber: "Billettnummer",
    expired: "Utløpte",
    submitFailure: "Det skjedde en feil ved lagring av skjema",
    noChangeToZoneValidationError: "Du må gjøre en endring på soner for å lagre.",
    acceptPrivacy: "Jeg bekrefter at den ansatte har fått informasjon om behandling av personopplysninger og godkjenner vilkårene, " +
      "samt at jeg som administrator har fått tillatelse til å inngå avtale på hans / hennes vegne.",
    subscriptions: "Abonnementer",
    description: "Beskrivelse",
    startDate: "Startdato (Bedriftsbillett)",
    orderSuccess: "Bestilling fullført!",
    subscription: "Abonnement"
  },
  en: {
    mobileNumber: "Mobile number",
    companyName: "Company",
    changeInfo: "Change info",
    modalTitle: "Add",
    businessTicket: "Business ticket",
    zones: "Zones",
    payroll: "Last 5 salary deductions",
    amount: "Amount",
    invoiceMonth: "Invoice month",
    orderNumber: "Ticket number",
    expired: "Expired",
    submitFailure: "An error occured while submitting this form.",
    noChangeToZoneValidationError: "You need to change zones in order to save.",
    acceptPrivacy:
      "I hereby confirm that the employee has accepted the terms and policies, and that I have been given consent by the employee to order a ticket on his/hers behalf",
    subscriptions: "Subscriptions",
    description: "Description",
    startDate: "Start date (Company ticket)",
    orderSuccess: "Order succeeded!",
    subscription: "Subscription"
  },
};

export default employeeTicketsLanguageResource;