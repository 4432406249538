import { Icon } from "@ruter-as/web-components-and-tools";
import React from "react";
import "./GotoColumn.scss";

const GotoColumn: React.FC = () => {
  return (
    <td className="goto-column">
      <Icon variant="ChevronRightIcon" size="small" />
    </td>
  );
};

export default GotoColumn;
