import { apiClient, Container, csvTools, DateInput, formatter, parser, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { addDays, addMonths, startOfMonth, startOfToday } from "date-fns";
import React, { useEffect, useState } from "react";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { Payroll } from "src/common/api/companyAgreementApi/Payroll";
import { PayrollChange } from "src/common/api/companyAgreementApi/PayrollChange";
import { ticketZonesLanguageResource } from "src/components/common/text/ticketZones/lang-resource";
import getTicketOwnerFieldName from "../../../common/ticketOwnerFieldName";
import { ButtonLink } from "../../common/buttons";
import { TicketZonesComponent } from "../../common/text/ticketZones/TicketZones";
import payrollLanguageResource from "./lang-resource";
import "./PayrollPage.scss";

const zonesAreEqual = (oldZones: Array<string>, newZones: Array<string>) => {
  if (oldZones.length !== newZones.length) {
    return false;
  }

  for (let i = 0; i < oldZones.length; i += 1) {
    if (oldZones[i] !== newZones[i]) {
      return false;
    }
  }

  return true;
};

const sorting = (rowA: Payroll, rowB: Payroll) => {
  const sortA = ((rowA.order.lastName || "").padEnd(50, "0") + (rowA.order.firstName || "")).toLowerCase();
  const sortB = ((rowB.order.lastName || "").padEnd(50, "0") + (rowB.order.firstName || "")).toLowerCase();

  if (sortA === sortB) {
    return 0;
  }

  if (sortA > sortB) {
    return 1;
  }

  return -1;
};

const PayrollPage: React.FC = () => {
  const ticketZonesLanguage = useLanguageResource(ticketZonesLanguageResource);
  const language = useLanguageResource(payrollLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const agreementNumber = companyTicketService.agreementNumber;
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const selectedCompany = authContext.userData.selectedCompany;
  const [fullPayroll, setFullPayroll] = useState<undefined | Payroll[]>();
  const [payrollChanges, setPayrollChanges] = useState<undefined | PayrollChange[]>();
  const [selectedDate, setSelectedDate] = useState(formatter.date.toShortDateString(startOfToday()));

  useEffect(() => {
    const fetch = async () => {

      const parsedSelectedDate = (() => {
        try {
          return parser.date.fromShortDateString(selectedDate);
        } catch (e) {
          return startOfToday()
        }
      })()

      const currentMonth = addDays(startOfMonth(parsedSelectedDate), 6);
      const previousMonth = addMonths(currentMonth, -1);

      const [fullPayrollResponse, payrollChangesResponse] = await Promise.all([
        apiClient.request(companyAgreementApi.invoice.getByAgreementAndDate(agreementNumber, parsedSelectedDate)),
        apiClient.request(companyAgreementApi.invoice.getChangesByAgreementNumberAndDate(agreementNumber, previousMonth, currentMonth)),
      ]);

      if (fullPayrollResponse.type !== "success") {
        setFullPayroll(() => { throw fullPayrollResponse.error; });
        return;
      }
      if (payrollChangesResponse.type !== "success") {
        setPayrollChanges(() => { throw payrollChangesResponse.error; });
        return;
      }
      fullPayrollResponse.result.sort(sorting);
      setFullPayroll(fullPayrollResponse.result);
      setPayrollChanges(payrollChangesResponse.result);
    };
    fetch();
  }, [agreementNumber, selectedDate]);

  const download = () => {
    if (!fullPayroll) {
      return;
    }

    const content: string[][] = [];

    if (companyTicketService.payRoll.export.showHeader) {
      content.push(companyTicketService.payRoll.export.columns.map(x => x.headerName));
    }

    fullPayroll.forEach((payrollRow) => {
      content.push(companyTicketService.payRoll.export.columns.map((column) => {
        const columnData = column.getData(payrollRow, ticketZonesLanguage);
        if (column.wrapAsFormula) {
          return csvTools.wrapAsFormula(columnData);
        }
        return columnData;
      }));
    });

    const fileName = `Lønnstrekk ${selectedCompany?.name} ${selectedDate}.${companyTicketService.payRoll.export.fileSuffix}`;
    csvTools.downloadAsCsv(fileName, content, companyTicketService.payRoll.export.delimiter);
  };

  const renderChangeRow = (change: PayrollChange) => {
    const rowId = (change.oldOrder && change.oldOrder.id) || (change.newOrder && change.newOrder.id);

    const oldPrice = change.oldOrder ? formatter.number.integer(Math.round(change.oldOrder.price)) : undefined;
    const newPrice = change.newOrder ? formatter.number.integer(Math.round(change.newOrder.price)) : undefined;

    if (change.changeType === "SUBSCRIBED" && change.newOrder) {
      return (
        <tr key={rowId}>
          <td>{language.changeTypeNew}</td>
          <td>{change.newOrder.lastName}</td>
          <td>{change.newOrder.firstName}</td>
          <td>{change.newOrder.phone}</td>
          <td>{change.newOrder.employeeId}</td>
          <td>
            <span className="new-value">
              <TicketZonesComponent nrOfZones={change.newOrder.numberOfZones} zones={change.newOrder.zones} />
            </span>
          </td>
          <td style={{ textAlign: "right" }}>
            <span className="price-prefix">kr</span>
            <span className="new-value">{newPrice}</span>
          </td>
        </tr>
      );
    }
    if (change.changeType === "UNSUBSCRIBED" && change.oldOrder) {
      return (
        <tr key={rowId}>
          <td>{language.changeTypeCancelled}</td>
          <td>{change.oldOrder.lastName}</td>
          <td>{change.oldOrder.firstName}</td>
          <td>{change.oldOrder.phone}</td>
          <td>{change.oldOrder.employeeId}</td>
          <td>
            <span className="old-value">
              <TicketZonesComponent nrOfZones={change.oldOrder.numberOfZones} zones={change.oldOrder.zones} />
            </span>
          </td>
          <td style={{ textAlign: "right" }}>
            <span className="price-prefix">kr</span>
            <span className="old-value">{oldPrice}</span>
          </td>
        </tr>
      );
    }

    // ChangePrice
    if (change.oldOrder === null || change.newOrder === null) {
      throw new Error("oldOrder and newOrder can not both be null");
    }

    const priceChange = oldPrice !== newPrice;
    const zoneChanged =
      change.oldOrder.numberOfZones !== change.newOrder.numberOfZones || !zonesAreEqual(change.oldOrder.zones, change.newOrder.zones);

    return (
      <tr key={rowId}>
        <td>{language.changeTypeChanged}</td>
        <td>{change.newOrder.lastName}</td>
        <td>{change.newOrder.firstName}</td>
        <td>{change.newOrder.phone}</td>
        <td>{change.newOrder.employeeId}</td>
        <td>
          {zoneChanged && (
            <span className="old-value">
              <TicketZonesComponent nrOfZones={change.oldOrder.numberOfZones} zones={change.oldOrder.zones} />
            </span>
          )}

          <span className="new-value">
            <TicketZonesComponent nrOfZones={change.newOrder.numberOfZones} zones={change.newOrder.zones} />
          </span>
        </td>
        <td style={{ textAlign: "right" }}>
          <span className="price-prefix">kr</span>
          {priceChange && <span className="old-value">{oldPrice}</span>}
          <span className="new-value">{newPrice}</span>
        </td>
      </tr>
    );
  };

  const showDatePicker = companyTicketService.payRoll.showDatePicker;

  return (
    <Container width="l" className="components-companyagreement-payroll" data-test-id="components-companyagreement-payroll">
      <h1>{`${language.title} - ${selectedDate}`}</h1>

      {showDatePicker && (
        <DateInput value={selectedDate} onChange={(newDate) => setSelectedDate(newDate)} />
      )}

      <div className="download">
        <ButtonLink text={language.downloadAsCSV} onClick={download} dataTestId="download-button" />
      </div>

      <Table
        breakpoint="600px"
        loading={payrollChanges === undefined}
        data-test-id="changes-table"
      >
        <thead>
          <tr>
            <th scope="col">{language.changeType}</th>
            <th scope="col">{language.lastName}</th>
            <th scope="col">{language.firstName}</th>
            <th scope="col">{language.phone}</th>
            <th scope="col">{ticketOwnerFieldName}</th>
            <th scope="col">{language.zones}</th>
            <th scope="col" style={{ textAlign: "right" }}>
              {language.montlyPrice}
            </th>
          </tr>
        </thead>
        <tbody>{payrollChanges?.map(renderChangeRow)}</tbody>
      </Table>
    </Container>
  );
};

export default PayrollPage;
