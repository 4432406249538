import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface CreateSubscriptionLanguageResource {
  title: string;
  acceptPrivay: string;
  addOnTitle: string;
  acceptOnBehalfOfAdministrator: string;
  ticketOrdered: string;
  acceptOtto: string;
  profile: string
  ticket: string
  subscription: string
  startDate: string
}


export const createSubscriptionLanguageResource: LanguageResource<CreateSubscriptionLanguageResource> = {
  nb: {
    title: "Bestill",
    acceptPrivay:
      "Jeg bekrefter at den ansatte har fått informasjon om behandling av personopplysninger og godkjenner vilkårene, " +
      "samt at jeg som administrator har fått tillatelse til å inngå avtale på hans / hennes vegne.",
    acceptOnBehalfOfAdministrator: "Jeg aksepterer Ruters vilkår på vegne av meg selv som administrator.",
    addOnTitle: "Tilleggstjenester",
    ticketOrdered: "Billett bestilt!",
    acceptOtto: "Jeg bekrefter at den ansatte har fått informasjon om at personopplysninger overføres til Otto",
    profile: "Reisende",
    subscription: "Abonnement",
    ticket: "Bedriftsbillett",
    startDate: "Startdato (Bedriftsbillett)"
  }, en: {
    title: "Order",
    acceptPrivay:
      "I hereby confirm that the employee has accepted the terms and policies, and that I have been given consent by the employee to order a ticket on his/hers behalf",
    acceptOnBehalfOfAdministrator: "I accept Ruters policies.",
    addOnTitle: "Additional services",
    ticketOrdered: "Ticket ordered!",
    acceptOtto: "I hereby confirm that the employee has been informed that personal data will be transferred to Otto",
    profile: "Profile",
    subscription: "Subscription",
    ticket: "Ticket",
    startDate: "Start date"
  },
};