import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface HousingAssociationTicketsLanguageResource {
  title: string
  newSubscription: string
  searchPlaceholder: string;
}

const housingAssociationTicketsLanguageResource: LanguageResource<HousingAssociationTicketsLanguageResource> = {
  nb: {
    title: "Beboere",
    newSubscription: "Nytt abonnement",
    searchPlaceholder: "Filtrer på navn eller telefon",
  },
  en: {
    title: "Residents",
    newSubscription: "New subscription",
    searchPlaceholder: "Search by name og phone",
  },
};

export default housingAssociationTicketsLanguageResource;