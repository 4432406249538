import { apiClient, Button, ButtonGroup, DropdownFormInput, FormGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { Subscription } from "src/common/api/companyAgreementApi/Subscription";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { subscriptionCardLanguageResource } from "./lang-resource";

interface Props {
  handleClose: () => void
  onSubmit: () => void
  subscription: Subscription
}

interface FormData {
  expiryDate: string
}

const CancelSubscriptionModal: React.FC<Props> = ({ handleClose, onSubmit, subscription }) => {
  const lang = useLanguageResource(subscriptionCardLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const fromDates = getExpiryDates(6, formLang.today);

  const formMethods = useForm<FormData>({
    defaultValues: {
      expiryDate: fromDates[0].value,
    },
  });

  const subscriptionIsActive = subscription.status === "active";

  const onSumbit = async (data: FormData) => {
    setSubmitError(false);
    setSubmitting(true);

    const backendFormattedExpiryDate = ensureExpiryDateIsNotInThePastAndFormatForBackend(data.expiryDate);

    if (subscriptionIsActive) {
      const response = await apiClient.request(companyAgreementApi.subscription.cancelActiveSubscription(subscription.id, backendFormattedExpiryDate));

      if (response.type === "success") {
        onSubmit();
      } else {
        setSubmitError(true);
        setSubmitting(false);
        sentry.captureException(response.error);
      }
    } else {
      const response = await apiClient.request(companyAgreementApi.subscription.cancelPendingSubscription(subscription.id));

      if (response.type === "success") {
        onSubmit();
      } else {
        setSubmitError(true);
        setSubmitting(false);
        sentry.captureException(response.error);
      }
    }

  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen
      title={lang.cancelSubscriptionTitle}
      data-test-id="cancel-subscription-modal">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSumbit)}>
          {subscriptionIsActive &&
            <DropdownFormInput name="expiryDate" label={lang.from} required>
              {fromDates.map(x => <option key={x.value} value={x.value}>{x.text}</option>)}
            </DropdownFormInput>
          }
          {!subscriptionIsActive &&
            <FormGroup>
              <p style={{ marginBottom: "1rem" }}>
                {lang.confirmCancelSubscription}
              </p>
            </FormGroup>
            }
          {submitError && <FormGroup><Message skin="danger" data-test-id="cancel-submit-error-message" title={formLang.submitError} /></FormGroup>}
          <ButtonGroup>
            <Button variant="primary" type="submit" text={formLang.yes} loading={submitting} />
            <Button variant="cancel" type="button" text={formLang.cancel} data-test-id="cancel-button" onClick={handleClose} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CancelSubscriptionModal;
