import { parseProfileV2 } from "../../../types/ProfileId";
import {
  CompanyTicketStatus,
  parseCompanyTicketStatus,
} from "./companyTicketStatus";
import { SearchResult } from "../../../types/SearchResult";
import { CompanyAgreementOrder, OrderJson } from "./order";

type SubscriberType = 'SUBSCRIPTION' | 'TICKET'

export interface SubscriberJson {
  id: string;
  agreementId: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  status: string;
}

export interface Subscriber {
  id: string;
  agreementId: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  status: CompanyTicketStatus;
}


export const mapSubscriber = (json: SubscriberJson): Subscriber => ({
  ...json,
  status: parseCompanyTicketStatus(json.status)
});

const parseSubscriberType = (subscriberType : string) : SubscriberType => {
  if ( subscriberType === "SUBSCRIPTION" || subscriberType === "TICKET") {
    return subscriberType
  }
  throw new Error(`Could not find subscriber type: ${subscriberType}`);
}

export const mapSubscriberSearchResult = (json: SearchResult<SubscriberJson>): SearchResult<Subscriber> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapSubscriber),
});

export const getValidSubscriber = (changes?: Partial<SubscriberJson>): SubscriberJson => ({
  id: "T12223359",
  agreementId: "1-3369898706",
  employeeId: "kiha",
  firstName: "firstName",
  lastName: "lastName",
  phone: "98064216",
  phoneCountryCode: "+47",
  status: "cancelled",
  ...changes,
});
