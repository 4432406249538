 
import { Icon } from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import React from "react";
import MediaType from "../../../types/mediaType";
import { SchoolTicket } from "../../../types/schoolTicketAgreement/schoolTicket";
import "./MediaTypeColumn.scss";

interface Props {
  schoolTicket: SchoolTicket;
  label?: string;
}

const MediaTypeColumn: React.FC<Props> = ({ schoolTicket, label }) => {
  if (schoolTicket.mediaType === MediaType.TRAVEL_CARD) {
    return (
      <td className="components-freeticketagreeemnt-mediatypecolumn travel-card" data-label={label}>
        <Icon variant="TicketOutlineIcon" size="medium"/>
        {schoolTicket.cardNumber}
      </td>
    );
  }

  let text = `${schoolTicket.phoneCountryCode || ""} ${schoolTicket.phone || ""}`;

  const parsedNumber = parsePhoneNumberFromString((schoolTicket.phoneCountryCode || "") + (schoolTicket.phone || ""));

  if (parsedNumber && parsedNumber.isValid()) {
    text = parsedNumber.formatInternational();
  }
  return (
    <td className="components-freeticketagreeemnt-mediatypecolumn mobile-ticket" data-label={label}>
      <Icon variant="CellPhoneIcon" size="medium" />
      {text}
    </td>
  );
};

export default MediaTypeColumn;
