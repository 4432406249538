import parsePhoneNumberFromString from "libphonenumber-js/min";
import { subscriptionTypeArray } from "src/types/subscriptionType";
import { CreateSubscriptionFormData } from "./createServiceFormData";
import CreateHousingTicketFormData from "./housingAssociation/createHousingTicketFormDate";

export interface SubscriptionPostContract {
  agreementId: string
  employeeId: string
  firstName: string
  lastName: string
  phone: string
  phoneCountryCode: string
  type: string
}

export const mapSubscriptionPostContract = (data: CreateSubscriptionFormData | CreateHousingTicketFormData): SubscriptionPostContract[] => {
  const phone = parsePhoneNumberFromString(data.phone);

  if (!phone || !phone.isValid()) {
    throw new Error("invalid phone number should be impossible as it is validated by the form");
  }

  const postContracts: SubscriptionPostContract[] = [];

  const baseContract: SubscriptionPostContract = {
    agreementId: data.agreementNumber,
    employeeId: data.employeeNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: phone.nationalNumber.toString(),
    phoneCountryCode: `+${phone.countryCallingCode}`,
    type: "",
  };

  subscriptionTypeArray.forEach(subscriptionType => {
    if (data[subscriptionType] && subscriptionType !== "TICKET") {
      postContracts.push({
        ...baseContract,
        type: subscriptionType,
      });
    }
  });

  return postContracts;
};

