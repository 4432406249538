import { AgreementSettings } from "src/types/AuthCompany";
import { mapTicketRequestSearchResult } from "src/types/companyAgreement/ticketRequest";
import { mapProductTemplateArray } from "src/types/ProductTemplate";
import { mapZones } from "../../commonTypes/Zone";
import { addPaginationParameters, addQueryParameters } from "../../tools";
import { mapAvailableSubscriptionArray } from "../AvailableSubscription";
import CompanyTicketPostContract from "../createTicketPostContract";
import { mapPrices } from "../prices";
import { mapSubscriberSearchResult } from "../subscriber";
import { mapSubscription, mapSubscriptionSearchResult } from "../Subscription";
import { SubscriptionPostContract } from "../subscriptionPostContract";
import { mapHousingAssociationOrderSearchResult } from "./housingAssociationOrder";
import { HousingAssociationTicketRequestJson } from "./housingAssociationTicketRequest";
import { CreateHousingAssociationWidgetContract, mapHousingAssociationWidget } from "./housingAssociationWidget";
import { mapHousingUnitCostChangeArray } from "./housingUnitCostChange";
import { mapHousingCostArray } from "./housingUnitCosts";

const housingAssociationApi = {
  auth: {
    widget: {
      createWidget: (body: CreateHousingAssociationWidgetContract) => ({
        method: "post" as const,
        url: "/api/auth/widget/create",
        body,
        mapper: mapHousingAssociationWidget,
      }),
    },
  },
  ticket: {
    create: (body: CompanyTicketPostContract) => ({
      method: "post" as const,
      url: `/api/ticket/create`,
      body,
    }),
  },
  widget: {
    getHousingAssociationWidgetByCompanyId: (companyId: string) => ({
      method: "get" as const,
      url: `/api/widget/by-company-id/${companyId}`,
      mapper: mapHousingAssociationWidget,
    }),
  },
  settings: {
    post: (contract: AgreementSettings) => ({
      method: "post" as const,
      url: "/api/company-agreement/settings",
      body: contract,
    }),
  },
  ticketRequest: {
    getByCompanyId: (companyId: string) => {
      return {
        method: "get" as const,
        url: `/api/ticket-request/by-company-id/${companyId}`,
        mapper: mapTicketRequestSearchResult,
      };
    },
    reject: (ticketRequestId: string) => ({
      method: "delete" as const,
      url: `/api/ticket-request/${ticketRequestId}`,
    }),
    approve: (ticketRequest: HousingAssociationTicketRequestJson) => ({
      method: "post" as const,
      url: `/api/ticket-request/approve`,
      body: ticketRequest,
    }),
  },
  invoice: {
    getByAgreementAndDate: (agreementNumber: string, date: Date) => ({
      method: "get" as const,
      url: `/api/invoice/orders/${agreementNumber}/${encodeURIComponent(date.toISOString())}`,
      mapper: mapHousingCostArray,
    }),
    getChangesByAgreementNumberAndDate: (agreementNumber: string, oldDate: Date, newDate: Date) => ({
      method: "get" as const,
      url: `/api/invoice/changed-subscriptions?agreement=${agreementNumber}&oldDate=${encodeURIComponent(oldDate.toISOString())}&newDate=${encodeURIComponent(newDate.toISOString())}`,
      mapper: mapHousingUnitCostChangeArray,
    }),
  },
  zones: {
    getAll: () => ({
      method: "get" as const,
      url: "/api/zone/list",
      mapper: mapZones,
    }),
  },
  product: {
    list: {
      get: (productTemplateId: string) => {
        return {
          method: "get" as const,
          url: `/api/product/list/${productTemplateId}`,
          mapper: mapPrices,
        };
      },
    },
    getV2Products: () => ({
      method: "get" as const,
      url: "/api/product/list-v2",
      mapper: mapProductTemplateArray,
    }),
  },
  subscription: {
    getById: (id: string) => ({
      method: "get" as const,
      url: `/api/subscription/${id}`,
      mapper: mapSubscription,
    }),
    available: {
      geyByCompanyId: (companyId: string) => ({
        method: "get" as const,
        url: `/api/subscription/available/by-company-id/${companyId}`,
        mapper: mapAvailableSubscriptionArray,
      }),
    },
    create: (body: SubscriptionPostContract) => ({
      method: "post" as const,
      body,
      url: "/api/subscription/create",
    }),
    cancelPendingSubscription: (id: string) => ({
      method: "delete" as const,
      url: `/api/subscription/${id}`,
    }),
    cancelActiveSubscription: (id: string, expiryDate: Date) => ({
      method: "put" as const,
      url: `/api/subscription/${id}/expiry/${encodeURIComponent(expiryDate.toISOString())}`,
    }),
  },
  subscriber: {
    getBySubscriberId: (companyId: string, query: string, page: number, pageSize: number) => {
      return {
        method: "get" as const,
        url: `/api/subscriber/by-company-id/${companyId}?${addPaginationParameters(page, pageSize)}&${addQueryParameters(query)}`,
        mapper: mapSubscriberSearchResult,
      };
    },
    getSubscriptionsBySubscriberId: (id: string) => ({
      method: "get" as const,
      url: `/api/subscriber/${id}/subscriptions`,
      mapper: mapSubscriptionSearchResult
    }),
    getTicketsBySubcsriberId: (id: string) => ({
      method: "get" as const,
      url: `/api/subscriber/${id}/tickets`,
      mapper: mapHousingAssociationOrderSearchResult
    })
  }
};

export default housingAssociationApi;