import { apiClient, Button, ButtonGroup, DateFormInput, DropdownFormInput, FormGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { housingUnitModalLanguageResource } from "../lang-resource";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  ticket: Pick<HousingAssociationOrder | CompanyAgreementOrder, "id">
}

interface ChangeActiveTicketForm {
  expiryDate: string;
}

export interface InternalProps extends Props {
  canSetArbitraryExpiryDateForTicket: boolean
}

export const CancelActiveTicketModalInternal: React.FC<InternalProps> = ({
  onClose,
  onSuccess,
  ticket,
  canSetArbitraryExpiryDateForTicket }) => {

  const language = useLanguageResource(housingUnitModalLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const fromDates = getExpiryDates(6, formLang.today);
  const [modalState, setModalState] = useState<"IDLE" | "SUBMITTING" | "SUBMIT_ERROR">("IDLE")

  const formMethods = useForm<ChangeActiveTicketForm>({
    defaultValues: {
      expiryDate: fromDates[0].value,
    },
  });

  const expiryDate = formMethods.watch("expiryDate");

  const submit = async (data: ChangeActiveTicketForm) => {
    setModalState("SUBMITTING")

    const newExpiryDate = ensureExpiryDateIsNotInThePastAndFormatForBackend(data.expiryDate);
    const response = await apiClient.request(companyAgreementApi.ticket.cancelActiveTicket(ticket.id, newExpiryDate));

    if (response.type === "success") {
      onSuccess();
    } else {
      setModalState("SUBMIT_ERROR")
      sentry.captureException(response.error);
    }
  };

  const renderDatePicker = () => {

    if (canSetArbitraryExpiryDateForTicket) {
      return (
        <div data-test-id="free-date-picker">
          <DateFormInput minDate={new Date()} name="expiryDate" required label={language.from} />
        </div>
      );
    }

    return <div data-test-id="date-dropdown">
      <DropdownFormInput name="expiryDate" label={language.from} required>
        {fromDates.map(x => <option key={x.value} value={x.value}>{x.text}</option>)}
      </DropdownFormInput>
    </div>;
  };

  return (
    <Modal
      isOpen={true}
      title={language.cancelActiveTicket}
      handleClose={onClose}
      data-test-id="cancel-active-ticket-modal"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submit)}>
          {renderDatePicker()}
          <FormGroup>
            <Message skin="info" title={language.cancelActiveTicketInfoTitle} data-test-id="information-message" style={{ marginTop: "1rem" }}>
              <p style={{ marginBottom: "1rem" }}>
                {language.cancelActiveTicketInfoPrivacy(expiryDate)}
              </p>
            </Message>
          </FormGroup>
          {modalState === "SUBMIT_ERROR" && <FormGroup><Message skin="danger" data-test-id="change-zone-submit-error-message" title={formLang.submitError} /></FormGroup>}
          <ButtonGroup>
            <Button variant="primary" type="submit" text={formLang.yes} loading={modalState === "SUBMITTING"} />
            <Button variant="cancel" type="button" text={formLang.cancel} data-test-id="cancel-button" onClick={onClose} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );

}

const CancelActiveTicketModal: React.FC<Props> = (props) => {
  const authContext = useAuthContextAuthenticated();
  const { companyTicket } = authContext.features.services

  const canSetArbitraryExpiryDateForTicket = (companyTicket.hasService && companyTicket.canSetArbitraryExpiryDateForTicket)

  return <CancelActiveTicketModalInternal
    canSetArbitraryExpiryDateForTicket={canSetArbitraryExpiryDateForTicket}
    {...props}
  />

};

export default CancelActiveTicketModal;
