import { LanguageResource, languageResourceBuilder, TwoParamsLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

interface HousingAssociationTicketFormLanguageResource {
  subscription: string
  order: string
  adult: string
  retired: string
  child: string
  profile: string
  errorExistingOpenTicket: string
  gapToSmall: TwoParamsLanguageResourceTemplate;
  acceptPrivay: string
  readPolicies: string
  chooseAddOn: string
  ticket: string
}

export const housingAsssociationTicketFormLanguageResource: LanguageResource<HousingAssociationTicketFormLanguageResource> = {
  nb: {
    order: "Bestill",
    adult: "Voksen",
    child: "Barn",
    retired: "Honnør",
    profile: "Reisende",
    errorExistingOpenTicket: "Det eksisterer allerede en gyldig eller ventende billett på dette telefonnummeret.",
    gapToSmall:
      languageResourceBuilder.twoParamTemplate("Bestilling av ny billett ble avvist, fordi det finnes en billett på dette telefonnummeret med sluttdato $p1$. Det er ikke mulig å ha overlappende billetter, første mulige startdato er derfor $p2$."),
    acceptPrivay:
      "Jeg bekrefter at beboberen har fått informasjon om behandling av personopplysninger og godkjenner Ruters vilkår, " +
      "samt at jeg som administrator har fått tillatelse til å inngå avtale på hans / hennes vegne.",
    readPolicies: "Les Ruters vilkår",
    chooseAddOn: "Velg tilleggstjeneste",
    ticket: "Billett",
    subscription: "Abonnement"
  },
  en: {
    order: "Order",
    adult: "Adult",
    child: "Child",
    retired: "Retired",
    profile: "Profile",
    errorExistingOpenTicket: "There already exists a pending or active ticket for this phonenumber.",
    gapToSmall: languageResourceBuilder.twoParamTemplate("Could not order a new ticket. A ticket that has expired on $p1$ already exists, according to our rules, the first available start date for a new ticket is $p2$"),
    acceptPrivay:
      "I hereby confirm that the resident has accepted ruters terms and policies, and that I have been given consent by the employee to order a ticket on his/hers behalf",
    readPolicies: "Read Ruters policies",
    chooseAddOn: "Choose addon service",
    ticket: "Ticket",
    subscription: "Subscription"
  },
};
