import * as Sentry from "@sentry/react";
import { setDefaultOptions } from "date-fns";
import { nb } from "date-fns/locale";
import moment from "moment";
import localization from "moment/locale/nb";
import ApiClientError from "./common/apiClient/ApiClientError";

const setGlobalContext = () => {
  Sentry.init({
    dsn: "https://52a72412a14055e67cfcaa089867c3ba@o268620.ingest.us.sentry.io/4508155055964160",
    environment: window.envName,
    normalizeDepth: 10,
    beforeSend(event, hint) {
      const modifiedEvent = { ...event };
      if (hint?.originalException instanceof ApiClientError) {
        modifiedEvent.extra = {
          ...event.extra,
          ...hint.originalException.extra,
        };
        modifiedEvent.fingerprint = hint.originalException.fingerPrint;
      }
      if ((hint.originalException as any).fingerPrint) {
        modifiedEvent.fingerprint = [(hint.originalException as any).fingerPrint];
      }
  
      if ((hint.originalException as any).additionalData) {
        modifiedEvent.extra = {
          ...event.extra,
          ...(hint.originalException as any).additionalData,
        };
      }
      return modifiedEvent;
    },
  });
  
  setDefaultOptions({ locale: nb });
  moment.updateLocale("nb", localization);

}

export default setGlobalContext