import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface PrivacyTermsLanguageResource {
  readPolicies: string
}

export const privacyTermsLanguageResource: LanguageResource<PrivacyTermsLanguageResource> = {
  nb: {
    readPolicies: "Les vilkårene",
  },
  en: {
    readPolicies: "Read our terms and conditions",
  },
};
