import { formatter, Icon, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useState } from "react";
import { Zone } from "src/common/api/commonTypes/Zone";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { FormFieldsLanguageResource, formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { TICKET_ACTIVE } from "src/constants";
import TicketStatus from "../../text/ticketStatus/TicketStatus";
import { TicketZonesComponent } from "../../text/ticketZones/TicketZones";
import { formatDate, formatText } from "../formatter";
import InfoCard, { InfoCardRowType } from "../infoCard/InfoCard";
import { InfoCardButtonType } from "../infoCard/InfoCardButton";
import { ticketCardLanguageResource } from "./lang-resource";
import CancelActiveTicketModal from "./modals/CancelActiveTicketModal/CancelActiveTicketModal";
import CancelPendingTicketModal from "./modals/CancelPendingTicketModal";
import ChangeZoneModal from "./modals/ChangeZoneModal";
import ResendPickupConfirmationModal from "./modals/ResendPickupConfirmationModal";
import ResendTicketConfirmationModal from "./modals/ResendTicketConfirmationModal";

const formatPicketUp = (pickedUp: boolean, language: FormFieldsLanguageResource) => {
  if (pickedUp) {
    return language.yes;
  }
  return language.no;
};

const calculatePrice = (ticket: HousingAssociationOrder | CompanyAgreementOrder) => {
  if (ticket.status !== TICKET_ACTIVE) {
    return "–";
  }

  const price = ticket.pricePerMonth;

  return formatter.number.currency(price, false);
};


type ModalType = "CHANG_ZONE" | "CANCEL_ACTIVE_TICKET" | "CANCEL_PENDING_TICKET" | "RESEND_PICKUP_CODE" | "RESEND_TICKET"

const getModal = (
  ticket: HousingAssociationOrder | CompanyAgreementOrder,
  type: ModalType,
  onUpdateRequest: () => void,
  onClose: () => void,
  zones: Zone[]) => {
  switch (type) {
    case "CANCEL_ACTIVE_TICKET":
      return <CancelActiveTicketModal ticket={ticket} onSuccess={onUpdateRequest} onClose={onClose} />
    case "CANCEL_PENDING_TICKET":
      return <CancelPendingTicketModal ticket={ticket} onSuccess={onUpdateRequest} onClose={onClose} />
    case "CHANG_ZONE":
      return <ChangeZoneModal zones={zones} ticket={ticket} onSuccess={onUpdateRequest} onClose={onClose} />
    case "RESEND_PICKUP_CODE":
      return <ResendPickupConfirmationModal ticket={ticket} onSuccess={onUpdateRequest} onClose={onClose} />
    case "RESEND_TICKET":
      return <ResendTicketConfirmationModal ticket={ticket} onSuccess={onUpdateRequest} onClose={onClose} />
  }
}

interface Props {
  ticket: HousingAssociationOrder | CompanyAgreementOrder;
  onUpdateRequest: () => void;
  zones: Zone[];
  title: string
}

const TicketCard: React.FC<Props> = ({ ticket, onUpdateRequest, zones, title }) => {
  const language = useLanguageResource(ticketCardLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [modalType, setModalType] = useState<ModalType>()

  const ticketHasPickupCode = Boolean(ticket.pickupCode);
  const ticketHasExpirationDate = Boolean(ticket.expirationDate);

  const ticketIsActive = ticket.status === "active";
  const ticketIsPending = ticket.status === "pending";
  const ticketIsActiveOrPending = ticketIsActive || ticketIsPending;

  const disableChangeZoneButton = !ticketIsActive || ticketHasExpirationDate;
  const disableCancelActiveTicketButton = !ticketIsActive || ticketHasExpirationDate;
  const disableSendPickupCode = !ticketIsActiveOrPending || !ticketHasPickupCode || ticket.downloadedToPhone;

  const showResendTicketButton = ticketIsActiveOrPending && !ticket.expirationDate && ticket.downloadedToPhone;

  const infoRows: InfoCardRowType[] = [
    { label: language.status, value: <TicketStatus status={ticket.status} />, "data-test-id": "ticket-status-value" },
    { label: language.orderId, value: ticket.id, "data-test-id": "ticket-id-value" },
    { label: formLang.phone, value: ticket.phone, "data-test-id": "ticket-phone-value" },
    { label: language.validFrom, value: formatter.date.toShortDateString(ticket.startDate), "data-test-id": "ticket-start-date-value" },
    { label: language.validTo, value: formatDate(ticket.expirationDate), "data-test-id": "ticket-expiration-date-value" },
    { label: language.pickedUp, value: formatPicketUp(ticket.downloadedToPhone, formLang), "data-test-id": "ticket-downloaded-to-phone-value" },
    { label: language.pickupCode, value: formatText(ticket.pickupCode), "data-test-id": "ticket-pickup-code-value" },
    { label: language.appId, value: formatText(ticket.appId), "data-test-id": "ticket-app-id-value" },
    { label: language.profile, value: formatText(ticket.profileName), "data-test-id": "ticket-profile-value"},
    { label: language.pricePerMonth, value: calculatePrice(ticket), "data-test-id": "ticket-price-value" },
    { label: language.zones, value: <TicketZonesComponent nrOfZones={ticket.nrOfZones} zones={ticket.zones} />, "data-test-id": "ticket-zone-value" },
    { label: language.invoiceReference, value: ticket.invoiceReference, "data-test-id": "ticket-invoice-ref-value" },
  ];

  const buttons: InfoCardButtonType[] = [
    {
      onClick: () => { setModalType("CHANG_ZONE"); },
      text: language.changeZone, "data-test-id": "ticket-change-zone-button",
      disabled: disableChangeZoneButton,
      icon: <Icon variant="ArrowsSwapIcon" size="small" />,
    },
    showResendTicketButton ?
      {
        onClick: () => { setModalType("RESEND_TICKET") },
        text: language.resendTicket,
        "data-test-id": "resend-ticket-button",
        icon: <Icon variant="CellPhoneArrowRightIcon" size="small" />,
      } :
      {
        onClick: () => { setModalType("RESEND_PICKUP_CODE") },
        text: language.sendPickupCode,
        "data-test-id": "send-pickup-code-button",
        disabled: disableSendPickupCode,
        icon: <Icon variant="CellPhoneArrowRightIcon" size="small" />,
      },
    ticketIsPending ?
      {
        onClick: () => { setModalType("CANCEL_PENDING_TICKET") },
        text: language.cancelPendingTicket,
        "data-test-id": "cancel-pending-ticket-button",
        icon: <Icon variant="CrossIcon" size="small" />,
      } :
      {
        onClick: () => { setModalType("CANCEL_ACTIVE_TICKET") },
        text: language.cancelActiveTicket,
        "data-test-id": "cancel-active-ticket-button",
        disabled: disableCancelActiveTicketButton,
        icon: <Icon variant="CrossIcon" size="small" />,
      },
  ];

  return (
    <>
      <InfoCard id={ticket.id} label={title} infoRows={infoRows} buttons={buttons} status={ticket.status} data-test-id="ticket-card" />
      {modalType && getModal(ticket, modalType, onUpdateRequest, () => { setModalType(undefined) }, zones)}
    </>
  );
};

export default TicketCard;
