import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { useAuthContext } from "src/AuthContext";
import CallToActionLink from "../common/buttons/CallToActionLink";
import { toolsLanguageResource } from "./lang-resource";
import "./Tools.scss";

const Tools: React.FC = () => {
  const authContext = useAuthContext();

  const showTools = authContext.features.showTools

  const hasAccessToWidget = (showTools === 'only-widget' || showTools === 'widget-and-real-time') && authContext.authenticated &&
    (authContext.features.services.companyTicket.hasService || authContext.features.services.housingAssociation.hasService);

  const housingAssociationWidgetIsDisabled = authContext.authenticated
    && authContext.features.services.housingAssociation.hasService
    && authContext.features.services.housingAssociation.widgetSettings.widgetAccess === "DISABLED";

  const companyTicketWidgetIsDisabled = authContext.authenticated
    && authContext.features.services.companyTicket.hasService
    && authContext.features.services.companyTicket.widgetSettings.widgetAccess === "DISABLED";

  const widgetAccessIsDisabled = housingAssociationWidgetIsDisabled || companyTicketWidgetIsDisabled;

  const language = useLanguageResource(toolsLanguageResource);

  return (
    <Container width="s" className="components-tools-tools" data-test-id="tools-page">
      <h1>{language.title}</h1>

      {(showTools === 'only-real-time' || showTools === 'widget-and-real-time') && (
        <div data-test-id="real-time-screen">
          <h2>{language.realTimeTitle}</h2>
          <p>{language.realTimeIngress}</p>
          <CallToActionLink text={language.realTimeLink} externalUrl="https://ruter.no/om-ruter/utviklerverktoy/egen-sanntidsskjerm/" showIcon={true} />
        </div>
      )}

      {hasAccessToWidget && (
        <>
          <div data-test-id="company-tickets-widget">
            <h2>{language.companyTicketsWidgetTitle}</h2>
            <p>{language.companyTicketsWidgetIngress}</p>
            {widgetAccessIsDisabled && <p className="warning">{language.widgetAccessIsDisabled}</p>}
            <CallToActionLink path="/verktoy/widget" text={language.companyTicketsWidgetLink} showIcon={true} />
          </div>

          <div data-test-id="company-tickets-link">
            <h2>{language.companyTicketsLinkTitle}</h2>
            <p>{language.companyTicketsLinkIngress}</p>
            {widgetAccessIsDisabled && <p className="warning">{language.widgetAccessIsDisabled}</p>}
            <CallToActionLink path="/verktoy/link" text={language.companyTicketsLinkLink} showIcon={true} />
          </div>
        </>
      )}
    </Container>
  );
};

export default Tools;
