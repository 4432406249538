import { Tenant as BillettLukeTenant } from "@ruter-as/billettluke-frontend";
import { hasValidAgreement } from "src/common/agreements";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { AuthCompany } from "src/types/AuthCompany";
import { Address } from "src/types/Company";
import { Contact } from "src/types/Contact";
import { getBlacklistedProducts } from "src/types/ticketCounter/ticketCounterBlacklist";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRoleType } from "src/types/user/UserRoleType";
import { EnvName } from "../EnvName";
import { Tenant } from "../Tenant";
import { getExternalContactUsUrl } from "../Urls";
import { HasNoService } from "./Services";
export interface HasTicketCounterService {
  hasService: true,
  isValid: boolean,
  agreementNumber: string
  allowedRefund: boolean,
  allowedTicketCancellation: boolean,
  allowedTravelMoneyCancellation: boolean,
  allowedOrderTicket: boolean
  allowedSettings: boolean
  allowedPaymentInAppEligible: boolean
  blackListedProducts: string[]
  ticketCounterTenant: BillettLukeTenant
  errorLink: string
  hasMailDelivery: boolean
  visitAddress?: Address;
}

export type TicketCounterService = HasTicketCounterService | HasNoService;

const ticketCounterTenant = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return BillettLukeTenant.Ruter;
    case Tenant.Brakar:
      return BillettLukeTenant.Brakar;
    case Tenant.Akt:
      return BillettLukeTenant.AKT;
    default:  {
      const exhaustiveCheck: never = tenant;
      throw new Error(`Unhandled switch case: ${exhaustiveCheck}`);
    }
      
  }
};

export const calculateTicketCounterService = (selectedCompany: AuthCompany, contact: Contact, tenant: Tenant, env: EnvName): TicketCounterService => {

  const agreement = selectedCompany.allAgreements.find(x => x.subTypeId === AgreementSubTypeId.TICKETOFFICE);

  if (!agreement) {
    return { hasService: false };
  }
  const hasSingleEligibleCompanyRole = selectedCompany.roles.some(role =>
    role === CompanyRoleType.TicketCounterAdmin
    || role === CompanyRoleType.FreeTicketOrCompanyTicketAdmin
    || role === CompanyRoleType.UserAdmin);

  const hasGlobalCompanyAdminRole = contact.roles.some(role => role === UserRoleType.GlobalCompanyAdmin);
  const hasTicketCounterAdminRole = selectedCompany.roles.some(role => role === CompanyRoleType.TicketCounterAdmin);

  if (!hasSingleEligibleCompanyRole && !hasGlobalCompanyAdminRole) {
    return { hasService: false };
  }

  return {
    hasService: true,
    agreementNumber: agreement.id,
    isValid: hasValidAgreement([agreement]),
    allowedTicketCancellation: selectedCompany.roles.some(role => role === CompanyRoleType.TicketCounterAdmin),
    allowedTravelMoneyCancellation: selectedCompany.roles.some(role => role === CompanyRoleType.TicketCounterAdmin) && tenant === Tenant.Akt,
    allowedRefund: contact.roles.some(role => role === UserRoleType.GlobalCompanyAdmin),
    allowedOrderTicket: hasValidAgreement([agreement]) && (hasTicketCounterAdminRole || hasGlobalCompanyAdminRole),
    allowedSettings: hasValidAgreement([agreement]) && selectedCompany.roles.some(role => role === CompanyRoleType.FreeTicketOrCompanyTicketAdmin || role === CompanyRoleType.UserAdmin),
    allowedPaymentInAppEligible: env !== "prod" && hasValidAgreement([agreement]) && selectedCompany.roles.some(role => role === CompanyRoleType.FreeTicketOrCompanyTicketAdmin || role === CompanyRoleType.UserAdmin),
    blackListedProducts: getBlacklistedProducts(tenant),
    ticketCounterTenant: ticketCounterTenant(tenant),
    visitAddress: tenant === Tenant.Ruter ? selectedCompany.addresses.visit : undefined,
    hasMailDelivery: tenant === Tenant.Ruter,
    errorLink: tenant === Tenant.Ruter ? "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/" : getExternalContactUsUrl(tenant),
  };
};

export const getValidTicketCounterService = (service: Partial<TicketCounterService> = {}): TicketCounterService => ({
  hasService: true,
  agreementNumber: "",
  allowedTicketCancellation: false,
  allowedTravelMoneyCancellation: false,
  allowedOrderTicket: false,
  allowedPaymentInAppEligible: false,
  allowedRefund: false,
  allowedSettings: false,
  isValid: false, blackListedProducts: [],
  errorLink: "",
  hasMailDelivery: false,
  ticketCounterTenant: BillettLukeTenant.Ruter,
  visitAddress: {
    city: "",
    country: "",
    line1: "",
    line2: "",
    postCode: "",
  },
  ...service,
});
