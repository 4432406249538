import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import React from "react";

interface Props {
  phoneCountryCode?: string | null;
  phone?: string | null;
  "data-label"?: string;
}

export const formatPhonenumber = (phoneCountryCode?: string | null, phone?: string | null): string => {
  let text = `${phoneCountryCode || ""} ${phone || ""}`;

  if (phoneCountryCode && phone) {
    const parsedNumber = parsePhoneNumberFromString(phoneCountryCode + phone);

    if (parsedNumber && parsedNumber.isValid()) {
      text = parsedNumber.formatInternational();
    }
  }
  return text;
};

const PhoneColumn: React.FC<Props> = (props) => {
  const { phoneCountryCode, phone } = props;
  const dataLabel = props["data-label"];  
  const text = formatPhonenumber(phoneCountryCode, phone);
  return <td data-label={dataLabel}>{text}</td>;
};

export default PhoneColumn;
