import { apiClient, Button, ButtonGroup, DropdownFormInput, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { getProductPrice } from "src/common/getProductPrice";
import ProductPrice from "src/components/common/form/ProductPrice";
import { mapChangeZonePostContract } from "src/types/backendContracts/companyAgreementApi/ChangeZonePostContract";
import { ProductTemplate } from "src/types/ProductTemplate";
import { housingUnitModalLanguageResource } from "./lang-resource";


interface Props {
  ticket: HousingAssociationOrder;
  onClose: () => void;
  onSuccess: () => void;
  zones: Zone[];
}

interface ChangeZoneForm {
  expiryDate: string;
  zoneFrom: string;
  zoneTo: string;
}

const ChangeZoneModal: React.FC<Props> = ({ ticket, onSuccess, onClose, zones }) => {
  const authContext = useAuthContextAuthenticated();
  const language = useLanguageResource(housingUnitModalLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const fromDates = getExpiryDates(6, formLang.today);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [noChangeError, setNoChangeError] = useState(false);
  const [productTemplate, setProductTemplate] = useState<ProductTemplate>();

  useEffect(() => {
    const fetchData = async () => {
      const productTemplateResponse = await apiClient.request(companyAgreementApi.product.getV2Products());

      if (productTemplateResponse.type !== "success") {
        setProductTemplate(() => {
          throw productTemplateResponse.error;
        });
        return;
      }

      setProductTemplate(productTemplateResponse.result.find(x => x.tags.includes("bigcustomer:subscriptionTicket")));
    };

    fetchData();
  }, []);

  const formMethods = useForm<ChangeZoneForm>({
    defaultValues: {
      expiryDate: fromDates[0].value,
      zoneFrom: ticket.zoneFrom,
      zoneTo: ticket.zoneTo,
    },
  });

  const zoneFrom = formMethods.watch("zoneFrom");
  const zoneTo = formMethods.watch("zoneTo");

  useEffect(() => {
    setNoChangeError(false);
  }, [zoneFrom, zoneTo]);

  const options = fromDates.map((o) => ({
    value: o.value,
    text: o.text,
  }));

  const onSubmit = async (values: ChangeZoneForm) => {
    setSubmitError(false);

    if (values.zoneFrom === ticket.zoneFrom && values.zoneTo === ticket.zoneTo) {
      setNoChangeError(true);
      return;
    }
    setSubmitting(true);

    const contract = mapChangeZonePostContract(values.zoneFrom, values.zoneTo, values.expiryDate, zones);

    const response = await apiClient.request(companyAgreementApi.ticket.changeZone(ticket.id, contract));
    if (response.type === "success") {
      onSuccess();
    } else {
      setSubmitError(true);
      setSubmitting(false);
      sentry.captureException(response.error);
    }
  };

  const price = productTemplate && getProductPrice(productTemplate.products, zoneFrom, zoneTo, zones, ticket.profileId);

  return (
    <Modal
      isOpen={true}
      title={language.changeZone}
      handleClose={onClose}
      data-test-id="change-zone-modal"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {authContext.features.getZoneInput(zones)}
          <DropdownFormInput name="expiryDate" label={language.from}>
            {options.map((x) => <option key={x.value} value={x.value}>{x.text}</option>)}
          </DropdownFormInput>
          <ProductPrice gray price={price}/>
          {submitError && <Message skin="danger" data-test-id="change-zone-submit-error-message" title={formLang.submitError} />}
          {noChangeError && <Message skin="warning" data-test-id="no-changes" title={language.noChangeToZoneValidationError} />}
          <ButtonGroup>
            <Button variant="primary" type="submit" text={formLang.save} loading={submitting} />
            <Button variant="cancel" type="button" text={formLang.cancel} onClick={onClose} data-test-id="cancel-button" />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ChangeZoneModal;
