import { apiClient, ButtonGroup, CheckBoxForminput, CheckboxList, DateFormInput, DropdownFormInput, FormGroup, Modal, parser, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import { AvailableSubscription } from "src/common/api/companyAgreementApi/AvailableSubscription";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { CreateCompanyTicketErrorType, CreateSubscriptionFormData } from "src/common/api/companyAgreementApi/createServiceFormData";
import CompanyTicketPostContract from "src/common/api/companyAgreementApi/createTicketPostContract";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { Subscription } from "src/common/api/companyAgreementApi/Subscription";
import { mapSubscriptionPostContract } from "src/common/api/companyAgreementApi/subscriptionPostContract";
import { getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { getAgreementText } from "src/common/getAgreementText";
import { getProductPrice } from "src/common/getProductPrice";
import { getSubscriptionPrice } from "src/common/getSubscriptionPrice";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import ProductPrice from "src/components/common/form/ProductPrice";
import { getLocaleText } from "src/types/localeText";
import { ProductTemplate } from "src/types/ProductTemplate";
import { SubscriptionType, subscriptionTypeArray } from "src/types/subscriptionType";
import CreateCompanyTicketError from "../common/createCompanyTicketError/CreateCompanyTicketError";
import PrivacyTerms from "../common/privacyTerms/PrivacyTerms";
import { submitCreateSubscription } from "../common/submitCreateSubscription";
import { submitCreateTicket } from "../common/submitCreateTicket";
import employeeTicketsLanguageResource from "./lang-resource";


interface FormData extends Record<SubscriptionType, boolean> {
  agreementNumber: string;
  zoneFrom: string;
  zoneTo: string;
  startDate: string;
  profile: "ADULT"
  TICKET: boolean
}

interface Props {
  onClose: () => void
  onSuccess: () => void
  ticket: CompanyAgreementOrder | undefined
  subscription: Subscription | undefined
  zones: Zone[]
  availableSubscriptions: AvailableSubscription[]
}


const NewSubscriptionModal: React.FC<Props> = ({
  onClose,
  onSuccess,
  ticket,
  subscription,
  zones,
  availableSubscriptions,
}) => {
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const lang = useLanguageResource(employeeTicketsLanguageResource);
  const { selectedLanguage } = useLanguageContext();
  const [productTemplate, setProductTemplate] = useState<ProductTemplate>();
  const [loading, setLoading] = useState(false);
  const [backendError, setBackendError] = useState<CreateCompanyTicketErrorType>();

  const dates = companyTicketService.hasIncreasedExpiryDate ? getExpiryDates(32, formLang.today) : getExpiryDates(6, formLang.today);

  const ticketOrSub = ticket || subscription;

  if (!ticketOrSub) throw new Error("Both ticket and subscription was undefined")

  const formMethods = useForm<FormData>({
    defaultValues: {
      agreementNumber: ticketOrSub.type === "TICKET" ? ticketOrSub.agreementNumber : ticketOrSub.agreementId,
      startDate: dates[0].value,
      profile: "ADULT"
    },
  });

  useEffect(() => {
    const fetch = async () => {
      const [productTemplateResponse] = await Promise.all([
        apiClient.request(companyAgreementApi.product.getV2Products()),
      ]);

      if (productTemplateResponse.type !== "success") {
        setProductTemplate(() => {
          throw productTemplateResponse.error;
        });
        return;
      }

      if (availableSubscriptions.length === 1) {
        const { type } = availableSubscriptions[0];
        formMethods.setValue(type, true);
      }

      setProductTemplate(productTemplateResponse.result.find(x => x.tags.includes("bigcustomer:subscriptionTicket")));
    };
    fetch();
  }, [authContext.userData.selectedCompany.id]);

  const mapCreateTicketPostContract = (formData: FormData): CompanyTicketPostContract => {
    const { phone, phoneCountryCode } = ticketOrSub;

    let zoneFromName = formData.zoneFrom;
    let zoneToName = formData.zoneTo;

    if (companyTicketService.singleZoneHasAdditionalZone) {
      if (zoneToName === zoneFromName) {
        if (zoneFromName === "Kristiansand" || zoneFromName === "Vennesla") {
          zoneFromName = "Kristiansand";
          zoneToName = "Vennesla";
        }
      }
    }

    const backendContract: CompanyTicketPostContract = {
      agreementName: formData.agreementNumber,
      employeeId: ticketOrSub.employeeId,
      firstName: ticketOrSub.firstName,
      lastName: ticketOrSub.lastName,
      phone: phone,
      phoneCountryCode: phoneCountryCode,
      startDate: parser.date.fromShortDateString(formData.startDate).toISOString(),
      zoneFrom: zoneFromName,
      zoneTo: zoneToName,
      addOns: [],
    };

    return backendContract;
  };

  const dateOptions = dates.map((o) => ({
    value: o.value,
    text: o.text,
  }));

  const renderDatePicker = () => {
    if (!ticketSelected) {
      return null;
    }

    if (companyTicketService.canSetArbitraryStartupDateForTicket) {
      return (
        <div data-test-id="free-date-picker">
          <DateFormInput minDate={new Date()} name="startDate" required label={lang.startDate} />
        </div>
      );
    }

    return <div data-test-id="constrained-date-picker">
      <DropdownFormInput data-test-id="constrained-date-picker" name="startDate" required label={lang.startDate}>
        {dateOptions.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
      </DropdownFormInput>
    </div>;
  };

  const { zoneFrom, zoneTo, TICKET: ticketSelected } = formMethods.watch()

  const selectedSubscriptions = subscriptionTypeArray.filter(subType => subType !== "TICKET").filter(subType => formMethods.watch(subType));
  const subscriptionIsSelected = selectedSubscriptions.length > 0;

  if (!zones) {
    return null;
  }

  const onSubmit = async (formData: FormData) => {
    setLoading(true);
    if (ticketSelected) {
      const contract = mapCreateTicketPostContract(formData);
      const ticketResponse = await submitCreateTicket(contract)

      if (ticketResponse.type === 'QUARANTEEN_ERROR' || ticketResponse.type === 'EXISTING_OPEN_TICKET_ERROR') {
        setBackendError(ticketResponse)
        return;
      } else if (ticketResponse.type === 'unhandledError') {
        setLoading(() => { throw ticketResponse.error });
        return;
      }
    }
    if (subscriptionIsSelected) {
      const subscriptionData: CreateSubscriptionFormData = {
        ...formData,
        employeeNumber: ticketOrSub.employeeId,
        firstName: ticketOrSub.firstName,
        lastName: ticketOrSub.lastName,
        phone: `${ticketOrSub.phoneCountryCode} ${ticketOrSub.phone}`,
        profile: "ADULT"
      }
      const contract = mapSubscriptionPostContract(subscriptionData);
      const subscriptionResponses = await submitCreateSubscription(contract)
      const error = subscriptionResponses.find(reponse => reponse !== true)
      if (error) {
        setLoading(() => { throw error })
        return
      }
    }
    onSuccess();
  };

  const getPrice = () => {
    let price = 0;

    if (ticketSelected) {
      price = productTemplate && getProductPrice(productTemplate.products, zoneFrom, zoneTo, zones, "ADULT") || 0;
    }
    if (subscriptionIsSelected) {
      price = price + getSubscriptionPrice(availableSubscriptions, selectedSubscriptions);
    }

    return price;
  };

  const serviceOptions = availableSubscriptions.map(subscription => ({
    name: subscription.type,
    label: getLocaleText(subscription.name, selectedLanguage),
    description: getLocaleText(subscription.description, selectedLanguage)
  }))

  const showSubscriptions = availableSubscriptions.length > 1;
  const hasSingleAvailableSubscription = availableSubscriptions.length === 1
  const formTitle = lang.modalTitle + " " + (hasSingleAvailableSubscription ? getLocaleText(availableSubscriptions[0].name, selectedLanguage) : lang.subscription).toLowerCase();
  const showForm = ticketSelected || subscriptionIsSelected;

  return (
    <Modal handleClose={() => { onClose() }} isOpen={true} title={formTitle} data-test-id="new-service-modal" >
      {hasSingleAvailableSubscription && <p data-test-id="subscription-description">{getLocaleText(availableSubscriptions[0].description, selectedLanguage)}</p>}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{marginTop: "2rem"}}>
          {showSubscriptions &&
            <FormGroup style={{ marginTop: "1rem" }}>
              <CheckboxList label={formLang.chooseSubscription} options={serviceOptions} />
            </FormGroup>}
          {showForm &&
            <>
              <DropdownFormInput name="agreementNumber" label={formLang.invoiceReference} required>
                {companyTicketService.invoiceRefs.map((option) => (
                  <option value={option.agreementNumber} key={option.agreementNumber}>{getAgreementText(option.invoiceReference, option.agreementNumber)}</option>
                ))}
              </DropdownFormInput>
              <FormGroup>{renderDatePicker()}</FormGroup>
              {ticketSelected && authContext.features.getZoneInput(zones)}
              <ProductPrice price={getPrice()} />
              {ticketSelected && <>
                <CheckBoxForminput name="acceptPrivacy" label={lang.acceptPrivacy} required />
                <PrivacyTerms terms={authContext.features.urls.terms} />
              </>
              }
              <ButtonGroup>
                <SubmitButton submitting={loading} text={formLang.order} />
                <CancelButton onClick={() => { onClose() }} />
              </ButtonGroup>
            </>
          }
          {backendError && <CreateCompanyTicketError error={backendError} />}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default NewSubscriptionModal;
