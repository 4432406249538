import { apiClient, ButtonGroup, CheckBoxForminput, CheckboxList, Container, DateFormInput, DropdownFormInput, FormGroup, PhoneFormInput, RadioButtonFormInput, TextFormInput, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import { AvailableSubscription, mapAvailableSubscriptionToOption } from "src/common/api/companyAgreementApi/AvailableSubscription";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { CreateCompanyTicketErrorType, CreateSubscriptionFormData } from "src/common/api/companyAgreementApi/createServiceFormData";
import { mapCreateTicketPostContract } from "src/common/api/companyAgreementApi/createTicketPostContract";
import { mapSubscriptionPostContract } from "src/common/api/companyAgreementApi/subscriptionPostContract";
import { getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { getAgreementText } from "src/common/getAgreementText";
import { getProductPrice } from "src/common/getProductPrice";
import { getSubscriptionPrice } from "src/common/getSubscriptionPrice";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import ProductPrice from "src/components/common/form/ProductPrice";
import { getLocaleText } from "src/types/localeText";
import { ProductTemplate } from "src/types/ProductTemplate";
import { ProfileId } from "src/types/ProfileId";
import { subscriptionTypeArray } from "src/types/subscriptionType";
import CreateCompanyTicketError from "../common/createCompanyTicketError/CreateCompanyTicketError";
import PrivacyTerms from "../common/privacyTerms/PrivacyTerms";
import { submitCreateSubscription } from "../common/submitCreateSubscription";
import { submitCreateTicket } from "../common/submitCreateTicket";
import "./CreateSubscriptionForm.scss";
import { createSubscriptionLanguageResource } from "./lang-resource";

const CreateSubscriptionForm: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const { selectedLanguage } = useLanguageContext();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const navigate = useNavigate();
  const lang = useLanguageResource(createSubscriptionLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const [zones, setZones] = useState<Zone[]>();
  const [productTemplate, setProductTemplate] = useState<ProductTemplate>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<CreateCompanyTicketErrorType>();
  const [availableSubscriptions, setAvailableSubscriptions] = useState<AvailableSubscription[]>([]);


  const dates = companyTicketService.hasIncreasedExpiryDate ? getExpiryDates(32, formLang.today) : getExpiryDates(6, formLang.today);

  const formMethods = useForm<CreateSubscriptionFormData>({
    defaultValues: {
      employeeNumber: "",
      firstName: "",
      lastName: "",
      agreementNumber: companyTicketService.invoiceRefs[0].agreementNumber || "",
      phone: "",
      startDate: dates[0].value,
      zoneFrom: authContext.features.defaultZones.hasDefaultZone ? authContext.features.defaultZones.fromZone : "",
      zoneTo: authContext.features.defaultZones.hasDefaultZone ? authContext.features.defaultZones.fromZone : "",
      profile: "ADULT",
      TICKET: false,
      JOULE: false,
      OTTO: false,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const [zoneResponse, productTemplateResponse, subscriptionResponse] = await Promise.all([
        apiClient.request(companyAgreementApi.zones.getAll()),
        apiClient.request(companyAgreementApi.product.getV2Products()),
        apiClient.request(companyAgreementApi.subscription.available.geyByCompanyId(authContext.userData.selectedCompany.id)),
      ]);

      if (zoneResponse.type !== "success") {
        setZones(() => {
          throw zoneResponse.error;
        });
        return;
      }

      if (productTemplateResponse.type !== "success") {
        setProductTemplate(() => {
          throw productTemplateResponse.error;
        });
        return;
      }

      if (subscriptionResponse.type !== "success") {
        setAvailableSubscriptions(() => {
          throw subscriptionResponse.error;
        });
        return;
      }

      if (subscriptionResponse.result.length === 0) {
        setAvailableSubscriptions(() => {
          throw new Error(`Has no known available subscriptions: ${subscriptionResponse.result.toString()}`)
        });
        return;
      }

      if (subscriptionResponse.result.length === 1) {
        const { type } = subscriptionResponse.result[0];
        formMethods.setValue(type, true);
      }

      setAvailableSubscriptions(subscriptionResponse.result);
      setZones(zoneResponse.result);
      setProductTemplate(productTemplateResponse.result.find(x => x.tags.includes("bigcustomer:subscriptionTicket")));
    };

    fetchData();
  }, [authContext.userData.selectedCompany.id]);


  const { zoneFrom, zoneTo, profile, TICKET: ticketSelected } = formMethods.watch()

  const selectedSubscriptions = subscriptionTypeArray.filter(type => type !== "TICKET").filter(subType => formMethods.watch(subType));
  const subscriptionIsSelected = selectedSubscriptions.length > 0;

  if (!zones) {
    return null;
  }

  const getPrice = () => {
    let price = 0;

    if (ticketSelected) {
      price = productTemplate && getProductPrice(productTemplate.products, zoneFrom, zoneTo, zones, profile) || 0;
    }
    if (subscriptionIsSelected) {
      price = price + getSubscriptionPrice(availableSubscriptions, selectedSubscriptions);
    }

    return price;
  };

  const onSubmit = async (data: CreateSubscriptionFormData) => {
    setSubmitting(true);

    if (data.TICKET) {
      const backendContract = mapCreateTicketPostContract(data, companyTicketService.singleZoneHasAdditionalZone, companyTicketService.hasMultipleProfiles);
      const ticketResponse = await submitCreateTicket(backendContract)

      if (ticketResponse.type === 'QUARANTEEN_ERROR' || ticketResponse.type === 'EXISTING_OPEN_TICKET_ERROR') {
        setBackendError(ticketResponse)
        return;
      } else if (ticketResponse.type === 'unhandledError') {
        setSubmitting(() => { throw ticketResponse.error });
        return;
      }
    }

    if (subscriptionTypeArray.some(sub => sub !== "TICKET" && data[sub])) {
      const backendSubscriptionContract = mapSubscriptionPostContract(data);
      const subscriptionResponses = await submitCreateSubscription(backendSubscriptionContract)

      const error = subscriptionResponses.find(response => response !== true)

      if (error) {
        setSubmitting(() => { throw error })
        return
      }
    }

    navigate("/bedriftsavtale");
  };

  const options = dates.map((o) => ({
    value: o.value,
    text: o.text,
  }));

  const getProfileOptions = () => {
    const profileOptions: { text: string, value: ProfileId }[] = [];

    productTemplate?.products.forEach((product) => {
      const optionExists = profileOptions.some(x => x.value === product.profileId)
      if (!optionExists) {
        profileOptions.push({ text: getLocaleText(product.profileName, selectedLanguage), value: product.profileId })
      }
    })

    return profileOptions;
  };


  const renderDatePicker = () => { 
    if (!ticketSelected) {
      return null;
    }

    if (companyTicketService.canSetArbitraryStartupDateForTicket) {
      return (
        <div data-test-id="free-date-picker">
          <DateFormInput minDate={new Date()} name="startDate" required label={ lang.startDate} />
        </div>
      );
    }

    return <div data-test-id="constrained-date-picker">
      <DropdownFormInput data-test-id="constrained-date-picker" name="startDate" required label={lang.startDate}>
        {options.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
      </DropdownFormInput>
    </div>;
  };

  const showForm = ticketSelected || subscriptionIsSelected;

  const showSubscriptions = availableSubscriptions.length > 1;
  const serviceOptions = availableSubscriptions.map(sub => mapAvailableSubscriptionToOption(sub, selectedLanguage))
  const hasSingleAvailableSubscription = availableSubscriptions.length === 1
  const formTitle = lang.title + " " + (hasSingleAvailableSubscription ? getLocaleText(availableSubscriptions[0].name, selectedLanguage) : lang.subscription).toLowerCase();

  return (
    <Container width="xs" data-test-id="create-ticket-form-page" className="create-ticket-form-page">
      <h1>{formTitle}</h1>
      {hasSingleAvailableSubscription && <p data-test-id="subscription-description">{getLocaleText(availableSubscriptions[0].description, selectedLanguage)}</p>}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {showSubscriptions && (
            <FormGroup>
              <CheckboxList label={formLang.chooseSubscription} options={serviceOptions} />
            </FormGroup>
          )}
          {showForm &&
            <>
              <PhoneFormInput name="phone" required label={formLang.phone} mobileOnly />
              <TextFormInput name="firstName" required minLength={2} maxLength={50} label={formLang.firstName} />
              <TextFormInput name="lastName" required minLength={2} maxLength={50} label={formLang.lastName} />
              <TextFormInput name="employeeNumber" required minLength={2} maxLength={20} label={ticketOwnerFieldName} />
              <DropdownFormInput name="agreementNumber" label={formLang.invoiceReference} required>
                {companyTicketService.invoiceRefs.map((option) => (
                  <option value={option.agreementNumber} key={option.agreementNumber}>{getAgreementText(option.invoiceReference, option.agreementNumber)}</option>
                ))}
              </DropdownFormInput>
              <FormGroup>
                {renderDatePicker()}
              </FormGroup>
              {ticketSelected && <>
                <FormGroup>
                  {authContext.features.getZoneInput(zones)}
                </FormGroup>
                {companyTicketService.hasMultipleProfiles &&
                  <RadioButtonFormInput name="profile" options={getProfileOptions()} label={lang.profile} />
                }
              </>}
              <ProductPrice price={getPrice()} />
              {ticketSelected && <>
                <CheckBoxForminput name="acceptPrivacy" label={lang.acceptPrivay} required />
                <PrivacyTerms terms={authContext.features.urls.terms} />
              </>
              }
              <ButtonGroup>
                <SubmitButton submitting={submitting} text={formLang.order} />
                <CancelButton onClick={() => { navigate("/bedriftsavtale"); }} />
              </ButtonGroup>
            </>
          }
          {backendError && <CreateCompanyTicketError error={backendError} />}
        </form>
      </FormProvider>
    </Container >
  );
};

export default CreateSubscriptionForm;