import { ALL_ZONES_FROM } from "src/constants";
import { Product } from "src/types/Product";
import { Zone } from "./api/commonTypes/Zone";
import { setIsEqual } from "./helpers/setIsEqual";

const zonesAreMatchingProductFilter = (product: Product, zoneFromId: string, zoneToId: string): boolean => {
  if (!product.filters) {
    return false;
  }
  const { zoneListMustEqual, zoneListMustBeSubsetOf } = product.filters;

  if (zoneListMustEqual) {
    return setIsEqual(new Set(zoneListMustEqual), new Set([zoneFromId, zoneToId]));
  }

  if (zoneListMustBeSubsetOf) {
    return zoneListMustBeSubsetOf.some(x => x === zoneFromId) && zoneListMustBeSubsetOf.some(x => x === zoneToId);
  }

  return false;
};

const getMatchingProduct = (products: Product[], zoneFrom: string, zoneTo: string, zones: Zone[], profile: string): Product => {
  const filteredProducts = products.filter(product => product.profileId === profile);
  const groupedFilteredProducts = {
    productsWithFilter: filteredProducts.filter(x => x.filters),
    productsWithoutFilter: filteredProducts.filter(x => x.filters === null),
  };
  const defaultProduct = groupedFilteredProducts.productsWithoutFilter[0];

  const zoneFromObj = zones.find(x => x.name === zoneFrom);
  const zoneToObj = zones.find(x => x.name === zoneTo);

  if (!zoneFromObj || !zoneToObj) {
    return defaultProduct;
  }

  if (groupedFilteredProducts.productsWithFilter.length > 0) {
    const exactMatch = groupedFilteredProducts.productsWithFilter.find(x => zonesAreMatchingProductFilter(x, zoneFromObj.id, zoneToObj.id));

    if (exactMatch) {
      return exactMatch;
    }
  }

  return defaultProduct;
};

export const getProductPrice = (products: Product[], zoneFrom: string, zoneTo: string, zones: Zone[], profile: string) => {
  let zoneCount = 1;
  if (zoneFrom === ALL_ZONES_FROM || zoneFrom === null) {
    zoneCount = 3;
  } else if (zoneFrom !== zoneTo) {
    zoneCount = 2;
  }

  const product = getMatchingProduct(products, zoneFrom, zoneTo, zones, profile);
  const priceInfo = product && product.prices.length > 1 ? product.prices.find(x => x.nrOfZones === zoneCount) : product?.prices[0];
  const price = priceInfo ? priceInfo.priceInclVAT / 12 : undefined;

  return price;
};

