import { LanguageResource } from "@ruter-as/web-components-and-tools"

interface HousingUnitCreateSubLanguageResource {
  order: string
  acceptPrivacy: string
  chooseAddon: string
  subscription: string
  startDate: string
}

const housingUnitCreateSubLanguageResource: LanguageResource<HousingUnitCreateSubLanguageResource> = {
  nb: {
    order: "Bestill",
    acceptPrivacy:
      "Jeg bekrefter at beboberen har fått informasjon om behandling av personopplysninger og godkjenner Ruters vilkår, " +
      "samt at jeg som administrator har fått tillatelse til å inngå avtale på hans / hennes vegne.",
    chooseAddon: "Velg tilleggstjeneste",
    subscription: "Abonnement",
    startDate: "Startdato (bedriftsbillett)"
  },
  en: {
    order: "Order",
    acceptPrivacy:
      "I hereby confirm that the employee has accepted the terms and policies, and that I have been given consent by the employee to order a ticket on his/hers behalf",
    chooseAddon: "Choose additional subscription", 
    subscription: "Subscription",
    startDate: "Start date (company ticket)"
  }
}

export default housingUnitCreateSubLanguageResource