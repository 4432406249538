import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Link } from "react-router-dom";
import { UrlType } from "src/types/features/Urls";
import { privacyTermsLanguageResource } from "./lang-resource";

interface Props {
  terms?: UrlType
}

const PrivacyTerms: React.FC<Props> = ({terms}) => {
  const lang = useLanguageResource(privacyTermsLanguageResource);
  if (!terms) return null;

  const { url } = terms;

  switch (terms.type) {
    case "External":
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ marginTop: "1.5rem", display: "block" }}>
          {lang.readPolicies}
        </a>
      );
    case "Internal":
      return (
        <Link to={url} style={{ marginTop: "1.5rem", display: "block" }}>
          {lang.readPolicies}
        </Link>
      );
  }

};

export default PrivacyTerms;