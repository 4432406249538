import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useValidHousingAssociationService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import { AvailableSubscription } from "src/common/api/companyAgreementApi/AvailableSubscription";
import { Subscription } from "src/common/api/companyAgreementApi/Subscription";
import { HousingAssociationOrder } from "src/common/api/companyAgreementApi/housingAssociation/housingAssociationOrder";
import { ButtonLink } from "src/components/common/buttons";
import SubscriptionCard from "src/components/common/cards/subscriptionCard/SubscriptionCard";
import TicketCard from "src/components/common/cards/ticketCard/TicketCard";
import { housingUnitLanguageResource } from "../lang-resource";

interface Props {
  person: PersonWithTicketsOrSubscriptions,
  availableSubscriptions: AvailableSubscription[],
  allowOrderTicket: boolean,
  setModalType: (params: { type: "NEW_ORDER", ticket: HousingAssociationOrder, availableSubscriptions: AvailableSubscription[] }) => void,
  onUpdateRequest: () => void,
  zones: Zone[]
}

export type TicketsOrSubscriptions = Array<Subscription | HousingAssociationOrder>

export interface PersonWithTicketsOrSubscriptions {
  name: string,
  ticketsOrSubscriptions: TicketsOrSubscriptions
}

export const getPersonsAvailableSubscriptions = (ticketsOrSubscriptions: TicketsOrSubscriptions, availableSubscriptions: AvailableSubscription[]) => {
  const mapActiveOrPendingSubscriptionTypes = ticketsOrSubscriptions.filter(ticketOrSub => ticketOrSub.status === "active" || ticketOrSub.status === "pending").map(ticketOrSub => ticketOrSub.type)
  const personsAvailableSubscriptions = availableSubscriptions.filter(sub => !mapActiveOrPendingSubscriptionTypes.includes(sub.type))
  return personsAvailableSubscriptions
}

export const allowCreateNewSubscription = (ticketsOrSubscriptions: TicketsOrSubscriptions, availableSubscriptions: AvailableSubscription[], allowOrderTicket: boolean): boolean => {
  const personsAvailableSubscriptions = getPersonsAvailableSubscriptions(ticketsOrSubscriptions, availableSubscriptions)
  return allowOrderTicket && personsAvailableSubscriptions.length > 0
}

const PersonWithTicketOrSubscription: React.FC<Props> = ({
  person,
  availableSubscriptions,
  allowOrderTicket,
  setModalType,
  onUpdateRequest,
  zones
}) => {
  const housingAssociationService = useValidHousingAssociationService();
  const language = useLanguageResource(housingUnitLanguageResource);

  const ticket = person.ticketsOrSubscriptions.find(ticketOrSub => ticketOrSub.type === "TICKET");

  return (
    <div data-test-id="subscriptions-row">
      <div className="subscriptions-row-header">
        <h2>
          <span className="person-name">{person.name}</span>
          {allowCreateNewSubscription(person.ticketsOrSubscriptions, availableSubscriptions, allowOrderTicket) && ticket && <>
            <span> – </span>
            <ButtonLink
              text={language.addSubscription}
              onClick={() => setModalType({ type: "NEW_ORDER", ticket, availableSubscriptions: getPersonsAvailableSubscriptions(person.ticketsOrSubscriptions, availableSubscriptions) })}
              dataTestId="create-subscription-button" />
          </>}
        </h2>
      </div>
      <div data-test-id="tickets-container" className="tickets">
        {person.ticketsOrSubscriptions.map(ticketOrSubscription => {
          if (ticketOrSubscription.type === "TICKET") {
            return <TicketCard title={language.companyTicket} onUpdateRequest={onUpdateRequest} ticket={ticketOrSubscription} zones={zones} key={ticketOrSubscription.id} />
          } else {
            return <SubscriptionCard subscription={ticketOrSubscription} onUpdateRequest={onUpdateRequest} key={ticketOrSubscription.id} disableResendButton={housingAssociationService.disableResendSubscriptionCodeButton} />
          }
        })}
      </div>
    </div>
  )
}

export default PersonWithTicketOrSubscription;