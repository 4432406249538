import { Container } from "@ruter-as/web-components-and-tools"
import './PrivacyDeclarationEBike.scss'


const PrivacyDeclarationEBike: React.FC = () => {
  return (
    <Container width="m" className="privacy-declaration-e-bike">
      <h1>Personvernerklæring for bestilling av Elsykkelabonnement</h1>
      <p>Her finner du informasjon om hvordan Ruter behandler dine personopplysninger når du velger å inngå avtale om elsykkel. </p>

      <h2>Hva innebærer et Elsykkelabonnement? </h2>
      <p>Dersom du jobber i en bedrift, eller bor i et borettslag, som har avtale med Ruter, har du mulighet å abonnere på en elsykkel. Abonnementet er en pilot for å teste nye tjenester i Ruters tilbud.  </p>
      <p>Elsykkelabonnementet tilbys i samarbeid med tjenesteleverandør der du får tilgang til en personlig disponert elsykkel som betales månedsvis via lønnstrekk eller sammen med felleskostnader.  </p>
      <p>Ruter behandler dine personopplysninger i forbindelse med bestilling av tjenesten. </p>

      <h2>Formål </h2>
      <p>Formålene med behandlingen av personopplysningene er </p>
      <ul>
        <li>Å kunne tilby tjenesten til deg som kunde av Ruter </li>
        <li>Administrere kundeforholdet og videre formidle kontakt med leverandør, og dessuten lage faktureringsgrunnlag pr. kunde til bedriftene. </li>
        <li>Ansvarlig for behandling av personopplysninger ved oppfølging/ rapportering i EU prosjektet, samt ved gjennomføring av spørreundersøkelser </li>
        <li>Behandle personopplysninger som ikke knyttes til deg til å samle innsikt i form av statistikk om bruken av tjenesten. </li>
        <li>Behandle personopplysninger i forbindelse med kundeundersøkelser (frivillig) </li>
      </ul>

      <h2>Rettslig grunnlag </h2>
      <p>Rettslig grunnlag for behandling av personopplysninger er at behandlingen er nødvendig for å oppfylle avtalen om elsykkelabonnement med deg. </p>
      <p>Ruter har videre en berettiget interesse å få innsikt i bruk av mobilitestjenester ved å behandle personopplysninger i forbindelse med spørreundersøkelser. </p>
      <p>For å samle innsikt og statistikk er det rettslige grunnlaget at Ruter har en berettiget interesse i å forbedre våre tjenester. Dersom du skulle ha noen innsigelser mot bruk av dine personopplysninger til dette formålet, kan du fremme innsigelsen ved bruk av <a href="https://kontakt.ruter.no/personvern" target="_blank" rel="noreferrer">kontaktskjemaet for personvernsaker</a> på Ruter.no </p>
      <p>For behandling av opplysninger knyttet til lovkrav, er det rettslige grunnlaget at behandlingen er nødvendig for å oppfylle våre rettslige forpliktelser etter regnskapsloven, bokføringsloven og dens forskrifter. </p>

      <h2>Hvilke personopplysninger behandler vi? </h2>
      <p>For å kunne tilby elsykkelabonnementet er det nødvendig å behandle følgende opplysninger: </p>
      <p><strong>Kundeopplysninger:</strong> Opplysninger om deg og ditt kundeforhold til oss, som oppgitt ved bestilling av tjenesten.  Vi behandler navn, telefonnummer, ansattnummer eller leilighetsnummer.  </p>
      <p><strong>Salgsopplysninger:</strong> Dette er opplysninger som start- og sluttdato, og opplysninger om abonnementets status (aktivt/avsluttet). </p>
      <p>Når vi forbereder faktagrunnlag vil dine kontaktopplysninger komme frem av fakturaen, som blir sendt til bedriften eller borettslaget, av Ruters databehandler for fakturering. </p>

      <h2>Lagring og sletting </h2>
      <p>Dine personopplysninger lagres kun så lenge du har en avtale om abonnement av el- sykkel, og slettes/anonymiseres etter 90 dager, med unntak av salgsopplysninger som beskrevet under </p>
      <p>For å oppfylle dokumentasjonsplikten i bokføringsloven lagres nødvendige salgsopplysninger inntil 5 år etter regnskapsårets slutt. Dette inkluderer navn, ansattnummer/leilighetsnummer og telefonnummer </p>

      <h2>Tilgang og bruk </h2>
      <p>Det er begrenset tilgang til dine personopplysninger, etter tjenstlig behov. </p>
      <p>Når vi sender faktura vil dine kontaktopplysninger komme frem av fakturaen, som blir sendt til bedriften eller borettslaget, av Ruters databehandler for fakturering. For IT-utvikling og drift av tjenesten, benytter Ruter selskapet Tet digital som databehandler. </p>
      <p>Du har rett til innsyn i, retting, dataportabilitet og sletting av opplysninger som er lagret om deg.  </p>
      <a href="https://ruter.no/fa-hjelp/vilkar/personvern/" rel="noreferrer" target="_blank">Les mer om dine rettigheter under Personvern i Ruters tjenester</a>

      <h2>Skyløsninger </h2>
      <p>Se beskrivelsen under <a href="https://ruter.no/fa-hjelp/vilkar/personvern/avtale-ruter-profil/personvernerklaring-ruter-profil/" rel="noreferrer" target="_blank">RuterProfil</a> for informasjon om tilganger og bruk av skytjenester. </p>

      <h2>Relatert innhold </h2>
      <a href="https://ruter.no/fa-hjelp/vilkar/personvern/" rel="noreferrer" target="_blank">Behandling av personopplysninger i Ruter (generell erklæring) </a>
    </Container>
  )
}

export default PrivacyDeclarationEBike