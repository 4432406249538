import { ReactNode } from "react";
import { Status } from "../../text/ticketStatus/TicketStatus";
import "./InfoCard.scss";
import InfoCardButton, { InfoCardButtonType } from "./InfoCardButton";
import InfoRow from "./InfoCardRow";

export interface InfoCardRowType {
  label: string
  value: ReactNode
  "data-test-id"?: string
}


interface Props {
  "data-test-id"?: string
  label: string
  status: Status
  infoRows: InfoCardRowType[]
  buttons?: InfoCardButtonType[]
  id?: string
}

const InfoCard: React.FC<Props> = ({ "data-test-id": dataTestId, status, label, infoRows, buttons, id }) => {
  return (
    <div className="info-card-component" data-test-id={dataTestId} id={id}>
      <div className={`status ${status}`} aria-hidden={true} data-test-id="card-status"/>
      <div className="card-label">
        <h3 id="title" className="value" data-test-id="card-label">
          {label}
        </h3>
      </div>
      <dl className="info">
        {infoRows.map(row => <InfoRow key={row.label} label={row.label} data-test-id={row["data-test-id"]}>{row.value}</InfoRow>)}
      </dl>
      {buttons &&
        <div className="actions">
          {buttons.map(button =>
            <InfoCardButton
              key={button.text}
              onClick={button.onClick}
              text={button.text}
              disabled={button.disabled}
              icon={button.icon}
              data-test-id={button["data-test-id"]} />,
          )}
        </div>
      }
    </div>
  );
};

export default InfoCard;
