import { CheckboxFormInputProps } from "@ruter-as/web-components-and-tools";
import { parseSubscriptionType, SubscriptionType } from "src/types/subscriptionType";
import { getLocaleText, LocaleText, LocaleTextJson, mapLocaleText } from "../../../types/localeText";

export interface AvailableSubscriptionJson {
  type: string
  name: LocaleTextJson,
  description: LocaleTextJson
  pricePerMonth: number | null
  vatPerMonth: number | null
}

export interface AvailableSubscription {
  type: SubscriptionType
  name: LocaleText,
  description: LocaleText,
  pricePerMonth: number
  vatPerMonth: number
}

export const mapAvailableSubscription = (json: AvailableSubscriptionJson): AvailableSubscription => ({
  description: mapLocaleText(json.description),
  name: mapLocaleText(json.name),
  pricePerMonth: json.pricePerMonth || 0,
  type: parseSubscriptionType(json.type),
  vatPerMonth: json.vatPerMonth || 0,
});

export const mapAvailableSubscriptionToOption = (sub: AvailableSubscription, selectedLanguage: "en" | "nb", disabled = false): CheckboxFormInputProps => ({
  name: sub.type,
  label: getLocaleText(sub.name, selectedLanguage),
  description: getLocaleText(sub.description, selectedLanguage),
  disabled
});

export const mapAvailableSubscriptionArray = (json: AvailableSubscriptionJson[]): AvailableSubscription[] => {
  const mappedAvailableSub = json.map(mapAvailableSubscription).filter(sub => sub.type !== "UNKOWN")
  const sortAvailableSub = (a: AvailableSubscription, b: AvailableSubscription) => {
    if(a.type === "TICKET") return -1
    if(b.type === "TICKET") return 1
    return 0;
  }

  mappedAvailableSub.sort(sortAvailableSub)
  return mappedAvailableSub
};

export const getValidAvailableSubscriptionJson = (json: Partial<AvailableSubscriptionJson> = {}): AvailableSubscriptionJson => ({
  description: {
    nb: "Elsykkel abbo",
    en: "Electrical bike",
  },
  name: {
    en: "Joule",
    nb: "Joule",
  },
  pricePerMonth: 100,
  type: "JOULE",
  vatPerMonth: 25,
  ...json,
});

