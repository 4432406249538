import { formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { formLanguageResource } from "./lang-resource";
import "./ProductPrice.scss";

interface Props {
  price?: number
  gray?: boolean;
}

const ProductPrice: React.FC<Props> = ({ gray, price }) => {
  const lang = useLanguageResource(formLanguageResource);

  const formattedPrice = price ? formatter.number.integer(Math.floor(price)) : "-";
  const formattedDate = formatter.date.toMonthAndYear(new Date());

  const cssClassNames = ["components-common-form-productpricefield"];
  if (gray) {
    cssClassNames.push("gray");
  }

  return (
    <div className={cssClassNames.join(" ")}>
      <div className="label">{lang.productName(formattedDate)}</div>
      <div>
        <span className="price" data-test-id="product-price-field">
          {formattedPrice}
        </span>
        <span className="label">kr</span>
      </div>
    </div>
  );
};

export default ProductPrice;