import { Container } from "@ruter-as/web-components-and-tools"
import { Link } from "react-router-dom"
import './PrivacyDeclarationEBike.scss'


const TermsForEBike: React.FC = () => {
  return (
    <Container width="m" className="privacy-declaration-e-bike">
      <h1>Vilkår for elsykkelabonnement </h1>
      <h2>Hva er elsykkelabonnementet? </h2>
      <p>Dersom du jobber i en bedrift, eller bor i et borettslag, som har avtale med Ruter, har du mulighet å abonnere på en elsykkel. Abonnementet er et prøveprosjekt for å teste nye tjenester i Ruters tilbud.  </p>
      <p>Elsykkelabonnementet tilbys i samarbeid med tjenesteleverandør og gir deg tilgang til en personlig disponert elsykkel, betalt månedsvis via lønnstrekk eller faktura.  </p>

      <h2>Vilkår for elsykkelabonnementet </h2>
      <p>Ved å ta i bruk elsykkelabonnementet aksepterer du vilkårene nedenfor: </p>
      <ul>
        <li>Les instruksjonene fra din arbeidsgiver eller ditt borettslag og sjekk at ordningen gjelder for deg.  </li>
        <li>Tjenesteleverandøren stiller blant annet følgende krav: </li>
        <ul>
          <li>Kunden må ha en fast bostedsadresse i Norge.  </li>
          <li>Kunden må ha et egnet sted å parkere elsykkelen hjemme over natten. F.eks. lukket bakgård med inngangskontroll, egen garasje, i egen hage/gårdsplass uten innsyn fra offentlig vei.  </li>
          <li>Kunden må ha tilfredsstillende kredittverdighet. Tjeneste-leverandøren gjennomfører automatisk kredittsjekk av alle kunder ved bestilling. Dette gjelder også ansatte, da hver enkelt ansatt står ansvarlig for elsykkelen og eventuelle kostnader som egenandel ved tyveri, reparasjon av skader utover normal bruksslitasje eller ved aktivt abonnement etter avsluttet arbeidsforhold. </li>
        </ul>
        <li>For å bli med i ordningen melder du deg opp via Ruters digitale bestillingsskjema. </li>
        <li>Ruter vil at du deltar på våre brukerundersøkelser i pilotperioden.  </li>
        <li>Dersom du ikke lenger er ansatt, går ut i lengre permisjon uten lønn, eller flytter fra din bolig, må du avslutte abonnementet hos tjenesteleverandøren. Hvis du ikke avslutter, vil tjenesteleverandøren trekke betaling fra deg direkte via betalingsopplysningene du oppga ved inngåelse av kundeforholdet hos leverandøren. Hvis du ønsker å fortsette kundeforholdet utenfor ordningen, må du kontakte tjenesteleverandøren om dette. </li>
      </ul>

      <p>Elsykkelabonnementet har bindingstid på én måned. Du blir først belastet når du har bestilt en sykkel hos tjenesteleverandøren og fått den utlevert. </p>
      <p>Du kan ikke bruke tjenesten til å oppnå uberettigede fordeler. Ved bruk av elsykkelabonnementet i strid med vilkårene, eller annen misbruk av tjenesten, er du ansvarlig for økonomisk tap som Ruter påføres i den forbindelse. </p>
      <p>For informasjon om hvordan vi behandler dine personopplysninger i forbindelse med bedriftsbillett, se vår <Link to="/vilkar/joule/persjonvernerklaering">personvernerklæring for elsykkelabonnement.</Link> </p>
    </Container>
  )
}

export default TermsForEBike