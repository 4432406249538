import { Button, ButtonGroup, Container, EmailFormInput, RadioButtonFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import apiClient from "src/common/apiClient/apiClient";
import "./ProvideSigneeInfo.scss";
import { onboardingLanguageResources } from "./lang-resource";

interface FormInput {
  signee: string;
  signeeEmail: string;
}
interface Props {
  onboardingId: string;
  onSigneeProvided: () => void;
}

const ProvideSigneeInfo: React.FC<Props> = ({ onboardingId, onSigneeProvided }) => {
  const lang = useLanguageResource(onboardingLanguageResources);
  const [elligbleSignees, setElligbleSignees] = useState<null | string[]>(null);
  const methods = useForm<FormInput>();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiClient.get(`/onboarding/onboarding/elligible-signers/${onboardingId}`, (json) => json.names as string[]);
        setElligbleSignees(result);
      } catch (e) {
        setElligbleSignees(() => { throw e; });
      }
    };
    fetch();

  }, [onboardingId]);

  const submit = async (values: FormInput) => {
    const body = {
      name: values.signee,
      email: values.signeeEmail,
    };

    try {
      setSubmitting(true);
      await apiClient.post(`/onboarding/onboarding/signee/${onboardingId}`, body);
      onSigneeProvided();
    } catch (e) {
      setElligbleSignees(() => { throw e; });
    }
  };

  const loading = elligbleSignees === null;
  const className = loading ? "provide-signee-info skeleton" : "provide-signee-info";
  const options = elligbleSignees?.map(x => ({ value: x, text: x }));

  return (
    <Container width="s" data-test-id="provide-signee-info" className={className}>
      <FormProvider {...methods}>

        <h1>{lang.provideSigneeInfo.title}</h1>
        <p>{lang.provideSigneeInfo.text}</p>
        {!loading && (
          <form onSubmit={methods.handleSubmit(submit)} style={{ marginTop: "2rem" }}>
            <RadioButtonFormInput name="signee" options={options || []} label={lang.provideSigneeInfo.signee} required />
            <EmailFormInput name="signeeEmail" required label={lang.provideSigneeInfo.signeeEmail} />
            <ButtonGroup>
              <Button variant="primary" type="submit" text={lang.provideSigneeInfo.continue} loading={submitting} />
            </ButtonGroup>
          </form>
        )}

      </FormProvider>
    </Container>
  );
};

export default ProvideSigneeInfo;