import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";
import { useTicketRequestContext } from "src/TicketRequestContext";
import DesktopMenu from "./DesktopMenu";
import DropDownMenu from "./DropDownMenu";
import "./Header.scss";
import { headerLanguageResource } from "./languageResource";
import { getMenuItems, getNonAuthenticatedMenuItems, MenuState } from "./menuState";
import MobileMenu from "./MobileMenu";
import SignInButton from "./SignInButton";
import SubMenu from "./SubMenu";

const Header: React.FC = () => {
  const language = useLanguageResource(headerLanguageResource);
  const authContext = useAuthContext();
  const ticketRequestContext = useTicketRequestContext();

  const allowTicketRequest = ticketRequestContext.state === "ticket-request-allowed";
  const requestCount = ticketRequestContext.state === "loading" || ticketRequestContext.state === "ticket-request-allowed" ? ticketRequestContext.requests.length : 0;

  const [menu, setMenu] = useState<MenuState>({
    items: authContext.authenticated ? getMenuItems(authContext.features, allowTicketRequest, requestCount) : getNonAuthenticatedMenuItems(authContext.features),
    showAccountMenu: false,
    showMobileMenu: false,
    openItems: [],
  });  

  const showRegisterButton = authContext.features.onboarding.hasAccess && authContext.features.onboarding.showOnboarding;

  useEffect(() => {
    if (authContext.authenticated) {
      setMenu((previousMenu) => ({
        ...previousMenu,
        items: getMenuItems(authContext.features, allowTicketRequest, requestCount),
      }),
      );
    } else {
      setMenu({
        items: getNonAuthenticatedMenuItems(authContext.features),
        openItems: [],
        showAccountMenu: false,
        showMobileMenu: false,
      });
    }


  }, [authContext, allowTicketRequest, requestCount]);

  const hideAccountMenu = () => {
    setMenu((state) => ({
      ...state,
      showAccountMenu: false,
    }));
  };

  const showAccountMenu = () => {
    setMenu((state) => ({
      ...state,
      showAccountMenu: true,
      showMobileMenu: false,
    }));
  };

  const hideMobileMenu = () => {
    setMenu((state) => ({
      ...state,
      showMobileMenu: false,
    }));
  };

  const showMobileMenu = () => {
    setMenu((state) => ({
      ...state,
      showAccountMenu: false,
      showMobileMenu: true,
    }));
  };

  const closeMenuItem = (id: string) => {
    setMenu((state) => ({
      ...state,
      openItems: state.openItems.filter(x => x !== id),
    }));
  };

  const expandMenuItem = (id: string) => {
    setMenu((state) => ({
      ...state,
      openItems: [...state.openItems, id],
    }));
  };

  const RegisterButton = () => (
    <Link to="/register">
      <button className="components-app-header-signinbutton" data-test-topmenu-id="register" type="button">
        <div>{language.register}</div>
      </button>
    </Link>
  );

  return (
    <header>
      <div className={`top-menu ${authContext.features.theme.class}`}>
        <Container width="l" className="header-container" >
          <Link to="/" className="logo" data-test-id="header-logo">
            <img src={authContext.features.theme.logo} alt="Logo" />
          </Link>
          <DesktopMenu menu={menu} />
          {authContext.authenticated && <DropDownMenu menu={menu} hideAccountMenu={hideAccountMenu} showAccountMenu={showAccountMenu} />}
          {showRegisterButton && <RegisterButton />}
          {!authContext.authenticated && <SignInButton onClick={() => authContext.login()} text={language.logIn} />}
          <MobileMenu menu={menu} closeMenuItem={closeMenuItem} expandMenuItem={expandMenuItem} hideMobileMenu={hideMobileMenu} showMobileMenu={showMobileMenu} />
        </Container>
      </div>
      <SubMenu menu={menu} />
    </header>
  );
};
export default Header;
