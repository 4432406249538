
export const companyTicketStatusArray = ["active", "cancelled", "expired", "pending", "failed", "anonymised"] as const;

export type CompanyTicketStatus = typeof companyTicketStatusArray[number];

export const parseCompanyTicketStatus = (value: string): CompanyTicketStatus => {
  const parsed = companyTicketStatusArray.find((x) => x === value);

  if (!parsed) {
    throw new Error(`Could not find ticket status: ${value}`);
  }

  return parsed;
};
