import { LanguageContextProvider } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import "core-js/actual/array/to-sorted";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider, { useAuthContext } from "./AuthContext";
import App from "./components/app/App";
import GenericError from "./components/app/genericError/GenericError";
import setGlobalContext from "./setupGlobalContext";
import TicketRequestContextProvider from "./TicketRequestContext";

declare global {
  interface Window {
    Cypress: unknown;
    auth: {
      authority: string;
       
      client_id: string;
      scope: string;
    };
    tenant: string;
    envName: string;
  }
}

const LanguageContextWrapper: React.FC = ({ children }) => {
  const authContext = useAuthContext();

  return (
    <LanguageContextProvider substituteDictionaryList={authContext.features.languageSubstitutionDictionary}>
      {children}
    </LanguageContextProvider>
  );
};

setGlobalContext()

ReactDOM.render(
  
    <Sentry.ErrorBoundary fallback={() => <GenericError />}>
      <BrowserRouter>
        <AuthContextProvider>
          <TicketRequestContextProvider>
            <LanguageContextWrapper>
              <StrictMode>
                <App />
              </StrictMode>
            </LanguageContextWrapper>
          </TicketRequestContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  ,
  document.getElementById("app"),
);
